export default (history, category, modal) => {
  const dashboard = [
    {
      selector: '',
      content: 'Welcome to exactplace - a place to handle your projects',
      action: (node) => {
        if (history.location.pathname !== '/dashboard/workplace')
          history.push('/dashboard/workplace');
      },
    },
    {
      selector: '#main-scroll',
      content:
        'In the dashboard you can see the task status, the assigned points and unassigned points per project.',
    },
    {
      selector: '',
      content: "Let's get you started",
    },
    // {
    //   selector: '#step-3',
    //   content: 'Closed points - to approve points that are recently set to closed.'
    // },
    // {
    //   selector: '#step-4',
    //   content: 'Unapproved Estimates - to approve/decline proposed time estimates from the person assigned of a specific point.'
    // },
  ];

  const client = [
    {
      selector: '#n-clients',
      content: 'First, we need to go to the clients page.',
    },
    {
      selector: '#step-5',
      content:
        'Before adding a feedback/points, you should add first your client.',
      action: (node) => {
        if (history.location.pathname !== '/clients') {
          console.log(history.location.pathname);
          history.push('/clients');
        }
      },
    },
    {
      selector: '.modal-content',
      content: 'Fill up the form',
      action: (node) => {
        if (!modal) document.getElementById('step-5').click();
      },
    },
    {
      selector: '#clientnr',
      content: 'Input client id',
    },
    {
      selector: '#clientname',
      content: 'Input client id',
    },
    {
      selector: '#ok_modal',
      content: 'Then click save',
    },
  ];

  const user = [
    {
      selector: '#step-6',
      content: "Before adding users to exactplace, let's add first a group",
      action: (node) => {
        if (history.location.pathname !== '/users') history.push('/users');
      },
    },
    {
      selector: '#add_group',
      content: 'Type the group name for the users',
      action: (node) => {
        if (!modal) document.getElementById('step-6').click();
      },
    },
    {
      selector: '#group-btn-save',
      content: 'Then click save',
    },
    {
      selector: '#step-6-5',
      content: 'Next, click add users under users page',
      action: (node) => {
        if (modal) document.getElementById('step-6').click();
      },
    },
    {
      selector: '.modal-content',
      content:
        'Then add your members to exactplace to collaborate with, and click save.',
      action: (node) => {
        if (history.location.pathname !== '/users') history.push('/users');

        if (!modal) document.getElementById('step-6-5').click();
      },
    },
  ];

  const project = [
    {
      selector: '#step-7',
      content:
        'Add projects of clients which you would like to track feedbacks from',
      action: (node) => {
        if (history.location.pathname !== '/projects')
          history.push('/projects');
      },
    },
    {
      selector: '.modal-content',
      content:
        "Fill up the form and don't forget to add the version of the project before saving.",
      action: (node) => {
        if (history.location.pathname !== '/projects')
          history.push('/projects');

        if (!modal) document.getElementById('step-7').click();
      },
    },
    {
      selector: '#user-step',
      content: 'Assign users to the project with their project roles',
    },
    {
      selector: '#version-step',
      content: 'Add a version of your project',
    },
    {
      selector: '#ok_modal',
      content: 'Then save your project',
    },
  ];

  const feedback = [
    {
      selector: '#n-add-feedback',
      content: 'And finally click here to add feedbacks of your projects.',
      action: (node) => {
        if (modal) document.getElementById('step-7').click();
      },
    },
    {
      selector: '#step-8',
      content: 'Fill up first the feedback title and choose a project first',
      action: (node) => {
        if (history.location.pathname !== '/feedback/create')
          history.push('/feedback/create');
      },
    },
    {
      selector: '#add-point',
      content: 'Click the add point button',
    },
    {
      selector: '.modal-content',
      content: 'This is where you will be adding your points.',
      action: (node) => {
        if (history.location.pathname !== '/feedback/create')
          history.push('/feedback/create');

        if (!modal) document.getElementById('add-point').click();
      },
    },
    {
      selector: '#version-step',
      content:
        'Specify which version of project you would like to add feedback to',
    },
    {
      selector: '#instruction-step',
      content: 'Place instuctions for the assigned user to work on to.',
    },
    {
      selector: '#comment-step',
      content: 'This is where you and your team will leave comments.',
    },
    {
      selector: '#status-step',
      content:
        'Choose the appropriate status for the point. \nOpen - points that is open to work on.\nQuestion - points that is in question (assign user to answer the question)\nFor Testing - points that are ready for testing/Closed - points that are completed',
    },
  ];

  let steps = { dashboard, client, user, project, feedback };

  let result = category
    ? steps[category]
    : Object.values(steps).reduce((a, b) => [...a, ...b]);

  return result;
};
