export default (state, action) => {

  switch (action.type) {
    case 'SET_STATE': 
      return {
        ...state,
        ...action.payload
      }

    case 'UPDATE_UPLOAD_STATE': 
      return {
        ...state,
        uploading: !state.uploading,
      }

    case 'UPDATE_POINTS': 
      /* Holds Feedbacks or Points */
      const pointss = state.points.map(e => ({
        ...e,
        files: (action.state.point_id === e.id)? action.state.data: e.files
      }))

      let payload = action.payload 
      payload.rowData.editedItem = {...state.editedItem}

      return {
        ...state,
        ...payload,
        points: pointss,
      }

    case 'REMOVE_FILE':
      let points = (state.isFeedbackList == 'feedback')? 
        { 
          feedbacks: state.feedbacks.map(e => ({
            ...e,
            versions: e.versions.map(version => ({
              ...version,
              points: version.points.map(point => ({
                ...point,
                files: (state.editedItem.id == point.id)? point.files.filter(e => e.filename != action.payload.filename): point.files
              }))
            }))
          })) 
        } : // Feedbacks
        { // Points
          points: state.points.map(e => ({...e, files: (state.editedItem.id == e.id)? e.files.filter(e => e.filename != action.payload.filename): e.files}))
        }

      return {
        ...state,
        ...points
      }

      default:
        return state
  }
}