import React, { useContext, useState, useEffect, Fragment } from 'react';
import { useSubscription } from 'react-apollo-hooks';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  withRouter,
} from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import './App.css';
import Scrollbar from 'react-smooth-scrollbar';
import SmoothScrollbar from 'smooth-scrollbar';
import OverscrollPlugin from 'smooth-scrollbar/plugins/overscroll';
import { Container } from 'reactstrap';
import classNames from 'classnames';
import SaveIcon from '@material-ui/icons/Save';
import AssessmentIcon from '@material-ui/icons/Assessment';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';
import Reactour from 'reactour';
import { Button as DButton } from 'antd';
// 3rd party

// Components
import Login from './components/login/Login';
import Register from './components/register/Register';
import Dashboard from './components/exactplace/dashboard/Dashboard';
import Timelog from './components/exactplace/time_log/Timelog';
import Feedbacks from './components/exactplace/feedback/Feedbacks';
import FeedbackForm from './components/exactplace/feedback/form/FeedbackForm2';
import SettingsComponent from './components/exactplace/settings/Settings';
import ClientsComponent from './components/user_plan/client/Client';
import ProjectsComponent from './components/user_plan/project/Projects';
import UsersComponent from './components/user_plan/user/User';
import FeedbackFormPage from './components/exactplace/feedback/form/FeedbackFormPage';
import HowToUsePage from './components/exactplace/how_to_use';

// Common
import {
  Loading,
  ComponentNotFound,
  MainNavbar,
  SideNav,
  Sidebar,
  Toast,
  Notification,
  FeatureInfo,
} from './components/common';

// Contexts
import { AuthContext } from './contexts/AuthContext';
import { GlobalContext } from './contexts/GlobalContext';
import PushNotification from './PushNotification';
import { SHOW_NOTIF } from './queries';

import main_background from './brand/main.jpg';

import getSteps from './utils/steps';

SmoothScrollbar.use(OverscrollPlugin);
let isFetching = false;
const handleScroll = (
  scroll,
  loc,
  showFab,
  isDashboardWorkplace,
  fetchingPoints,
  hasMore,
  dispatch
) => {
  /* Sticky component for status feedback on feedback list */
  if (!!document.getElementById('status-feedback')) {
    if (scroll.offset.y >= 15) {
      document.getElementById('status-feedback').style.marginTop =
        scroll.offset.y - 11 + 'px';
    } else {
      document.getElementById('status-feedback').style.marginTop = 0;
    }
  }

  /* Sticky component for all headers that has filters */
  if (!!document.getElementById('page-header')) {
    // console.log(scroll.offset.y)
    if (scroll.offset.y >= 15) {
      document.getElementById('page-header').style.padding = 0;
      document.getElementById('page-header').style.marginTop =
        scroll.offset.y - 15 + 'px';
    } else {
      document.getElementById('page-header').style.padding = '0 22px 0 15px';
      document.getElementById('page-header').style.marginTop = 0;
    }

    if (scroll.offset.y >= 145) {
      if (loc == 'essentials' && !showFab) {
        dispatch({ type: 'SET_STATE', payload: { showFab: true } });
      }
    } else {
      if (loc == 'essentials' && showFab) {
        dispatch({ type: 'SET_STATE', payload: { showFab: false } });
      }
    }
  }

  if (isDashboardWorkplace) {
    if (scroll.limit.y - scroll.offset.y <= 70 && !isFetching && hasMore) {
      isFetching = true;
      dispatch({ type: 'SET_STATE', payload: { fetchingPoints: true } });
    }
  }
};

let current = -1;
const Tour = withRouter(
  ({
    isOpen,
    closeTour,
    filter,
    modal,
    location: { pathname },
    history,
    context,
    isInteraction,
    goToStep,
    disableInteraction,
  }) => {
    const steps = getSteps(history, filter, modal);
    const [update, setUpdate] = useState('');

    useEffect(() => {
      setUpdate(pathname);
    }, [pathname]);

    useEffect(() => {
      setUpdate(modal.toString());
    }, [modal]);

    function disableCurrentStep(step) {
      // step starts at 0
      let payload = {};
      // Show/hide button
      if ([3, 4, 6, 7].includes(step)) {
        payload = { ...payload, isInteraction: true };
      } else {
        payload = { ...payload, isInteraction: false };
      }

      // Enable/disable interaction on highlighted section
      // if ([5].includes(step)) {
      //   payload = { ...payload, disableInteraction: true }
      // } else {
      //   payload = { ...payload, disableInteraction: false }
      // }

      payload = { ...payload, currentStep: step };

      context.dispatch({ type: 'SET_STATE', payload });
    }

    return (
      <Reactour
        steps={steps}
        isOpen={isOpen}
        onRequestClose={closeTour}
        goToStep={goToStep}
        update={update}
        prevButton={<></>}
        updateDelay={1000}
        closeWithMask={false}
        showNavigation={false}
        // disableInteraction={disableInteraction}
        getCurrentStep={(curr) => {
          if (goToStep != null) {
            context.dispatch({
              type: 'SET_STATE',
              payload: { goToStep: null, currentStep: curr },
            });
          }

          if (current != curr) {
            current = curr;
            disableCurrentStep(curr);
          }
        }}
        disableDotsNavigation={true}
        disableKeyboardNavigation={['left', 'right']}
        lastStepNextButton={
          <DButton>{context.translation.done[context.locale]}</DButton>
        }
        nextButton={
          !isInteraction ? (
            <DButton type="primary">
              {context.translation.next[context.locale]}
            </DButton>
          ) : (
            <></>
          )
        }
      />
    );
  }
);

export default (props) => {
  const Auth = useContext(AuthContext);
  const {
    goToStep,
    isInteraction,
    modal1,
    dispatch,
    isDashboardWorkplace,
    fetchingPoints,
    isTourOpen,
    hasMore,
    isOpen,
    filteredCategory,
    newSidebarOpen,
    open,
    message,
    showFab,
    disableButton,
    showFab3,
    loc,
    showFab2,
    fabAction,
    fabAction3,
    fabActionAdd,
    tooltipTittle,
    print,
    uploadFiles,
    files,
    uri,
    fileName,
    point_no,
    translation,
    locale,
    disableInteraction,
  } = useContext(GlobalContext);
  const [sideNavIsOpen, toggleSideNav] = useState(true);
  const [openDial, setOpenDial] = useState(false);
  const [damping, setDamping] = useState(0.1);
  const [subscription, setSubscription] = useState();
  const updatedPoint = useSubscription(SHOW_NOTIF, {
    variables: { data: { subscription } },
  });

  useEffect(() => {
    (async () => {
      setSubscription(await PushNotification());
    })();
  }, []);

  useEffect(() => {
    if (Auth.user !== null) {
      if (Auth.user.first_tutorial === 'yes')
        dispatch({ type: 'IS_TOUR_OPEN', payload: true });
    }
  }, [Auth.user, dispatch]);

  useEffect(() => {
    isFetching = fetchingPoints;
  }, [fetchingPoints]);

  return (
    <Fragment>
      {/* {
        process.env.ENV === 'development' && ( */}
      <FeatureInfo />
      {/* )
      } */}
      <div
        className="App wrapper"
        style={{ backgroundColor: newSidebarOpen ? '#fff' : 'initial' }}
      >
        {Auth.isLoading ? (
          <Loading />
        ) : (
          <Router>
            {Auth.isOnline && !Auth.isSubmitForm ? (
              <>
                {/* <SideNav sideNavIsOpen={sideNavIsOpen} />  */}
                <MainNavbar
                  toggleSideNav={toggleSideNav}
                  sideNavIsOpen={sideNavIsOpen}
                />
                <Sidebar isOpen={isOpen} />
              </>
            ) : (
              ''
            )}
            {!Auth.isOnline && !Auth.isRegister && !Auth.isSubmitForm && (
              <div
                style={{
                  backgroundColor: 'rgba(0,0,0,0.5)',
                  width: '100%',
                  height: '100%',
                  position: 'fixed',
                }}
              >
                <img src={main_background} id="main_background" />
              </div>
            )}
            <Container
              fluid
              className={classNames('content', {
                'is-open': isOpen,
                'is-register': Auth.isRegister,
              })}
              style={{
                padding: (!Auth.isOnline || Auth.isRegister || Auth.isSubmitForm) ? '0' : '50px 0 0',
              }}
            >
              <Scrollbar
                id="main-scroll"
                className={classNames({
                  loginsi: !Auth.isOnline && !Auth.isRegister && !Auth.isSubmitForm,
                  submitform: !!Auth.isFeedbackForm
                })}
                onScroll={(scroll) =>
                  handleScroll(
                    scroll,
                    loc,
                    showFab,
                    isDashboardWorkplace,
                    fetchingPoints,
                    hasMore,
                    dispatch
                  )
                }
                alwaysShowTracks={true}
                renderByPixels={true}
                continuousScrolling={true}
                style={{
                  height: '100%',
                  width: '100%',
                  paddingTop: !Auth.isOnline || Auth.isRegister ? '0' : '13px',
                  paddingBottom:
                    !Auth.isOnline || Auth.isRegister ? '0' : '25px',
                  overflow: 'visible',
                }}
                damping={damping}
                plugins={{
                  overscroll: { damping },
                }}
              >
                {/* <main className={ Auth.isOnline && sideNavIsOpen? 'main-box pt-2':'pt-2' }> */}
                <Switch>
                  <Route
                    exact
                    path="/"
                    render={(props) =>
                      Auth.isOnline ? (
                        <Redirect to="/dashboard/workplace" />
                      ) : (
                        <Login {...props} />
                      )
                    }
                  />

                  <Route
                    exact
                    path="/login"
                    render={(props) =>
                      Auth.isOnline ? <Redirect to="/dashboard" /> : <Login />
                    }
                  />

                  <Route
                    exact
                    path="/register"
                    render={(props) =>
                      Auth.isOnline ? (
                        <Redirect to="/dashboard" />
                      ) : (
                        <Register />
                      )
                    }
                  />

                  <Route
                    exact
                    path="/login/:type"
                    render={(props) =>
                      Auth.isOnline ? <Redirect to="/dashboard" /> : <Login />
                    }
                  />

                  <Route
                    exact
                    path="/feedbacks"
                    render={(props) =>
                      Auth.isOnline ? (
                        <Redirect to="/feedbacks/Open" />
                      ) : (
                        <Login />
                      )
                    }
                  />

                  <Route
                    exact
                    path="/how_to_use"
                    render={(props) =>
                      Auth.isOnline ? (
                        <Redirect to="/how_to_use/introduction" />
                      ) : (
                        <Login />
                      )
                    }
                  />

                  <Route
                    exact
                    path="/feedback/view/:id"
                    render={(props) =>
                      Auth.isOnline ? <Redirect to="Open" /> : <Login />
                    }
                  />

                  <Route
                    exact
                    path="/settings"
                    render={(props) =>
                      Auth.isOnline ? (
                        <Redirect to="/settings/account" />
                      ) : (
                        <Login />
                      )
                    }
                  />

                  <Route
                    exact
                    path="/dashboard"
                    render={(props) =>
                      Auth.isOnline ? (
                        <Redirect to="/dashboard/workplace" />
                      ) : (
                        <Login />
                      )
                    }
                  />

                  <Route
                    exact
                    path="/time_log"
                    render={(props) =>
                      Auth.isOnline ? (
                        <Redirect to="/time_log/record" />
                      ) : (
                        <Login />
                      )
                    }
                  />

                  <PrivateRoute
                    exact
                    path="/how_to_use/:topic"
                    component={HowToUsePage}
                  />
                  <PrivateRoute
                    exact
                    path="/dashboard/workplace"
                    component={Dashboard}
                  />
                  <PrivateRoute
                    exact
                    path="/dashboard/analysis/:staff?/:type?/:id?"
                    component={Dashboard}
                  />
                  <PrivateRoute
                    exact
                    path="/time_log/record"
                    component={Timelog}
                  />
                  <PrivateRoute
                    exact
                    path="/time_log/summary"
                    component={Timelog}
                  />
                  <PrivateRoute
                    exact
                    path="/feedbacks/:status"
                    component={Feedbacks}
                  />
                  <PrivateRoute
                    exact
                    path="/clients"
                    component={ClientsComponent}
                  />
                  <PrivateRoute
                    exact
                    path="/projects"
                    component={ProjectsComponent}
                  />
                  <PrivateRoute
                    exact
                    path="/users"
                    component={UsersComponent}
                  />
                  <PrivateRoute
                    exact
                    path="/feedback/create"
                    component={FeedbackFormPage}
                  />
                  <PrivateRoute
                    path="/feedback/view/:id/:status"
                    component={FeedbackFormPage}
                  />
                  <PrivateRoute
                    path="/settings/:type"
                    component={SettingsComponent}
                  />

                  <Route path="/submitFeedback" component={FeedbackForm} />

                  <Route component={ComponentNotFound} />
                </Switch>

                {/* {
                  Auth.isOnline && 
                  <Tour
                    isOpen={isTourOpen}
                    closeTour={() => dispatch({ type: 'SET_STATE', payload: { isTourOpen: false } })}
                    filter={filteredCategory}
                    modal={modal1}
                    context={{ translation, locale, dispatch }}
                    isInteraction={isInteraction}
                    disableInteraction={disableInteraction}
                    goToStep={goToStep}
                    /> 
                } */}
                {/* </main> */}
              </Scrollbar>
            </Container>
          </Router>
        )}
        <Toast />

        {uploadFiles && (
          <Notification
            show={uploadFiles}
            files={files}
            dispatch={dispatch}
            url={
              uri + '/' + fileName.split('/')[1] + '/' + fileName.split('/')[2]
            }
            fileName={fileName}
            point_no={point_no}
          />
        )}
      </div>

      {showFab3 && (
        <Tooltip title={tooltipTittle}>
          <Fab
            disabled={disableButton}
            variant="extended"
            size="small"
            color="primary"
            onClick={() => fabAction3()}
            className={`fab-add save-fab ${
              showFab3 ? 'scale-in-center' : 'scale-hide'
            }`}
          >
            <SaveIcon />
          </Fab>
        </Tooltip>
      )}

      {showFab && (
        <Tooltip title={tooltipTittle}>
          <Fab
            id="add-point"
            variant="extended"
            size="small"
            color="primary"
            aria-label="Add"
            onClick={() => fabAction()}
            className={`fab-add ${showFab ? 'scale-in-center' : 'scale-hide'}`}
          >
            {
              print ? <AssessmentIcon /> :  <AddIcon />
            }
          </Fab>
        </Tooltip>
      )}

      {/* <div style={{ height: '380px' }}> */}
      {/* <div>
          <SpeedDial
            ariaLabel="Save Feedback"
            icon={<SpeedDialIcon icon={<MenuIcon />} openIcon={<SaveIcon />} />}
            // onBlur={() => setOpenDial(false)}
            onClick={() => fabAction()}
            // onClose={() => setOpenDial(false)}
            // onFocus={() => setOpenDial(true)}
            // onMouseEnter={() => setOpenDial(true)}
            // onMouseLeave={() => setOpenDial(false)}
            open={true}
            hidden={!showFab2}
            direction="left"
            style={{ position: 'absolute', right: '23px', bottom: '20px' }}
          >
            <SpeedDialAction
              icon={<AddIcon />}
              tooltipTitle="Add Point"
              onClick={() => fabActionAdd()}
              hidden={!showFab2}
            />
          </SpeedDial>
        </div> */}
    </Fragment>
  );
};
