import React, { useEffect, useReducer, useContext } from "react";
import { Col, Row } from "antd";

import RegisterReducer from "./RegisterReducer";
import RegisterForm from "./RegisterForm";
import { AuthContext } from "../../contexts/AuthContext";
import { GlobalContext } from "../../contexts/GlobalContext";

const initialState = {
  confirmDirty: false,
  autoCompleteResult: [],
  isLoading: false
};

export default props => {
  const Auth = useContext(AuthContext);
  const context = useContext(GlobalContext);
  const [state, dispatch] = useReducer(RegisterReducer, initialState);

  useEffect(() => {
    Auth.dispatch({ type: "SET_STATE", payload: { isRegister: true } });

    return () => {
      Auth.dispatch({ type: "SET_STATE", payload: { isRegister: false } });
    };
  }, []);

  return (
    <div style={{ height: '100vh' }}>
      {/* Design (Left Side) */}
      <Col className="register-left" md={16} xs={24}>
        <div id="register-header">
          <h4 id="logo-namee" style={{ zIndex: 1, padding: '1px 0 0 0' }}>xactplace</h4>
          <div id="logsgos"></div>
        </div>

        <div className="left-header">
          <h3>{ context.translation.header.main[context.locale] } <span style={{ color: '#BD212D' }}>exactplace</span></h3>
          <h4>{ context.translation.header.sub[context.locale] }</h4>
        </div>

        <div className="flat-character"></div>
      </Col>
      
      {/* Form (Right Side) */}
      <Col className="register-right" md={8} xs={24}>
        <RegisterForm reducer={{state, dispatch}} />
      </Col>
    </div>
  );
}
