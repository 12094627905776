export default (state, action) => {

  switch (action.type) {

    case 'setErrors':
      return {
        ...state,
        errors: action.payload 
      }

    case 'setModal':
      return {
        ...state,
        modal: action.payload
      }

    case 'setVisible':
      return {
        ...state,
        visible: action.payload
      }

    case 'setRole':
      return {
        ...state,
        role: action.payload
      }
    
    case 'setEditVersion': 
      return {
        ...state,
        editVersion: action.payload
      }
    
    case 'setTeam': 
      return {
        ...state,
        team: action.payload
      }
    
    case 'setUser': 
      return {
        ...state,
        user: action.payload
      }
    
    case 'setUsers': 
      return {
        ...state,
        users: action.payload
      }
    
    case 'setProjects': 
      return {
        ...state,
        projects: action.payload
      }
    
    case 'setVersion': 
      return {
        ...state,
        version: action.payload
      }
    
    case 'setVersions': 
      return {
        ...state,
        versions: action.payload
      }
    
    case 'setProj_id': 
      return {
        ...state,
        proj_id: action.payload
      }
    
    case 'setProj_nr': 
      return {
        ...state,
        proj_nr: action.payload
      }
    
    case 'setProj_name': 
      return {
        ...state,
        proj_name: action.payload
      }
    
    case 'setSelected': 
      return {
        ...state,
        selected: action.payload
      }
    
    case 'setModalType': 
      return {
        ...state,
        modalType: action.payload
      }
    
    case 'setTempUsers': 
      return {
        ...state,
        tempUsers: action.payload
      }
    
    case 'setTempVersions': 
      return {
        ...state,
        tempVersions: action.payload
      }
    
    case 'setStatusCount': 
      return {
        ...state,
        statusCount: action.payload
      }

    case 'SET_STATE':
    case 'INITIAL_STATE':
      return {
        ...state,
        ...action.payload
      }

    default:
      return state
  }
}