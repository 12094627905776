import React, { Fragment, useContext, useState, useEffect } from 'react';
import { Row, Col, FormGroup, Label, Input, FormFeedback, FormText } from 'reactstrap';
import Tooltip from '@material-ui/core/Tooltip';
import { message, Popconfirm } from 'antd'
import { GlobalContext } from '../../../contexts/GlobalContext'
import crypto from 'crypto';

import { CREATE_USER, UPDATE_USER } from '../../../queries';

const FormInput = ({ label, input, formfeedback, formtext, generatePassOption, setIsGenerate, context }) => (
  <>
    <Label for={label.for}>{label.name}
      {
        generatePassOption && (
          <span>
            <Label for="exampleCheck" style={{ marginLeft: '5px' }} check><small>{context.translation.generate_password[context.locale]}</small></Label>
            <Input type="checkbox" name="isChangePassword" id="exampleCheck" onChange={(event) => {event.stopPropagation(); setIsGenerate(event.target.checked)}} style={{ position: 'relative', marginTop: 0, marginLeft: '0.25rem' }} />
          </span>
        )
      }
    </Label>
    <Input {...input}/>
    <FormFeedback>{formfeedback}</FormFeedback>
    <FormText>{formtext}</FormText>
  </>
)

export const confirmCreate = (props) => async (e, form) => {
  const { isGenerate, handleConfirm, forms, translation, locale, statusCount, setStatusCount, modal, setModal, isChangePass, setSelectedUser, setForm, setModalType, setType, toggleChangePass, users, setUsers, context, setErrors, errors, type, initialErrorState } = props
  const formData = new FormData(form);

  let payload = {};
  formData.forEach((value,key) => payload[key] = value )

  if (errors.password.invalid || errors.email.invalid) 
    setErrors({ email: { invalid: false, message: '' }, password: { invalid: false, valid: false, message: '' } })

  if (type === 'add' || type === 'edit') {
    if ((passwordIsSame(payload.password, payload.password_confirm) && type === 'add') || 
      (isChangePass && passwordIsSame(payload.password, payload.password_confirm) && type === 'edit') || 
        (!isChangePass && type === 'edit') || (['add', 'edit'].includes(type) && isGenerate)) {
      let additional = {}
      if (type === 'edit') {
        additional = {id: forms.id, old_email: forms.old_email}
      }
      const variables = { 
        data: {...payload, ...additional},
      };

      setStatusCount({...statusCount, total_status: parseInt(statusCount.total_status) + 1, active_status: parseInt(statusCount.active_status) + 1 })
      const messages = translation.msg_user_success;

      return await saveUser(variables, messages, type, message);
    } else {
      setErrors({ ...errors, password: { invalid: true, valid: false, message: translation.password_no_match[locale] } })
    }
  }

  async function saveUser (variables, s, type, message) {
    const hide = message.loading(translation.saving_changes[locale], 0)
    try{
      const res = await context.client.mutate({ mutation: CREATE_USER, variables })
      if (res.data.createUser) {
        res.data.createUser.action = (
          <>
            <Tooltip title={translation.update_account[locale]}>
              <button onClick={() => {setErrors(initialErrorState); setModal(true); ; setModalType('create'); setType('edit'); setForm({id: res.data.createUser.id, first_name: res.data.createUser.first_name, last_name: res.data.createUser.last_name, user_type_id: res.data.createUser.user_type_id, email: res.data.createUser.email, old_email: res.data.createUser.email, group_id: res.data.createUser.group.id, client_id: res.data.createUser.client_id || '' })}} className="btn btn-sm btn-warning">
                <i className="fas fa-edit" style={{ color: '#fff'  }}></i>
              </button>
            </Tooltip>

            <Popconfirm
              placement="topRight"
              title={<div style={{ width: '25vh' }}>{translation.send_email_confirmation[locale]}</div>}
              onConfirm={() => handleConfirm(res.data.createUser)}
              okText="Yes"
              cancelText="No"
            >
              <Tooltip title={translation.send_account_details[locale]}>
                <button className="btn btn-sm btn-primary">
                  <i className="fas fa-envelope" style={{ color: '#fff'  }}></i>
                </button>
              </Tooltip>
            </Popconfirm>
          </>
        )
      }

      if (type === 'add') {
        setUsers([...users, res.data.createUser]);
      } else {
        setUsers(users => users.map(e => e.id == res.data.createUser.id ? {...res.data.createUser} : {...e}));
      }

      hide()
      message.success(translation[variables.data.id != -1? 'msg_user_updated' : 'msg_user_success'][locale])
      // Toast.showToast('success', translation.msg_user_success[locale])

      /* Close Modal */
      toggleChangePass(false)
      return true
    } catch(error) {
      hide()
      const err = error.toString().split(':')
      console.log(err);
      setErrors({ ...errors, email: { invalid: true, message: err[3] } })
      return false
    }
  } 
  
  function passwordIsSame (password, password_confirm) { 
    return password === password_confirm 
  };
}

export default(props => {
  const { form, errors, type, isChangePass, updateFormState, items, clients, toggleChangePass, isGenerate, setIsGenerate, state } = props.payload
  const { translation, locale } = useContext(GlobalContext);
  const [pass, setPass] = useState('password')
  const [c_pass, setCPass] = useState('password')

  function handleCheckbox(event) {
    event.persist();
    toggleChangePass(event.target.checked)
  }

  function handleIsEmailSend(event) {
    event.persist();
    updateFormState({ target: { value: event.target.checked } }, 'isSendEmail')
  }

  useEffect(() => {
    if (isGenerate) {
      function randomString(len, charSet) {
        charSet = charSet || 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var randomString = '';
        for (var i = 0; i < len; i++) {
          var randomPoz = Math.floor(Math.random() * charSet.length);
          randomString += charSet.substring(randomPoz,randomPoz+1);
        }
        return randomString;
      }
      updateFormState({ target: { value: randomString(16) } }, 'password')
    } else {
      if (form.password !== '')
      updateFormState({ target: { value: '' } }, 'password')
    }
  }, [isGenerate])

  // FOr every change of account type, group type is also changed.
  useEffect(() => {
    let res 
    if ((res = items.filter(e => e.user_type_id == form.user_type_id)).length !== 0)
      updateFormState({target: {value: res[0].id}}, 'group_id')
  }, [form.user_type_id])

  return (
    <>
      <Row>
        <Col xs={6}>
          <FormGroup>
            <Label for="first_name">{translation.first_name[locale]}</Label>
            <Input value={form.first_name} onChange={(e) => {updateFormState(e,'first_name')}} type="text" name="first_name" id="first_name" bsSize="sm" readOnly={ type === 'view' } required/>
          </FormGroup>
        </Col>
        <Col xs={6}>
          
          <FormGroup>
            <Label for="last_name">{translation.last_name[locale]}</Label>
            <Input value={form.last_name} onChange={(e) => {updateFormState(e,'last_name')}} type="text" name="last_name" id="last_name" bsSize="sm" readOnly={ type === 'view' } required/>
          </FormGroup>
        </Col>
      </Row>
  
      <FormGroup>
        <FormInput label={{ for: 'email', name: 'Email'  }} 
          input={{ 
            type: "email",
            name: "email",
            id: "email",
            bsSize: "sm",
            readOnly: (type ==='view'),
            required: true,
            invalid: errors.email.invalid,
            value: form.email,
            onChange: (e) => {updateFormState(e,'email')},
            key: 'form_email'
          }}
          formfeedback={errors.email.message} />
      </FormGroup>

      {
        (type === 'edit') && (
          <FormGroup>
            <Label for="exampleCheck" check>{ translation.change_password[locale] }</Label>
            <Input type="checkbox" name="isChangePassword" id="exampleCheck" onChange={handleCheckbox} style={{ position: 'relative', marginTop: 0, marginLeft: '0.25rem' }} />
          </FormGroup>
        )
      }
  
      { 
        (type === 'add' || isChangePass) &&
          <>
            <FormGroup style={{ position: 'relative' }}>
              <FormInput label={{ for: 'pword', name: translation.password[locale]  }} 
                input={{ 
                  type: pass,
                  name: "password",
                  id: "pword",
                  bsSize: "sm",
                  placeholder: translation.password[locale],
                  required: true,
                  pattern: '^(?=.*[A-Za-z0-9])[A-Za-z0-9, .!@#$%^&*()_]{6,25}$',
                  title: translation.msg_password[locale],
                  invalid: errors.password.invalid,
                  valid: errors.password.valid,
                  value: form.password,
                  onChange: (e) => {updateFormState(e,'password')},
                  key: 'form_passsword',
                }}
                generatePassOption={['add', 'edit'].includes(type)}
                setIsGenerate={setIsGenerate}
                context={{ translation, locale }}
                formfeedback={errors.password.message} 
                formtext={translation.msg_password[locale]}/>
                <i onClick={() => setPass(pass == 'password' ? 'text': 'password')} className={`fas ${pass == 'password'? 'fa-eye': 'fa-eye-slash'} view-password`} style={{ position: 'absolute', top: '34px', right: '10px', color: '#007BFF', fontSize: '14px' }}></i>
            </FormGroup>
            {
              !isGenerate && (
                <FormGroup style={{ position: 'relative' }}>
                  <FormInput label={{ for: 'c_pword', name: translation.confirm_password[locale]  }} 
                      input={{ 
                        type: c_pass,
                        name: "password_confirm",
                        id: "c_pword",
                        bsSize: "sm",
                        placeholder: translation.confirm_password[locale],
                        required: true,
                        value: form.password_confirm,
                        onChange: (e) => {updateFormState(e,'password_confirm')},
                        key: 'form_password_confirm'
                    }} />
                    <i onClick={() => setCPass(c_pass == 'password' ? 'text': 'password')} className={`fas ${c_pass == 'password'? 'fa-eye': 'fa-eye-slash'} view-password`} style={{ position: 'absolute', top: '34px', right: '10px', color: '#007BFF', fontSize: '14px' }}></i>
                </FormGroup>
              )
            }
          </> 
      }
      {/* <FormGroup>
        <Label for="exampleCheckbox">{translation.user_type[locale]}</Label>
        <div style={{ textAlign: 'center' }}>
          <CustomInput value={2} checked={form.user_type_id == 2} onChange={(e) => {updateFormState(e,'user_type_id')}} type="radio" id="radio-administrator" name="user_type_id" label={<span style={{ paddingTop: '2.5px', display: 'inline-block' }}>{translation.administrator[locale]}</span>} inline/>
          <CustomInput value={6} checked={form.user_type_id == 6} onChange={(e) => {updateFormState(e,'user_type_id')}} type="radio" id="radio-member" label={<span style={{ paddingTop: '2.5px', display: 'inline-block' }}>{translation.member[locale]}</span>} name="user_type_id" inline/>
          <CustomInput value={7} checked={form.user_type_id == 7} onChange={(e) => {updateFormState(e,'user_type_id')}} type="radio" id="radio-client" label={<span style={{ paddingTop: '2.5px', display: 'inline-block' }}>{translation.client[locale]}</span>} name="user_type_id" inline/>
        </div>
      </FormGroup> */}

      <Row>
        <Col xs={6}>
          <FormGroup>
            <Label for="exampleSelect">{translation.user_type[locale]}</Label>
            <Input type="select" name="user_type_id" id="user_type_id" onChange={(e) => {e.persist();updateFormState(e,'user_type_id')}} value={form.user_type_id}>
              <option value={2}>{translation.administrator[locale]}</option>
              <option value={6}>{translation.member[locale]}</option>
              <option value={7}>{translation.client[locale]}</option>
            </Input>
          </FormGroup>
        </Col>
          
        <Col xs={6}>
          <FormGroup>
            <Label for="exampleSelect">{translation.groups[locale]}</Label>
            <Input type="select" name="group_id" id="group_id" onChange={(e) => {e.persist();updateFormState(e,'group_id')}} value={form.group_id}>
              {
                items.length !== 0 &&
                  items.filter(e => e.user_type_id == form.user_type_id).map((e, i) => (
                    <option key={i} value={e.id}>{e.name}</option>
                  ))
              }
            </Input>
          </FormGroup>
        </Col>
      </Row>
      
      {
        form.user_type_id == 7 && (
          <Row>
            <Col xs={12}>
              <FormGroup>
                <Label for="exampleSelect">{translation.clien[locale]}</Label>
                {
                  !!!state.modalField ? 
                    <Input type="select" name="client_id" id="client_id" value={form.client_id} onChange={(e) => {e.persist();updateFormState(e,'client_id')}} required>
                      <option value={''}>{ translation.select_client[locale] }</option>
                      {
                        clients.length !== 0 &&
                          clients.sort(function(a, b){
                            var nameA=a.clientname.toLowerCase(), nameB=b.clientname.toLowerCase()
                            if (nameA < nameB) //sort string ascending
                                return -1 
                            if (nameA > nameB)
                                return 1
                            return 0 //default return value (no sorting)
                        }).map((client, i) => (
                          <option key={i} value={client.id}>{client.clientname}</option>
                        ))
                      }
                    </Input>
                  : state.modalField.client.type === 'New' ? 
                    <span style={{ display: 'block' }}><b>{ state.modalField.client.clientname }</b></span> 
                  : <span style={{ display: 'block' }}><b>{ state.clients.find(e => e.id == state.modalField.client.id).clientname }</b></span>
                }
              </FormGroup>
            </Col>
          </Row>
        )
      }

      {
        type === 'add' && (
          <FormGroup>
            <Label for="exampleCheck" check>{ translation.send_account_details[locale] }</Label>
            <Input type="checkbox" name="isSendEmail" id="send-email-checkbox" onChange={handleIsEmailSend} style={{ position: 'relative', marginTop: 0, marginLeft: '0.25rem' }} />
          </FormGroup>
        )
      }
    </>
  )
})