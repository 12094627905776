import React, { useEffect, useContext, useReducer, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Container, Row, Col } from 'reactstrap'
import Typography from '@material-ui/core/Typography';

import { Empty, Spinner2 } from '../../../common';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { Divider } from 'antd';
import moment from 'moment';

import TaskList from './AssignedTaskList'
import CapacityChart from './CapacityChart'
import UnassignedTaskList from './UnassignedTaskList'
import DeveloperAnalysis from './DeveloperAnalysis'
import Modal from '../../feedback/form/FeedbackFormModal'

/* IMPORT QUERIES */
import DashboardReducer from '../DashboardReducer'
import { DASHBOARD_ANALAYSIS_QUERY } from '../../../../queries';

import { ApolloContext } from 'react-apollo'; 

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  }
}));

const initialEditedItem = {
  user: { id: 0, name: '' },
  instructions: '',
  status: 'Open',
  deadline: '',
  time_estimate: '',
  hours_worked: '',
  version: 0,
  priority: 'Low',
  comments: [],
  users: [],
  deadline: moment(),
  deadlineDisplay: 'N/A',
  comment: '',
}

const editField = {
  editPoint: false,
  editInstructions: false,
  editStatus: false,
  editTimeEstimate: false,
  editHoursWorked: false,
  editDeadline: false,
  editPriority: false,
  editAssignedUsers: false,
  editVersion: false,
}

const initialState = {
  ...editField,
  editedItem: {...initialEditedItem},
  assignedTasks: [],
  unassignedTasks: [],
  unassignedProjects: [],
  expand: [],
  modal: false,
  modalType: '',
  files: [],
  removefiles: [],
  project: {
    id: -1,
    name: ''
  },
  role: '',
  gallery: [],
  members: [],
  editPoint: false,
  uploadNewFiles: false,
  loadURI: '',
  rowData: {files: []},
  dupFiles: [],
  versions: [],
  points: [],
  isFeedbacklist: 'analysis',
  isLoading: true,
}

let groupname = ''
export default ({ context }) => {
  const [state, dispatch] = useReducer(DashboardReducer, initialState)
  const [loading, setLoading] = useState(false)
  const { client } = useContext(ApolloContext)
  const classes = useStyles();
  const columns = [{
    dataField: 'id',
    text: 'id',
    hidden: true
  }, {
    dataField: 'name',
    text: context.translation.client[context.locale],
    style: {
      fontWeight: 600,
      paddingBottom: 0,
      borderTop: 0,
      color: '#1890FF'
    },
    formatter: (cell, row, rowIndex, formatExtraData) => {
      let result
      //fix
      if (row.group != null) {
        if(rowIndex===0){
          
          groupname = row.group.name
          result = (<>
                      <span className="group-name" style={{ textAlign: 'center', display: 'inline-block', width: '100%',color: '#004f98', marginBottom:'10px', fontSize: '1.75em' }}>{row.group.name}</span>
                      <span className="link-hovers" onClick={() => context.history.push(`/dashboard/analysis/${row.id}/all/all`)}>{cell} </span> 
                      
                    </>)
        }
        else if (groupname !== row.group.name) {
          groupname = row.group.name
          result = (<>
                        
                      <Divider style={{borderBottom: '#dadada 2px solid'}}></Divider>
                      <span className="group-name" style={{ textAlign: 'center', display: 'inline-block', width: '100%',color: '#004f98',marginBottom:'10px', fontSize: '1.75em' }}>{row.group.name}</span>
                      <span className="link-hovers" onClick={() => context.history.push(`/dashboard/analysis/${row.id}/all/all`)}>{cell} </span> 
                    </>)
        } else {
          result = (<>
            <Divider orientation="left">
              <span className="link-hovers" onClick={() => context.history.push(`/dashboard/analysis/${row.id}/all/all`)}>{cell} </span> 
            </Divider>
          </>)
        }
      } else {
        result = (<>
          <Divider orientation="left">
            <span className="link-hovers" onClick={() => context.history.push(`/dashboard/analysis/${row.id}/all/all`)}>{cell} </span> 
          </Divider>
        </>)
      }
      
      return result
    },
    attrs: { colSpan: 7 },
    headerStyle: () => ({ width: '25%' }),
  }, {
    text: context.translation.nav.project[context.locale],
    style: {
      display: 'none',
    },
  }, {
    text: context.translation.remaining_hours[context.locale],
    style: { display: 'none' },
    headerAlign: 'center',
  }, {
    text: context.translation.hours_worked[context.locale] + ' (' + context.translation.individual[context.locale] + ')',
    style: { display: 'none' },
    headerAlign: 'center',
  }, {
    text: context.translation.hours_worked[context.locale] + ' (' + context.translation.nav.project[context.locale] + ')',
    style: { display: 'none' },
    headerAlign: 'center',
  }, {
    text: context.translation.open_points[context.locale],
    style: { display: 'none' },
    headerAlign: 'center',
  }, {
    text: context.translation.question_points[context.locale],
    style: { display: 'none' },
    headerAlign: 'center',
  }];
  const expandRow = {
    renderer: row => <TaskList reducer={{ state, dispatch, points: row.points.map(point => ({...point, user_id: row.id})) }} context={context} />,
    // showExpandColumn: true,
    expanded: state.expand,
    onExpand: handleOnExpand
  }

  function handleOnExpand(row, isExpand, rowIndex, e) {
    if (isExpand) 
      dispatch({ type: 'SET_STATE', payload: { expand: [...state.expand] } })
    else 
      dispatch({ type: 'SET_STATE', payload: { expand: [...state.expand] } })
  }

  useEffect(() => {
    if (!state.modal)
      dispatch({ type: 'SET_STATE', payload: { editedItem: {...initialEditedItem}, files: [], removefiles: [], gallery: [], editPoint: false, id: -1, ...editField } })
  }, [state.modal])

  useEffect(() => {
    if(state.assignedTasks.length !== 0) {
      let expand = state.assignedTasks.map(e => e.id)
      dispatch({ type: 'SET_STATE', payload: { expand } })
    }
  }, [state.assignedTasks.length])
  
  useEffect(() => {
    setLoading(true)
    client.query({ query: DASHBOARD_ANALAYSIS_QUERY }).then(({data: {assignedTasks, unassignedTasks, unassignedProjects}}) => {
      dispatch({ 
        type: 'SET_STATE', 
        payload: { 
          assignedTasks: assignedTasks.map(user => ({
            ...user, 
            points: user.points.filter(point => point.open_status != 0 || point.question_status != 0).map(point => ({...point, total_time_estimate: point.total_time_estimate || '00:00', total_hours_used: point.total_hours_used || '00:00' }))
          })),
          unassignedTasks: unassignedTasks.map(point => ({...point, total_time_estimate: point.total_time_estimate || '00:00' })),
          unassignedProjects
        } 
      })
      setLoading(false)
    })
  }, [])

  const options = {
    // pageStartIndex: 0,
    sizePerPage: 5,
    hideSizePerPage: true,
    hidePageListOnlyOnePage: true
  };

  function alphabetically() {
    return function (a, b) {
      // equal items sort equally
      if (a.points.length === b.points.length) {
        return 0;
      }
      // nulls sort after anything else
      else if (a.points.length === 0) {
        return 1;
      }
      else if (b.points.length === 0) {
        return -1;
      }
      // otherwise, if we're ascending, lowest sorts first
      else {
        return a.name < b.name ? -1 : 1;
      }
    };
  }

  return (
    <>
      <Modal state={state} dispatch={dispatch} />

      <Row id="div-assigned-task">
        <Col xs={12} style={{ width: '100px' }}>
          <Paper className={classes.paper}>
            <Typography variant="h6" gutterBottom>{context.translation.capacity_calendar[context.locale]}</Typography>  
            <CapacityChart reducer={{ state, dispatch, loading }} context={context}/>
          </Paper>
        </Col>

        <Col xl={12} lg={12} style={{ padding: 0, display: 'flex', flexWrap: 'wrap' }}>
          <Col xl={6} lg={6} md={12}>
            <Paper className={classes.paper} style={{ height: '90%' }}>
              <Typography variant="h6" gutterBottom>{context.translation.unassigned_projects[context.locale]}</Typography>  
              <UnassignedTaskList reducer={{ state, dispatch, loading }} data={state.unassignedProjects} type={'projects'} context={context}/>
            </Paper>
          </Col>
          <Col xl={6} lg={6} md={12}>
            <Paper className={classes.paper} style={{ height: '90%' }}>
              <Typography variant="h6" gutterBottom>{context.translation.unassigned_task[context.locale]}</Typography>  
              <UnassignedTaskList reducer={{ state, dispatch, loading }} data={state.unassignedTasks} type={'tasks'} context={context}/>
            </Paper>
          </Col>
        </Col>

        <Col xl={12} lg={12}>
          <Paper className={classes.paper}>
            <Typography variant="h6" gutterBottom>{context.translation.assigned_task[context.locale]}</Typography>  
            {
              !!!context.params.staff ? 
                <BootstrapTable
                  id="table-assigned-task"
                  keyField="id"
                  columns={ columns } 
                  data={ state.assignedTasks }
                  bootstrap4
                  noDataIndication = { () => loading ? <Spinner2 />: <Empty /> }
                  expandRow = { expandRow }
                  loading={loading}
                  bordered={ false }
                  // pagination={ paginationFactory(options) }
                  // hover
                /> :
                <DeveloperAnalysis reducer={{ state, dispatch }} context={context} />

            }
          </Paper>
        </Col>
      </Row>
    </>
  );
}