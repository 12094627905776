import React, { useState, useEffect, useContext } from 'react';
import { Empty, Spinner2, ContentCard } from '../../../common';
import { Popover, Avatar, message, Icon } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faUserCircle } from '@fortawesome/free-regular-svg-icons';
import Tooltip from '@material-ui/core/Tooltip';

import { POINT_ANALYSIS_QUERY } from '../../../../queries';
import { ApolloContext } from 'react-apollo'; 
import { AuthContext } from '../../../../contexts/AuthContext';
import { GlobalContext } from '../../../../contexts/GlobalContext';

export default ({ reducer: { state, dispatch }, data: { cell, row, old_count, status, table }, context }) => {
  const [points, setPoints] = useState([])
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const { client } = useContext(ApolloContext)
  const { user } = useContext(AuthContext);
  // const context = useContext(GlobalContext);

  useEffect(() => {
    if (visible) {
      setLoading(true)
      client.query({ query: POINT_ANALYSIS_QUERY({ id: row.proj_id, type: 'Project', user_id: row.user_id, status }, table) }).then(({ data: { points } }) => {
        setPoints(points || [])
        setLoading(false)
      })
    }
  }, [visible])

  async function onClick (row, rowIndex) {
    setVisible(false)
    // localStorage.setItem('buttonClicked', false)
    console.log(context)
    const {last_change, feedback_id, title, creator_id, creator, comments, clientname, deadline, deadlineMoment, date, date_created, files, team: users, hours_worked, time_estimate, time_approve, version, version_name, instructions, priority, status, proj_id} = row
    console.log(context.temp_projects)
    console.log(row.proj_id)
    const members =  context.temp_projects.find(e => e.id == row.proj_id).team
    let editItem = (row.id > 0)? { id: row.id, point_nr: row.point_nr }: {}

    let project_role = 'None'
    members.forEach(e => {
      if (e.id == user.id) 
        project_role = e.project_role
    })
    const payload = {
      editedItem: {
        ...state.editedItem,
        ...editItem,
        comments, 
        feedback_id,
        clientname, 
        last_change,
        users,
        date,
        date_created,
        creator_id, 
        creator,
        time_estimate: time_estimate === 'N/A'? '' : time_estimate, 
        hours_worked: hours_worked === 'N/A' ? '' : hours_worked,
        version: version === 'N/A'? state.versions[0].id + "|" + state.versions[0].version_name: version, 
        version_name, 
        time_approve,
        instructions, 
        priority, 
        status,
        deadline: deadlineMoment,
        deadlineDisplay: deadline,
        rowIndex,
        loggedUserNotOnTeam: !!!context.temp_projects.find(project => project.id == proj_id ? !!project.team.find(dev => dev.id == user.id): false)
      },
      files: (row.id > 0) ? files.map(e => ({source: `project_${row.proj_id}/feedback_${row.feedback_id}/point_${row.point_nr}_${row.id}/${e.filename}`, options: { type: 'local' }, filename: e.filename})): files,
    }
  

    dispatch({ type: 'SET_STATE', 
      payload: { 
        id: row.feedback_id,
        // assignedTasks: state.assignedTasks,
        // isFeedbackList: 'analysis',
        modal: true, 
        title,
        modalType: 'update',
        project: {
          id: row.proj_id,
          name: row.proj_name
        },
        project_role,
        members,
        versions: context.temp_projects.find(e => e.id == row.proj_id).project_versions.map(e => ({id: e.id, version_name: e.version_name})),
        ...payload,
        rowData: {...payload},
        // For default data when cancel is clicked
        // gallery: files.map(e => ({ filename: e.filename, src: `${context.uri}?load=project_${state.project.id}/feedback_${state.id}/point_${row.point_nr}_${row.id}/${e.filename}`, width: 1, height: 1 }))
      } 
    })
  } 

  const Title = () => (<><span style={{ display: 'block' }}>{row.proj_name}</span><small style={{ display: 'block', color: '#afafaf' }}>{row.clientname}</small></>)
  return (
    <Popover 
      placement="rightTop" 
      visible={visible}
      onVisibleChange={visible => setVisible(visible)}
      title={<Title />} 
      content={
        <>
          <div id="point-card">
            {
              !loading ? 
                points.length !== 0 ? 
                  points.map((point, index) => <ContentCard key={index} data={{ point, index }} context={context} clickEvent={onClick} />)
                : <Empty />
              : <Spinner2 />
            }       
          </div>
        </>
      } trigger="click">
    {/* //fix  */}
    {/* <span  className={`${cell !== 0 ? 'link-me': ''} ${old_count > 0 ? 'text-danger': ''}`}>{cell}</span>  */}
    <span  className={`${cell !== 0 ? 'link-me': ''}`} style={{ position: 'relative', display: 'inline-block', width: '50px' }}>
      {cell} 
      { old_count > 0 && <Icon style={{ color: 'yellow', verticalAlign: 'middle', position: 'absolute', top: '3px', right: '0' }} type="clock-circle" /> }
    </span> 
     
    </Popover>
  )
};