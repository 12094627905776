import React, { useContext } from 'react';
import { withRouter } from 'react-router-dom'
import { Navbar, Collapse, Nav, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import {
  Form,
  Input,
  Select,
  Row,
  Col,
  Button,
  message
} from 'antd';

import Icons from '../common/Icon';
import { GlobalContext } from '../../contexts/GlobalContext';
import { ApolloContext } from 'react-apollo';
import { CREATE_USER } from '../../queries';
import gerFlag from '../../brand/ger-flag.png';
import engFlag from '../../brand/eng-flag.png';
import countries from '../../lang/countries.json';
import phone from '../../lang/phone.json';

const { Option } = Select;

export default Form.create({ name: 'register' })(withRouter((props) => {
  const context = useContext(GlobalContext)
  const { client } = useContext(ApolloContext)
  const { state, dispatch } = props.reducer;
  const { getFieldDecorator } = props.form;
  const setActive = lang => (context.locale === lang) ? true : false;
  const setFlag = () => (context.locale === 'de') ? gerFlag : engFlag;

  const prefixSelector = getFieldDecorator('prefix', {
    initialValue: '+49',
  })(
    <Select style={{ width: 90 }}>
      {
        phone.filter(item => countries.find(country => (country.code == item.code)) && phone.map(s => s.dial_code == item.dial_code).filter(a => a).length == 1)
              .sort((a, b) => parseInt((a.dial_code).substring(1, a.dial_code.length).replace(' ', '')) - parseInt((b.dial_code).substring(1, b.dial_code.length).replace(' ', '')))
              .map((item, index) => <Option key={index} value={item.dial_code}>{ item.dial_code }</Option>)
      }
    </Select>
  );

  function handleSubmit(e) {
    e.preventDefault();
    props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        dispatch({ type: 'TOGGLE_LOADING' });
        values.phone = values.prefix + values.phone;
        values = {
          type: 'Subscription',
          email: values.email,
          plan_id: 100,
          company_name: values.company,
          street_nr: values.street_no,
          address: values.street_name,
          country: values.country,
          zip_code: values.zip_code,
          phone: values.phone,
          User: {
            ...values,
            user_type_id: 2,
            is_active: 'yes',
          }
        }
        console.log('Received values of form: ', values);
        client.mutate({ mutation: CREATE_USER, variables: { data: values } })
          .then(({ data }) => {
            message.success(context.translation.register_success[context.locale]);
            dispatch({ type: 'TOGGLE_LOADING' });
            props.history.push('/');
          })
          .catch(error => {
            console.log(error)
            dispatch({ type: 'TOGGLE_LOADING' });
            const err = error.toString().split(':')
            message.error(err[3])
          })
      }
    });
  };

  function handleConfirmBlur(e) {
    const { value } = e.target;
    dispatch({ type: 'SET_STATE', payload: { confirmDirty: state.confirmDirty || !!value } })
  };

  function compareToFirstPassword(rule, value, callback) {
    const { form } = props;
    if (value && value !== form.getFieldValue('password')) {
      callback(context.translation.password_not_match[context.locale]);
    } else {
      callback();
    }
  };

  function validateToNextPassword(rule, value, callback) {
    const { form } = props;
    if (value && state.confirmDirty) {
      form.validateFields(['confirm'], { force: true });
    }
    callback();
  };

  function handleWebsiteChange(value) {
    let autoCompleteResult;
    if (!value) {
      autoCompleteResult = [];
    } else {
      autoCompleteResult = ['.com', '.org', '.net'].map(domain => `${value}${domain}`);
    }
    dispatch({ type: 'SET_STATE', payload: { autoCompleteResult: state.autoCompleteResult } })
  };
  const config = {
    rules: [{ type: 'object', required: true, message: 'Please select time!' }],
  };
  return (
    <>
      <Navbar className="main-navbar flex-md-nowrap p-0" style={{ backgroundColor: 'transparent', height: '49px', position: 'absolute', right: '20px', top: '20px' }} light expand="md">
        <Collapse className="mr-2" navbar>
          <Nav className="ml-auto" style={{ height: '50px', paddingTop: '7px' }} navbar>
            <UncontrolledDropdown id="lang" className="ml-2" style={{ marginRight: '7px' ,backgroundColor: 'transparent' }} nav inNavbar>
              <DropdownToggle nav>
                <Icons height="17" width="20" src={setFlag()}  />
              </DropdownToggle>
              <DropdownMenu right className="mb-2" style={{ fontSize: '.795rem' }}>
                <DropdownItem onClick={() => context.setLocale('en')} style={{ paddingLeft: '15px' }} active={setActive('en')}><small style={{ marginRight: '10px' }}>EN</small> English</DropdownItem>
                <DropdownItem onClick={() => context.setLocale('de')} style={{ paddingLeft: '15px' }} active={setActive('de')}><small style={{ marginRight: '10px' }}>DE</small> German</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Collapse>
      </Navbar>


      <div className="right-header">
        <h3>{ context.translation.registration.main[context.locale] } </h3>
        <h5>{ context.translation.registration.sub[context.locale] }</h5> <br />
      </div>

      <Form layout="vertical" onSubmit={handleSubmit}>
        {/* ==== Name Field ==== */}
        <Form.Item label={<label className="ant-form-item-required" title="Name">Name</label>} style={{ marginBottom: 0, paddingBottom: 0 }}>
          <Row gutter={8}>
           <Col span={12}>
              <Form.Item layout="inline">
                {getFieldDecorator('first_name', {
                  trigger: 'onBlur',
                  valuePropName: 'defaultValue',
                  rules: [{ required: true, message: context.translation.err_name[context.locale], whitespace: false }],
                })(<Input placeholder={context.translation.first_name[context.locale]} />)}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item layout="inline">
                {getFieldDecorator('last_name', {
                  trigger: 'onBlur',
                  valuePropName: 'defaultValue',
                  rules: [{ required: true, message: context.translation.err_name[context.locale], whitespace: false }],
                })(<Input placeholder={context.translation.last_name[context.locale]} />)}
              </Form.Item>
            </Col>
          </Row>
        </Form.Item>

        {/* ==== Email Field ==== */}
        <Form.Item label={context.translation.username[context.locale]}>
          {getFieldDecorator('email', {
            trigger: 'onBlur',
            valuePropName: 'defaultValue',
            rules: [
              {
                type: 'email',
                message: context.translation.err_invalid_email[context.locale],
              },
              {
                required: true,
                message: context.translation.err_email[context.locale],
              },
            ],
          })(<Input placeholder={context.translation.username[context.locale]}/>)}
        </Form.Item>

        {/* ==== Password Field ==== */}
        <Form.Item label={context.translation.password[context.locale]} hasFeedback>
          {getFieldDecorator('password', {
            trigger: 'onBlur',
            valuePropName: 'defaultValue',
            rules: [
              {
                required: true,
                message: context.translation.err_password[context.locale],
              },
              {
                validator: validateToNextPassword,
              },
              { 
                min: 5, 
                message: context.translation.password_requirements1[context.locale]
              }
            ],
          })(<Input.Password placeholder={context.translation.password[context.locale]} />)}
        </Form.Item>

        {/* ==== Confirm Password Field ==== */}
        <Form.Item label={context.translation.confirm_password[context.locale]} hasFeedback>
          {getFieldDecorator('confirm_password', {
            trigger: 'onBlur',
            valuePropName: 'defaultValue',
            rules: [
              {
                required: true,
                message: context.translation.err_confirm_password[context.locale],
              },
              {
                validator: compareToFirstPassword,
              },
            ],
          })(<Input.Password placeholder={context.translation.confirm_password[context.locale]} onBlur={handleConfirmBlur} />)}
        </Form.Item>

        {/* ==== Company Name Field ==== */}
        <Form.Item label={context.translation.company_name[context.locale]}>
          {getFieldDecorator('company', {
            trigger: 'onBlur',
            valuePropName: 'defaultValue',
            rules: [{ required: true, message: context.translation.err_comapny_name[context.locale] }],
          })(<Input placeholder={context.translation.company_name[context.locale]} />)}
        </Form.Item>

        {/* ==== Phone Number Field ==== */}
        <Form.Item label={context.translation.phone_number[context.locale]}>
          {getFieldDecorator('phone', {
            trigger: 'onBlur',
            valuePropName: 'defaultValue',
            rules: [{ required: true, message: context.translation.err_phone_number[context.locale] }],
          })(<Input placeholder={context.translation.phone_number[context.locale]} addonBefore={prefixSelector} style={{ width: '100%' }} />)}
        </Form.Item>

         {/* ==== Street Field ==== */}
        <Form.Item label={<label className="ant-form-item-required" title={context.translation.street_name[context.locale]}>{context.translation.street_name[context.locale]}</label>}  style={{ marginBottom: 0, paddingBottom: 0 }}>
         <Row gutter={8}>
            <Col span={19}>
              <Form.Item>
                {getFieldDecorator('street_name', {
                  trigger: 'onBlur',
                  valuePropName: 'defaultValue',
                  rules: [{ required: true, message: context.translation.err_street_name[context.locale] }],
                })(<Input placeholder={context.translation.street_name[context.locale]} />)}
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item>
                {getFieldDecorator('street_no', {
                    trigger: 'onBlur',
                    valuePropName: 'defaultValue',
                    rules: [{ required: true, message: context.translation.err_street_no[context.locale], whitespace: false }],
                  })(<Input placeholder={context.translation.street_no[context.locale]} />)}
              </Form.Item>
            </Col>
          </Row>
          
        </Form.Item>

         {/* ==== City Field ==== */}
        <Form.Item label={<label className="ant-form-item-required" title={context.translation.city[context.locale]}>{context.translation.city[context.locale]}</label>}  style={{ marginBottom: 0, paddingBottom: 0 }}>
         <Row gutter={8}>
            <Col span={19}>
              <Form.Item>
                {getFieldDecorator('city', {
                  trigger: 'onBlur',
                  valuePropName: 'defaultValue',
                  rules: [{ required: true, message: context.translation.err_city[context.locale] }],
                })(<Input placeholder={context.translation.city[context.locale]} />)}
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item>
                {getFieldDecorator('zip_code', {
                    trigger: 'onBlur',
                    valuePropName: 'defaultValue',
                    rules: [{ required: true, message: context.translation.err_zip_code[context.locale], whitespace: false }],
                  })(<Input placeholder={context.translation.zip_code[context.locale]} />)}
              </Form.Item>
            </Col>
          </Row>
          
        </Form.Item>

        {/* ==== Country Field ==== */}
        <Form.Item label={context.translation.country[context.locale]}>
          {getFieldDecorator('country', {
            trigger: 'onBlur',
            valuePropName: 'defaultValue',
            rules: [{ required: true, message: context.translation.err_select_country[context.locale] }],
          })(
            <Select
              showSearch
              placeholder={context.translation.select_country[context.locale]}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {
                countries.map((item, index) => <Option key={index} value={item.code}>{item.name}</Option>)
              }
            </Select>
          )}
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={state.isLoading}>
            { context.translation.nav.register[context.locale] }
          </Button>
          <Button type="link" onClick={() => props.history.push('/login')} style={{ marginLeft: '8px' }}>{ context.translation.signin[context.locale] }</Button>
        </Form.Item>
      </Form>
    </>
  );
}))