import React, { useContext, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Form } from 'reactstrap';
import Button from '@material-ui/core/Button';
import { message } from 'antd';
import { makeStyles } from '@material-ui/core/styles';
import { GlobalContext } from '../../contexts/GlobalContext'


export default ({ location, disable, children, title, accessFunction, modal, setModal, width, hideActions, cancelFunction, uploading, cancelLabel, saveLabel, autoFocus, isHide }) => {
  const { translation, locale, dispatch, isTourOpen } = useContext(GlobalContext)

  useEffect(() => {
    dispatch({ type: 'SET_STATE', payload: { modal1: modal } })
  }, [modal])

  const toggle = () => {
    console.log('=====UPLOADING=====')
    console.log(uploading)
    if (uploading) {
      message.warning(translation.upload_not_complete[locale])
    } else {
      console.log(cancelFunction)
      if (!!cancelFunction) {
        if (!isEquivalent(cancelFunction.editedItem, cancelFunction.rowData.editedItem)) {
          if (window.confirm('You have unsaved changes, are you sure you want to leave?')) {
            setModal(false)
          }
        } else {
          setModal(false)
        }
      } else {
        setModal(false);
      }
    }
  };

  function isEquivalent(a, b) {
    // Create arrays of property names
    var aProps = Object.getOwnPropertyNames(a);
    var bProps = Object.getOwnPropertyNames(b);

    // If number of properties is different,
    // objects are not equivalent 
    if (aProps.length != bProps.length) {
        return false;
    }

    for (var i = 0; i < aProps.length; i++) {
        var propName = aProps[i];

        // If values of same property are not equal,
        // objects are not equivalent
        if (a[propName] !== b[propName]) {
            return false;
        }
    }

    // If we made it this far, objects
    // are considered equivalent
    return true;
}
  
  return (
    <div>
      <Modal keyboard={false} isOpen={modal} toggle={toggle} backdrop={'static'} style={{ maxWidth: width || '500px' }}>
        <Form onSubmit={async (e) => {
          e.preventDefault();
          if (!!accessFunction) {
            if (await accessFunction(e, document.forms.namedItem('modal-form'))) {
              if (location && isTourOpen)
                dispatch({ type: 'SET_STATE', payload: { goToStep: location } })
              toggle() 
            }
          } else {
            toggle()
          }
        }} name="modal-form">
          <ModalHeader toggle={toggle}>{ title }</ModalHeader>
          <ModalBody>
            { children }
          </ModalBody>
          <ModalFooter style={{ display: (hideActions)? 'none': 'flex' }}>
            <Button color="secondary" onClick={toggle}>{cancelLabel || translation.cancel[locale]}</Button>{' '}
            {
              !isHide &&
                <Button autoFocus={autoFocus} disabled={disable} style={{ display: (cancelLabel)? 'none': 'initial', opacity: disable? 0.3: 1 }} id="ok_modal" color="primary" type="submit">{saveLabel || translation.save[locale]}</Button>
            }
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
}