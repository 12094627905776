import React, { useEffect, useContext, useState } from 'react';
import { Type } from 'react-bootstrap-table2-editor';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { Spinner2, Empty } from '../../common';
import { Switch, Button, Popover, Icon, Button as Buttono, message } from 'antd';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import arrayMove from 'array-move';

import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { ApolloContext } from 'react-apollo';
import { AuthContext } from '../../../contexts/AuthContext';
import { GlobalContext } from '../../../contexts/GlobalContext';
import { UPDATE_PRIORITY, UPDATE_PROJECT_STATUS } from '../../../queries';
import ProjectArchive from './ProjectArchive';

const DragHandle = sortableHandle(() => <span className="sort-draggable"><Icon type="menu" /></span>);

const SortableItem = sortableElement(({value}) => (
  <li className="SortableItem">
    <div>
      <span style={{ display: 'block' }}>{value.proj_name}</span>
      <small style={{ display: 'block', color: '#6C757D' }}>{value.client.clientname}</small>
    </div>
    <DragHandle /> 
  </li>
));

const SortableContainer = sortableContainer(({children}) => {
  return <ul className="SortableList">{children}</ul>;
});

export default ({ state, dispatch, clearState, context }) => { 
  const { client } = useContext(ApolloContext)
  const { SearchBar } = Search;
  const { user } = useContext(AuthContext)
  const { translation, locale } = useContext(GlobalContext)
  const [items, setItems] = useState([])
  const [archives, setArchives] = useState([])
  const [tempItems, setTempItems] = useState([])
  const [tempArchives, setTempArchives] = useState([])
  const [isOrderChanged, setIsOrderChanged] = useState([])
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [visible1, setVisible1] = useState(false)

  const selectedRow = user.role === 'Administrator' ? {              
    selectRow: {
      mode: 'checkbox',
      selected: state.selected,
      onSelect: (row, isSelect, rowIndex, e) => {
        if (isSelect) 
          dispatch({ type: 'setSelected', payload: [ ...state.selected, row.id ] })
        else 
          dispatch({ type: 'setSelected', payload: state.selected.filter(x => x !== row.id) })
      },
      onSelectAll: (isSelect, rows, e) => {
        const ids = rows.map(r => r.id);
        if (isSelect) 
          dispatch({ type: 'setSelected', payload: ids })
        else
          dispatch({ type: 'setSelected', payload: [] })
      }
    }
  } : {}

  const onSortEnd = ({oldIndex, newIndex}) => {
    setItems(arrayMove(items, oldIndex, newIndex))
  };

  useEffect(() => {
    if (state.projects.length !== 0) {
      const itemso = state.projects.map(e => ({...e, clientname: !!e.client? e.client.clientname : 'Deleted Client' }))
        .filter((e,i) => e.team.find(team => team.id == user.id && e.status === 'Open'))
          .sort((a, b) => {
            const first = a.team.find(team => team.id == user.id).priority
            const next = b.team.find(team => team.id == user.id).priority
            return first - next
          })

      setDuo(itemso)
    }
  }, [state.projects])

  useEffect(() => {
    if (items.length !== 0 && tempItems.length !== 0) {
      let result = false
      items.forEach((item, index) => {
        if (item.id != tempItems[index].id)
          result = true
      })
  
      setIsOrderChanged(!result)
    }
  }, [items, tempItems])

  useEffect(() => {
    context.dispatch({ type: 'SET_STATE', payload: { loc:  'essentials', showFab: false ,tooltipTittle: translation.add_project[locale], fabAction: () => {
      // user.role != 'Member'? 'essentials': '' ---> UNCOMMENT IF NEEDED
      dispatch({ type: 'INITIAL_STATE', 
        payload: {
          modal: !state.modal,
          modalType: 'create'
        }
      })
      clearState(); } } 
    }) // Show FAB      

    return () => { // Trigger if component will unmount
      context.dispatch({ type: 'SET_STATE', payload: { loc: '', showFab: false } }) // Hide FAB      
    }
  }, [])

  useEffect(() => { 
    console.log(state.projects)

  }, [state.projects])

  const handleActiveChange = (checked) => {
    dispatch({ type: 'INITIAL_STATE', payload: { isAssigned: checked } })
  }

  const handleActiveChange2 = (checked) => {
    dispatch({ type: 'INITIAL_STATE', payload: { hideClosedProjects: checked } })
  }

  async function handlePrioritySave () {
    try {
      const team = items.map((item, index) => {
        const temp_user = item.team.find(team => team.id == user.id)
        return {
          id: temp_user.proj_team_id,
          proj_team_id: item.id, 
          user_id: temp_user.id,
          priority: index,
        }
      })
  
      setLoading(true)
      const { data } = await client.mutate({ mutation: UPDATE_PRIORITY, variables: { data: { team } } });
      setLoading(false)
      setDuo(items.map((e, i) => ({...e, team: e.team.map(team => team.id == user.id? {...team, priority: i}: e )})))
      message.success(context.translation.priority_success[context.locale])
    } catch (error) {
      setLoading(false)
      message.error(context.translation.error_occurred[context.locale])
    }
  }

  function returnPayload(id, status) {
    const project = state.projects.find(project => project.id == id)
    console.log(project)
    return {
      id,
      proj_name: project.proj_name, 
      client_id: project.client_id, 
      proj_nr: project.proj_nr, 
      status
    }
  }


  async function handleSaveArchive() {
    try { 
      let result = []
      result = tempArchives.filter(e => !!!archives.find(s => s == e)).map(e => returnPayload(e, 'Open'))  // Return status of projet to Open
      result = result.concat(archives.filter(e => !!!tempArchives.find(s => s == e)).map(e => returnPayload(e, 'Archived'))) // Archive projects

      setLoading(true)
      console.log(result)
      await client.mutate({ mutation: UPDATE_PROJECT_STATUS, variables: { data: { payload: result } } });
      setLoading(false)
      
      message.success(context.translation.project_status_success[context.locale])
      dispatch({ 
        type: 'INITIAL_STATE', 
        payload: { 
          projects: state.projects.map(e => updateProject(e, result)),
          projectsTable: state.projectsTable.map(e => updateProject(e, result))
        } 
      })
    } catch (error) {
      setLoading(false)
      console.log(error)
      message.error(context.translation.error_occurred[context.locale])
    }
  }

  function updateProject(e, result) {
    const found = result.find(s => s.id == e.id)
    if (found) {
      return { ...e, status: found.status }
    } else {
      return e
    }
  }

  function handleCancel () {
    setItems(tempItems)    
    setVisible(false)
  }

  function handleCancel1 () {
    setArchives(tempArchives)    
    setVisible1(false)
  }

  function setDuo (projects) { 
    setItems(projects)
    setTempItems(projects)
  }

  const handleVisibleChange = visible => {
   setVisible(visible)
  };

  const handleVisibleChange1 = visible => {
   setVisible1(visible)
  };

  /* TABLE SETTINGS */
  const columns = [{
    dataField: 'id',
    text: 'id',
    hidden: true,
  }, {
    dataField: 'proj_nr',
    text: 'ID',
    sort: true,
    headerStyle: (colum, colIndex) => ({ width: '70px' })
  }, {
    dataField: 'proj_name',
    text: translation.project_name[locale],
    sort: true
  }, {
    dataField: 'clientname',
    text: translation.client[locale],
    sort: true
  }, {
    dataField: 'team',
    text: translation.team[locale],
    sort: false,
  }, {
    dataField: 'status',
    text: 'Status',
    sort: false,
    formatter: (cell, row, rowIndex, formatExtraData) => translation.status[cell.toLowerCase().replace(' ', '_')][locale],
    hidden: user.role !== 'Client',
    editor: {
      type: Type.SELECT,
      options: [{
        value: 'Open',
        label: translation.status.open[locale]
      }, {
        value: translation.status.closed[locale],
        label: 'Closed'
      }]
    },
  }, {
    dataField: 'date_created',
    text: translation.date_created[locale],
    sort: true
  }, {
    dataField: 'action',
    text: translation.action[locale],
    align: 'center',
    headerAlign: 'center',
    hidden: user.role === 'Client'
  },];
  /* TABLE SETTINGS END */

  function filterProjects(i) {
    if (user.role !== 'Client') {
      if (state.isAssigned && state.hideClosedProjects) {
        return !!state.projects[i].team.find(team => team.id == user.id) && state.projects[i].status === 'Open'
      } else if (state.isAssigned && !state.hideClosedProjects) {
        return !!state.projects[i].team.find(team => team.id == user.id && state.projects[i].status !== 'Archived')
      } else if (!state.isAssigned && state.hideClosedProjects){
        return state.projects[i].status === 'Open'
      }
    }

    return state.projects[i].status !== 'Archived'
  }

  return (
    <ToolkitProvider
      keyField='id'
      bootstrap4
      columns = { columns }
      data={ state.projectsTable.map(e => ({...e, clientname: !!e.client? e.client.clientname : 'Deleted Client' })).filter((e,i) => filterProjects(i)) } 
      search
    >
      {
        props => (
          <>
            {/* <Tooltip title="Add">
                <IconButton aria-label="Add" onClick={() => {
                     dispatch({ type: 'INITIAL_STATE', 
                     payload: {
                       modal: !state.modal,
                       modalType: 'create'
                     }
                   })
                   clearState(); 
                }}>
                  <AddIcon
                   />
                </IconButton>
            </Tooltip> */}
            { user.role !== 'Client' && 
              (<>
                {/* ===============
                      Add Project
                  =============== */}
                <Fab
                  id="step-7"
                  variant="extended"
                  size="small"
                  color="primary"
                  aria-label="Add"
                  onClick={() => {
                    dispatch({ type: 'INITIAL_STATE', 
                      payload: {
                        modal: !state.modal,
                        modalType: 'create'
                      }
                    })
                    clearState(); 
                  }}  
                >
                  <AddIcon />
                  <span>{translation.add_project[locale]}</span>
                </Fab>
              
                 {/* ===============
                    Project Priorites 
                    =============== */}
                <Popover 
                  title={translation.project_prior[locale]}
                  trigger="click"
                  visible={visible}
                  onVisibleChange={handleVisibleChange}
                  content={(
                    <>
                      <SortableContainer onSortEnd={onSortEnd} helperClass="SortableHelper" useDragHandle>
                        {
                          items.length !== 0 && 
                            items.map((value, index) => (
                              <SortableItem key={value.id} index={index} value={value}/>
                            ))
                        }
                      </SortableContainer>
                      <div style={{ textAlign: 'right' }}>
                        <Buttono type="link" onClick={handleCancel}>{translation.cancel[locale]}</Buttono>
                        <Buttono type="primary" disabled={isOrderChanged} onClick={handlePrioritySave} loading={loading}>{translation.save[locale]}</Buttono>
                      </div>
                    </>
                  )} 
                >
                  <Button type="link">{translation.project_priority[locale]}</Button>
                </Popover>

                {/* ===============
                  Project Archive 
                  =============== */}
                <Popover 
                  placement="bottom"
                  title={translation.archive_projects[locale]}
                  trigger="click"
                  visible={visible1}
                  onVisibleChange={handleVisibleChange1}
                  content={<ProjectArchive projects={state.projects} context={{ translation, locale }} handleSaveArchive={handleSaveArchive} loading={loading} handleCancel={handleCancel1} data={{ archives, setArchives, tempArchives, setTempArchives }} />} 
                >
                  <Button type="link">{translation.archive_projects[locale]}</Button>
                </Popover>


                {/* ===============
                    DELETE BUTTON
                  =============== */}
                <Tooltip title="delete">
                    <IconButton className={`${(state.selected.length !== 0)? 'scale-in-center': 'scale-hide'}`} aria-label="Delete" onClick={() => {
                        dispatch({ type: 'INITIAL_STATE',
                          payload: {
                            modal: !state.modal,
                            modalType: 'delete'
                          }
                        })
                    }}>
                      <DeleteIcon />
                    </IconButton>
                </Tooltip>
              </>)
            }
                
            <div style={{ float: 'right' }}>
            {
              user.role !== 'Client' && (
                <>
                  <Switch   
                    checked={state.isAssigned}
                    onChange={handleActiveChange}
                    checkedChildren={context.translation.assigned_projects[context.locale]} 
                    unCheckedChildren={context.translation.unassigned_projects[context.locale]}
                    style={{ margin: '5px 10px 0 0' }}
                  />

                  <Switch   
                    checked={state.hideClosedProjects}
                    onChange={handleActiveChange2}
                    checkedChildren={context.translation.hide_closed_projects[context.locale]} 
                    unCheckedChildren={context.translation.display_closed_projects[context.locale]}
                    style={{ margin: '5px 10px 0 0' }}
                  />
                </>
              )
            }

            <SearchBar placeholder={translation.search[locale]} { ...props.searchProps } />
            </div>

            <BootstrapTable 
              { ...props.baseProps }
              {...selectedRow}
              noDataIndication={ () => state.loading? <Spinner2 />  : <Empty />}
              bordered={ false }
              hover 
            />
        </>
        )
      }
    </ToolkitProvider> 
  )
}