import React, { useEffect, useContext, useState, useCallback, useRef } from 'react';
import Moment from 'react-moment';
import moment from 'moment';
import { Empty, TimeApprove, Spinner2, PopoverContent } from '../../../common';
import { Icon, Popconfirm, message, Badge, Mentions, Popover, Checkbox, Tag, Form } from 'antd';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import { FormFeedback, Row, Col, FormGroup, Label, Input, InputGroup, InputGroupAddon, InputGroupText, Button as But, Spinner } from 'reactstrap';
import InputMask from 'react-input-mask';
import InputMoment from 'input-moment'
import { FilePond, registerPlugin  } from 'react-filepond';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';

import FilePondPluginFileRename from 'filepond-plugin-file-rename';
import FilePondPluginGetFile from 'filepond-plugin-get-file';
import { GlobalContext } from '../../../../contexts/GlobalContext';
import { AuthContext } from '../../../../contexts/AuthContext';
import Scrollbar from 'react-smooth-scrollbar';
import EditAction from './EditActions'

import Tooltip from '@material-ui/core/Tooltip';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';

import Button from '@material-ui/core/Button';
import DateIcon from '@material-ui/icons/DateRange';

import Carousel, { Modal, ModalGateway } from 'react-images'

import { ApolloContext } from 'react-apollo';
import { useSubscription } from 'react-apollo-hooks';
import { UPSERT_COMMENT, UPDATE_POINT, NEW_COMMENT, DELETE_COMMENT, TIMELOG_HISTORY, LAST_CHANGE_HISTORY } from '../../../../queries';
import axios from 'axios'

registerPlugin(FilePondPluginImagePreview, FilePondPluginFileRename, FilePondPluginGetFile, FilePondPluginFileValidateSize);

const { Option, getMentions } = Mentions;
let removefiles = [];
let gallery = [];
let temp_gallery = [];
let id = -1;
let name = '';
let project_role = '';
let deleteFromClick = false;
let check_time_len = false
let check_hour_len = false
let point_temp = {id: 0, point_nr: 0, feedback_id: 0}
let point_id, statsCount, temp_statsCount
let isEnter = false

export const saveProject = async (e, type, state, dispatch, context) => {
  e.preventDefault();
  
  /* Check if state files has no attachments then close modal */
  try {
    const formData = new FormData(document.forms.namedItem('modal-form'))
    let payload = {};
    formData.forEach((value,key) => payload[key] = value)

    if (!state.isSubmitFeedbackForm) {
      payload.id = (state.editedItem.id > 0)? state.editedItem.id: --id
      payload.team = [...state.editedItem.users]
      payload.version_id = payload.version.split('|')[0]
      payload.version_name = payload.version.split('|')[1]
      payload.time_estimate = payload.time_estimate || 'N/A'
      payload.files = state.files
      payload.dupFiles = state.dupFiles
      payload.comments = state.editedItem.comments
      payload.creator = name
      payload.deadlineMoment = state.editedItem.deadline
      if (state.editedItem.id > 0)
        payload.point_nr = state.editedItem.point_nr
  
      
      if (type === 'update' && state.isNew) {
        const points = [...state.points]
        dispatch({ type: 'SET_STATE', payload: { points: points.map((e, i) => (state.editedItem.rowIndex === i)? ({...payload}): ({...e})) } })
        // Toast.showToast('success', context.translation.msg_point_updated[context.locale])
        message.success(context.translation.msg_point_updated[context.locale]);
        return true;
      } else if (type === 'create' || type === 'update') {
        // [...document.querySelectorAll('.filepond--file-action-button.filepond--action-process-item')].map(e => e.click()) // upload all attachments
        dispatch({ type: 'SET_STATE', payload: { confirmModal: !state.confirmModal, payloadPoints: [{...payload}], removefiles } })
        // return false;
      }
    } else {
      payload.files = state.files
      payload.dupFiles = state.dupFiles

      if (state.modalType === 'create') {
        dispatch({ type: 'SET_STATE', payload: { points: state.points.concat(payload), modal: false } })
      } else {
        dispatch({ type: 'SET_STATE', payload: { points: state.points.map((e, i) => state.editedItem.s_id === i ? payload : e), modal: false } })
      }
    }
  } catch (error) {
    console.log(error)
  }
}

const Ponds = React.memo(({state, dispatch, context, files, removefiles, openLightbox, filepondupload, history, auth}) => {
  let uploads = 0, uploading = false, newAddedFiles = -1, temp_files = [...state.files];

  /* 
  * ===== NOTES =====
  * uploads = Number of files that are added
  * uploading = same value as state.uploading, made a separate variable to get the latest value of variable, states inside filepond sometimes not updated (Ma issue el plugin)
  * newAddedFiles = this is dependent on the programmatical execution of file uploads (used native javascript to trigger hidden buttons)
  * temp_files = I don't know this shyt. 
   */

  console.log('Filepond - re-render')

  /* If uploads value is 0 && uploading state is still true, then set uploading to false */
  useEffect(() => { 
    console.log(uploads)
    if (uploads === 0 && uploading) {
      uploading = false
      dispatch({ type: 'SET_STATE', payload: { uploading: false } })
    }
  }, [uploads])
    
  if (state.newFeedbackFileUpload) {
    [...document.querySelectorAll('#modal-upload .filepond--file-action-button.filepond--action-process-item')].map(e => {
      e.removeAttribute('disabled')
      e.click()
    }) // upload all attachments
  }

  const updateUploadState = (n) => {
    if (!uploading && n !== 0) {
      uploading = true
      dispatch({ type: 'SET_STATE', payload: { uploading: true } })
    } 
  }

  /*
  * Executes when all files are uploaded 
  */
  const checkFiles = async (point, feedback) => {
    newAddedFiles = -1
    let unassignedPoints = {}
    const point_id = point.split('_')[2]
    const point_nr = point.split('_')[1]
    const feedback_id = feedback.split('_')[1]
    const {data} = await axios.get(context.uri + `/files/${point_id}`)
    const pointy = state.isFeedbackList !== 'analysis'? state[state.isFeedbackList == 'feedback'? 'feedbacks': 'points'].filter(e => state.isFeedbackList != 'dashboard'? true : e.team.length == 0 ? true : false ).map(e => ({
      ...e,
      files: (point_id === e.id)? data: e.files
    })) : []

    deleteFromClick = ''
    filepondupload.current.removeFiles()
    
    /* Holds Feedbacks or Points */
    let points = state.isFeedbackList !== 'analysis'? (state.isFeedbackList == 'feedback')? 
      { 
        feedbacks: state.feedbacks.map(e => ({
          ...e,
          versions: e.versions.map(version => ({
            ...version,
            points: version.points.map(point => ({
              ...point,
              files: (point_id == point.id)? data: point.files
            }))
          }))
        })) 
      } : // Feedbacks
      { // Points
        points: pointy
      } : {}
    const files1 = await data.filter(e => e.filename !== removefiles.filename).map(e => ({source: `project_${state.project.id}/feedback_${state.id}/point_${point_nr}_${point_id}/${e.filename}`, options: { type: 'local' }, filename: e.filename}))
    temp_files = [...files1] 

    filepondupload.current.addFiles(files1)


    point_temp = { id: point_id, point_nr: point_nr, feedback_id: state.id }
    uploading = false
    dispatch({ type: 'UPDATE_POINTS', state: {data, point_id}, payload: { editPoint: false, files: [...files1], rowData: { files: [...files1] }, uploading: false, newFeedbackFileUpload: false, uploadNewFiles: true, ...unassignedPoints } })

    message.success(context.translation.msg_point_updated[context.locale]);

    if (state.isNew) {
      history.push(`/feedback/view/${feedback_id}/${state.editedItem.status}`)
    }
  
    setTimeout(() => {

      document.getElementsByClassName('filepond--download-icon').forEach(es => {
        es.addEventListener("click", (e) => {
          e.stopPropagation();
        });
      })
    }, 1000);
  }
  
  //id={`${(state.editedItem.id > 0 && !state.editPoint)? "filepond-upload": ''}`}
  return (
  <FilePond id="modal-upload" ref={filepondupload} className="fileupload" allowallowImagePreview={true} allowMultiple={true} server={
    {
      url: context.uri + `/project_${state.project.id}/feedback_${state.id}`, //state.loadURI
      process: { 
        onload: (response) => {
          --uploads
          return response.key
        },
      },
      revert: () => null,
      load: (source, load, error, progress, abort, headers) => {
        fetch(context.uri + '?load=' + source).then(function(response) {
          if (!response.ok) {
            error('oh my goodness')
          } else {
            response.blob().then(function(myBlob) {
              setTimeout(() => {
                document.getElementsByClassName('filepond--download-icon').forEach(es => {
                  es.addEventListener("click", (e) => {
                    e.stopPropagation();
                  });
                })
              }, 1000);
              load(myBlob)
            }).catch(e => {
              // console.log(e)
            });
          }
        });         
      },
      remove: async (source, load, error) => {
        if (deleteFromClick) {
          await axios.delete(context.uri + `/project_${state.project.id}/feedback_${state.id}`, {data: { files: [{filename: deleteFromClick.filename}], point: {id: state.editedItem.id, point_nr: state.editedItem.point_nr, user_id: !!auth ? auth.user.id : null} }}, { headers: { 'Content-Type': 'application/json;charset=utf-8', accept: 'application/json;charset=utf-8' } }).then(res => {
              dispatch({ type: 'REMOVE_FILE', payload: { filename: deleteFromClick.filename } });    
              deleteFromClick = ''
              // Toast.showToast('success', `${context.translation.msg_file_removed[context.locale]}!`)
              message.success(`${context.translation.msg_file_removed[context.locale]}!`);
            }).catch(err => {
              // Toast.showToast('error', `${context.translation.msg_file_remove_failed[context.locale]}!`)
              message.error(`${context.translation.msg_file_remove_failed[context.locale]}!`);
            })
        }
        // Should somehow send `source` to server so server can remove the file with this source

        // Can call the error method if something is wrong, should exit after
        error('oh my goodness');

        // Should call the load method when done, no parameters required
        load();
      }
    }} 
    files={state.files}
    onupdatefiles={(fileItems) => {
      const files = fileItems.map((fileItem, i) => { 
        fileItem.setMetadata('user_id', !!auth ? auth.user.id : null)
        fileItem.setMetadata('points', `point_${state.editedItem.point_nr || point_temp.point_nr }_${state.editedItem.id || point_temp.id }`)
        fileItem.setMetadata('feedback', `feedback_${state.id || point_temp.feedback_id }`)

        return fileItem.file 
      })

      // console.log(uploads)
      // console.log(files)

      dispatch({ type: 'SET_STATE', payload: { files, dupFiles: fileItems } });          
    }}
    onactivatefile={(file) => {
      dispatch({ type: 'SET_STATE', payload: { gallery: [...state.gallery, ...temp_gallery] } })
      if (temp_gallery.length !== 0)
        gallery = temp_gallery
      // temp_gallery = []
      gallery.forEach((e, index) => {
        if (e.filename === file.file.name)
          openLightbox('asd', { index })
      })
    }}
    onprocessfileabort={(file) => {
      // console.log("YAN CANCEL NYETA")
      if (--uploads === 0 && uploading) {
        checkFiles(file.getMetadata('points'), file.getMetadata('feedback'))
      }
    }}
    beforeRemoveFile={(file) => {
      if (state.editedItem.id > 0) {
        if (window.confirm(context.translation.delete_file[context.locale])) {
          deleteFromClick = file
        } else {
          return false
        }
      } else {
        deleteFromClick = file
      }
    }}
    onremovefile={async (error, file) => {
      if (uploads > 0) --uploads

      if (state.editedItem.id > 0) {
        // Reduce number of newly added files (that are not yet uploaded)
        if (!temp_files.find(f => f.filename == file.filename)) {
          --newAddedFiles
        }
      }
    }}
    onaddfile={(error, file) => {
      if (!error) {
        if (state.editedItem.id > 0) {
          // Manually uploads newly added files 
          if (!temp_files.find(f => f.filename == file.filename)) {
            if (!state.isSubmitFeedbackForm)
              updateUploadState(++uploads)
            ++newAddedFiles
            document.querySelectorAll('#modal-upload .filepond--file-action-button.filepond--action-process-item')[0].removeAttribute('disabled')
            document.querySelectorAll('#modal-upload .filepond--file-action-button.filepond--action-process-item')[0].click()
          }
        } else {
          if (!state.isSubmitFeedbackForm)
            updateUploadState(++uploads)  
        }
      }
    }}
    onprocessfilestart={(file) => {
      // file.setMetadata('points', `point_${state.editedItem.point_nr || point_temp.point_nr }_${state.editedItem.id || point_temp.id }`)
      // file.setMetadata('feedback', `feedback_${state.id || point_temp.feedback_id }`)
      // updateUploadState(uploads)
    }}
    onprocessfile={(error, file) => {
      if (!error) {
        console.log(uploads)
        if (uploads === 0) {
          checkFiles(file.getMetadata('points'), file.getMetadata('feedback'))
        }
      } else {
        uploading = false
        dispatch({ type: 'SET_STATE', payload: { uploading: false } }) 
        uploads = 0
      }
    }}
    // onprocessfiles={async () => {
    //   checkFiles()
    // }}
    fileRenameFunction={file => file.name.split(/\.(?=[^\.]+$)/)[0] + '_' + new Date().getTime() + file.extension}
    // allowDrop={!(state.editedItem.id > 0 && !state.editPoint)} 
    instantUpload={false}
    allowRevert={false}
    labelIdle={context.locale === 'de'? `${context.translation.drag_n_drop[context.locale]}<br />${context.translation.msg_paste_image[context.locale]}` :'Drag & Drop your files or <span class="filepond--label-action"> Browse </span> <br />You can also paste any image here'}
    labelMaxFileSizeExceeded={context.translation.file_large_error[context.locale]}
    labelMaxFileSize={context.translation.max_file_size[context.locale]}
    labelMaxTotalFileSizeExceeded={context.translation.max_total_file_size[context.locale]}
    labelFileProcessingComplete={context.translation.upload_complete[context.locale]}
    labelFileProcessingAborted={context.translation.upload_cancelled[context.locale]}
    labelFileProcessingError={context.translation.error_upload[context.locale]}
    labelFileWaitingForSize={context.translation.wait_size[context.locale]}
    labelFileRemoveError={context.translation.error_remove[context.locale]}
    labelFileProcessing={context.translation.uploading[context.locale]}
    labelFileLoadError={context.translation.error_load[context.locale]}
    labelTapToCancel={context.translation.tap_cancel[context.locale]}
    labelFileLoading={context.translation.loading[context.locale]}
    labelTapToRetry={context.translation.tap_retry[context.locale]}
    labelTapToUndo={context.translation.tap_undo[context.locale]}
    maxFileSize={'30MB'}
    maxTotalFileSize={'50MB'}
    // forceRevert={true}
    />
)}, (prevProps, nextProps) => {
  /*
  * REMOVE UNNECESSARY RERENDERS FOR FILEPOND 
  * FALSE = RERENDER COMPONENT
  * TRUE = USE CACHED MEMORY OF COMPONENT (NO RENDER)
   */

  function isFeedbackList () {
    return (prevProps.state.isFeedbackList == 'feedback') ? 
      (prevProps.state.feedbacks !== nextProps.state.feedbacks) :
      (prevProps.state.points !== nextProps.state.points)
  }

  if ((prevProps.context.locale !== nextProps.context.locale || prevProps.state.modal !== nextProps.state.modal ||  prevProps.state.id !== nextProps.state.id || prevProps.state.editedItem.id !== nextProps.state.editedItem.id || isFeedbackList())) { // RENDER ON CHANGE IN LANGUAGE OR REOPENED MODAL
      return false
  }

  return true
})

const badgeColorStatus = [
  { name: 'Open', border: '1px solid #CF0F22', backgroundColor: '#FAE6E8', color: '#CF0F22', fontWeight: '400', borderRadius: '2px' },
  { name: 'Question', border: '1px solid #219FC7', backgroundColor: '#E8F5F9', color: '#219FC7', fontWeight: '400', borderRadius: '2px' },
  { name: 'For Testing', border: '1px solid #5B3694', backgroundColor: '#EEEAF4', color: '#5B3694', fontWeight: '400', borderRadius: '2px' },
  { name: 'Postpone', border: '1px solid #E43988', backgroundColor: '#FDEBF3', color: '#E43988', fontWeight: '400', borderRadius: '2px' },
  { name: 'Closed', border: '1px solid #53AD57', backgroundColor: '#EDF7EE', color: '#53AD57', fontWeight: '400', borderRadius: '2px' },
]

const badgeColorPriority = [
  { name: 'High', border: '1px solid #CF0F22', backgroundColor: '#FAE6E8', color: '#CF0F22', fontWeight: '400', borderRadius: '2px' },
  { name: 'Mid', border: '1px solid #FF8B00', backgroundColor: '#FFF3E5', color: '#FF8B00', fontWeight: '400', borderRadius: '2px' },
  { name: 'Low', border: '1px solid #FFC400', backgroundColor: '#FFF9E5', color: '#FFC400', fontWeight: '400', borderRadius: '2px' },
]

const CheckboxGroup = Checkbox.Group;

const AssignUserContent = ({ reducer: { state, dispatch, saveField, visiblePopover }, context }) => {
  let initialState = {
    checkedList: state.editedItem.users.map(e => e.id),
    plainOptions: state.members.map(e => ({ label: e.name, value: e.id })),
    indeterminate: true,
    checkAll: false,
  }

  const [states, setState] = useState(initialState)

  useEffect(() => {
    if (visiblePopover) 
      setState(initialState)
  }, [visiblePopover])

  useEffect(() => {
    console.log(state.editedItem.users)
  }, [state.editedItem.users])

  function handleClose (id) {
    const users = state.editedItem.users.filter((e, i) => i != id);
    console.log(users);
    dispatch({ type: 'SET_STATE', payload: { editedItem: { ...state.editedItem, users } } })
    onChange(users.map(e => e.id))
  };

  function onChange (checkedList) {
    saveChanges(checkedList)

    setState({
      ...states,
      checkedList,
      indeterminate: !!states.checkedList.length && states.checkedList.length < states.plainOptions.length,
      checkAll: checkedList.length === states.plainOptions.length
    });
  };

  function onCheckAllChange (e) {
    saveChanges(e.target.checked ? state.members.map(e => e.id) : []) 

    setState({
      ...states,
      checkedList: e.target.checked ? states.plainOptions : [],
      indeterminate: false,
      checkAll: e.target.checked
    });
  };

  function saveChanges(users) {
    users = state.members.filter(e => users.find(s => s == e.id)).map(s => ({...s, is_working: 'active'}))
    dispatch({ type: 'SET_STATE', payload: { editedItem: { ...state.editedItem, users } } })
    saveField({ team: users }, {rowData: {editedItem: {...state.rowData.editedItem, users} }}, 'team', 'editAssignedUsers')
  }

  return (
    <div style={{ width: '300px' }}>
      {
        state.editedItem.users.length !== 0 && state.editedItem.users.map((e, i) => (
          <Tag key={e.id} closable={true} onClose={() => handleClose(i)}>
            {e.name}
          </Tag>
        )) 
      }

      <div style={{ borderBottom: '1px solid #E9E9E9', margin: '12px 0 4px 0' }}>
        <Checkbox
          indeterminate={states.indeterminate}
          onChange={onCheckAllChange}
          checked={states.checkAll}
        >
          { context.translation.check_all[context.locale] }  
        </Checkbox>
      </div>

      <CheckboxGroup
        options={states.plainOptions}
        value={states.checkedList}
        onChange={onChange}
        style={{ maxheight: '230px', overflow: 'auto' }}
      />
    </div>    
  )
}

const Content = ({ logHistory, historyLoading, context }) => {
  return (
    <div id="point-card" style={{ padding: 0 }}>
      {
        !historyLoading ?
          logHistory.length !== 0 ? 
            logHistory.map((e, i) => (
              <div key={i}>
                {
                  i === 0 ?
                    <span style={{ display: 'block', color: '#e0e0e0', margin: '10px 0 5px 0' }}>{ moment(!!e.hours_worked ? e.date_received : new Date(parseInt(e.date_received))).format('ll') }</span>
                    : (!moment(!!e.hours_worked ? logHistory[i-1].date_received : new Date(parseInt(logHistory[i-1].date_received)), 'YYYY-MM-DD').isSame(moment(!!e.hours_worked ? e.date_received : new Date(parseInt(e.date_received)), 'YYYY-MM-DD')) &&
                        moment(!!e.hours_worked ? logHistory[i-1].date_received : new Date(parseInt(logHistory[i-1].date_received)), 'YYYY-MM-DD').format('YYYY-MM-DD') != moment(!!e.hours_worked ? e.date_received : new Date(parseInt(e.date_received)), 'YYYY-MM-DD').format('YYYY-MM-DD')) ?
                      <span style={{ display: 'block', color: '#e0e0e0', margin: '10px 0 5px 0' }}>{ moment(!!e.hours_worked ? e.date_received : new Date(parseInt(e.date_received))).format('ll') }</span> 
                      : ''
                }
                
                {
                  !!e.hours_worked ? 
                    <span style={{ display: 'block' }}>{ e.hours_worked } - { e.name }</span> 
                    :
                      <span style={{ display: 'block', marginBottom: '4px' }}>
                        <small style={{ display: 'block' }}><b>{ e.name }</b></small>
                        { context.translation.type_of_change[(e.description).split('*')[0]][context.locale] }
                      </span>
                }
              </div>
            )):
            <Empty />
          :
            <Spinner2 />
      }
    </div>
  )
}

export default Form.create()(({ state, dispatch, history, form }) => {
  const { client } = useContext(ApolloContext);
  const scrollbar = useRef(null)
  const filepondupload = useRef(null)
  const [isSelfAssigned, setSelfAssigned] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const [comment, setComment] = useState('')
  const [mentions, setMentions] = useState('')
  const context = useContext(GlobalContext)
  const auth = useContext(AuthContext)
  const [tooltipOpen, toggle] = useState([])
  const [visible, setVisible] = useState(false)
  const [visible1, setVisible1] = useState(false)
  const [popHoursWorked, setPopHoursWorked] = useState(false)
  const [visiblePopover, setVisiblePopover] = useState(false)
  const [historyLoading, setHistoryLoading] = useState(false)
  const [changeLoading, setChangeLoading] = useState(false)
  const [tpOpen, setTpOpen] = useState([false, false])
  const [momentTime, setMomentTime] = useState({ start_time: '', end_time: '' })
  const [logHistory, setLogHistory] = useState([])
  const [changeHistory, setChangeHistory] = useState([])
  const [query, setQuery] = useState(false)
  const [query1, setQuery1] = useState(false)
  const [activeTab, setTab] = useState(1)
  const status = {
    Open: -1,
    Question: -1,
    For_Testing: -1,
    Draft: -1,
    Postpone: -1,
    Closed: -1,
  }
  const statusFP = ['For_Testing', 'Draft']
  let sameUser = false;
  let marginTop = '7px';
  let display = 'initial'
  const { data, error, loading } = useSubscription(NEW_COMMENT);

  useEffect(() => {
    setTab(state.project_role === 'Feedback Provider' ? 1 : 2)
  }, [])

  useEffect(() => {
    console.log(data)
    if (data) 
      if (data.newComment.point_id == state.editedItem.id && data.newComment.user_id != auth.user.id)
        addComment(data.newComment)
  }, [data])

  useEffect(() => {
    setMentions(getMentions(comment))
  }, [comment])

  useEffect(() => {
    if (visible && !query && !historyLoading) {
      setHistoryLoading(visible)
      client.query({ query: TIMELOG_HISTORY(state.editedItem.id) }).then(async ({ data }) => {
        setLogHistory(data.logHistory || [])
        setQuery(true)
      }).catch(err => {
        console.log(err)
      }).finally(() => {
        setHistoryLoading(false)
      })
    }
  }, [visible])

  useEffect(() => {
    if (visible1 && !query1 && !changeLoading) {
      setHistoryLoading(visible1)
      client.query({ query: LAST_CHANGE_HISTORY(state.editedItem.id) }).then(async ({ data }) => {
        setChangeHistory(data.changeHistory || [])
        setQuery1(true)
      }).catch(err => {
        console.log(err)
      }).finally(() => {
        setHistoryLoading(false)
      })
    }
  }, [visible1])

  useEffect(() => {
    (async () => {
      if (context.files.length !== 0 && context.uploadFiles && state.points.length !== 0 && context.uploadDone && state.editedItem.id > 0) {
        let isAllUploaded = false;
  
        /* 
        * ====== FILE STATUS ENUM ======
        * INIT: 1,
        * IDLE: 2,
        * PROCESSING_QUEUED: 9,
        * PROCESSING: 3,
        * PROCESSING_COMPLETE: 5,
        * PROCESSING_ERROR: 6,
        * PROCESSING_REVERT_ERROR: 10,
        * LOADING: 7,
        * LOAD_ERROR: 8  
         */
  
        context.files.forEach(file => {
          if (file.status === 5) {
            isAllUploaded = true
          }
        })
  
        if (isAllUploaded) {
          const {data} = await axios.get(context.uri + `/files/${state.editedItem.id}`)
          const pointy = state.isFeedbackList !== 'analysis'? state[state.isFeedbackList == 'feedback'? 'feedbacks': 'points'].filter(e => state.isFeedbackList != 'dashboard'? true : e.team.length == 0 ? true : false ).map(e => ({
            ...e,
            files: (state.editedItem.id === e.id)? data: e.files
          })) : []
          let points = state.isFeedbackList !== 'analysis'? (state.isFeedbackList == 'feedback')? 
            { 
              feedbacks: state.feedbacks.map(e => ({
                ...e,
                versions: e.versions.map(version => ({
                  ...version,
                  points: version.points.map(point => ({
                    ...point,
                    files: (state.editedItem.id == point.id)? data: point.files
                  }))
                }))
              })) 
            } : // Feedbacks
            { // Points
              points: pointy
            } : {}
            
          dispatch({ type: 'SET_STATE', payload: { ...points, files: data.map(e => ({source: `project_${state.project.id}/feedback_${state.id}/point_${state.editedItem.point_nr}_${state.editedItem.id}/${e.filename}`, options: { type: 'local' }, filename: e.filename})) } }) 
        }
      }
    })()
  }, [context.uploadDone])

  useEffect(() => {
    if (!state.isSubmitFeedbackForm) {
      let isAssigned = '';
  
      name = auth.user.first_name + ' ' + auth.user.last_name
      gallery = [...state.gallery]
      state.members.forEach((e, i) => { if (e.id === auth.user.id) project_role = e.project_role })
      project_role = project_role !== ''? project_role : 'None'
      // console.log(state.id)
  
      function handleKeyDown(e) { 
        if(e.key === 'Enter' && !e.shiftKey) { 
          document.getElementById('send-comment').click()
        } 
    
        // autosize()
      }
      
      // only execute this code if user is not a feedback provider or a feedback provider is on a clients comment tab. 
      if (state.project_role !== 'Feedback Provider' || (state.project_role === 'Feedback Provider' && activeTab == 1))
        document.getElementById('comment-input').addEventListener('keydown', handleKeyDown);
      // return () => document.getElementById('comment-input').removeEventListener('keydown', handleKeyDown);
    }
  }, [activeTab])

  useEffect(() => {
    if (!state.isSubmitFeedbackForm) {
      if (!!document.querySelector('.m-input-moment .im-btn.btn-save')) {
        document.querySelector('.m-input-moment .im-btn.btn-save').classList.remove('ion-checkmark')
        document.querySelector('.m-input-moment .im-btn.btn-save').innerText = 'Close'
        // document.querySelector('.m-input-moment').classList.add('slide-hide')
      }
    }
  }, [state.editDeadline])

  // useEffect(() => {
    // let fileupload = document.querySelector('.fileupload .filepond--drop-label')
    // let fileremove = [...document.querySelectorAll('.fileupload .filepond--file-action-button.filepond--action-remove-item')]
    // removefiles = []

    // if (!state.editPoint) 
    //   dispatch({ type: 'SET_STATE', payload: { uploadNewFiles: false } })

    // console.log(state.files.length !== 0)
    // if (state.files.length !== 0) {
    //   if (!!fileupload && !!fileremove) {
    //     if (!state.editPoint) {
    //       document.querySelector('.fileupload').id = 'filepond-upload'
    //       fileupload.style = 'display: none'
    //       fileremove.forEach(e => e.style = 'display: none')
    //     }
    //     else {
    //       document.querySelector('.fileupload').id = 'filepond-uploads'
    //       fileupload.style = 'display: flex'
    //       fileremove.forEach(e => e.style = 'display: flex')
    //     }
    //   }
    // }
  // }, [state.editPoint])

  useEffect(() => {
    console.log(state.editedItem.comments)
    scrollToLatestComment()
  }, [state.editedItem.comments])

  useEffect(() => {
    if (state.files.length !== 0 && state.modalType === 'update') {
      let isAllUploaded = true;
      state.files.forEach(file => {
        if (!file.status)
          isAllUploaded = false
      })

      if (isAllUploaded){
        // console.log('tsongg')
        dispatch({ type: 'SET_STATE', payload: { modal: !state.modal } })
      }
    }

    if (state.files.length !== 0) {
      temp_gallery = []
      state.dupFiles.forEach(async file => {
        if (file.file instanceof Blob) {
          const split_file = file.filename.split(/\.(?=[^\.]+$)/)
          let file_extension = split_file[split_file.length - 1]
          if(file_extension.toLowerCase() === 'png' || file_extension.toLowerCase() === 'jpg' | file_extension.toLowerCase() === 'jpeg' || file_extension.toLowerCase() === 'gif') {
            const _reader = new FileReader();
            _reader.onload = async function(event) {
              temp_gallery.push({ filename: file.file.name, src: event.target.result, width: 1, height: 1, title: '', caption: ''})
            }
            await _reader.readAsDataURL(file.file)
          }
        }
      })
    }
  }, [state.files])

  useEffect(() => {
    if (!state.isSubmitFeedbackForm) {
      if (state.editedItem.id < 0 || state.editAssignedUsers || state.editedItem.id == null) {
        const res = state.members.filter(e => { 
          for (let i in state.editedItem.users) {
            if (state.editedItem.users[i].id == e.id)
            return false
          }
          return true
        })
        
        const user = document.getElementById('users').options[document.getElementById('users').selectedIndex]
        if (res.length !== 0){
          const _user = {...state.editedItem, user: { id: user.dataset.id, name: user.value, project_role: user.dataset.role } }
          dispatch({ type: 'SET_STATE', payload: { editedItem: _user, rowData: {editedItem: _user, files: []} } })
        }
        
      }
      setSelfAssigned(!!!state.editedItem.users.find(e => e.id == auth.user.id))
    }
  }, [state.editedItem.users, state.members, state.editAssignedUsers])

  // useEffect(() => {©
  //   if (state.confirmModal) 
  //     console.log('asdqweqweqweqweqwe')
  //   if (state.confirmModal) 
  //     dispatch({ type: 'SET_STATE', payload: { files: filepondupload.current.getFiles().map(e => e.file) } })
  // }, [state.confirmModal])

  function scrollToLatestComment () {
    if (!state.isSubmitFeedbackForm) {
      setTimeout(() => {
        scrollbar.current.scrollbar.update()
        scrollbar.current.scrollbar.setPosition(0,scrollbar.current.scrollbar.limit.y)
      }, 10);
    }
  }

  function toggles(id) {
    setTab(id)
  }

  function autosize() {  
    var el = document.getElementById('comment-input');
    setTimeout(function(){
      el.style.cssText = 'height:auto; padding:0';
      const height = el.scrollHeight == 30 ? 31: el.scrollHeight
      // for box-sizing other than "content-box" use:
      // el.style.cssText = '-moz-box-sizing:content-box';
      el.style.cssText = 'height:' + height + 'px';
    }, 0);
  }

  const showDate = () => {
    const moment = document.querySelector('.m-input-moment')
    const arr = [...moment.classList]
    if (arr.indexOf('slide-in') !== -1) {
      document.querySelector('.m-input-moment').classList.remove('slide-in')
      document.querySelector('.m-input-moment').classList.add('slide-hide')
    } else {
      document.querySelector('.m-input-moment').classList.add('slide-in')
      document.querySelector('.m-input-moment').classList.remove('slide-hide')
    }
  }

  const addUser = () => {
    dispatch({ type: 'SET_STATE', 
      payload: {
        editedItem: {
          ...state.editedItem,
          users: [...state.editedItem.users, { id: state.editedItem.user.id, name: state.editedItem.user.name, project_role: state.editedItem.user.project_role }]
        }
      }
    })
  }

  const handleChange = e => {
    dispatch({ type: 'SET_STATE', 
      payload: { 
        editedItem: {
          ...state.editedItem,
          user: { 
            id: e.target.options[e.target.selectedIndex].dataset.id, 
            name: e.target.value,
            project_role: e.target.options[e.target.selectedIndex].dataset.role
          } 
        }
      } 
    })
  }

  const removeUser = user => () => {
    dispatch({ type: 'SET_STATE', 
      payload: {
        editedItem: {
          ...state.editedItem,
          users: state.editedItem.users.filter(x => parseInt(user.id) !== parseInt(x.id))
        }
      }
    })
  }

  const openLightbox = (event, { index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };
 
  const closeLightbox = () => {
    setViewerIsOpen(false);
    // setCurrentImage(0);
  };

  const validateForm = (field) => !state.editPoint && !field && (auth.user.first_name + ' ' + auth.user.last_name === state.editedItem.creator || state.project_role === 'Project Leader')

  const deleteComment = (id) => async (e) => {
    try {
      const { data } = await client.mutate({ mutation: DELETE_COMMENT, variables: { ids: [id] } })

      const commentsy = await state.editedItem.id > 0? 
          {
            [state.isFeedbackList == 'feedback'? 'feedbacks': 'points']: state.isFeedbackList == 'feedback'? 
            state.feedbacks.map(e => ({
              ...e,
              versions: e.versions.map(version => ({
                ...version,
                points: version.points.map(point => ({
                  ...point, 
                  comments: point.id === state.editedItem.id? [...point.comments, {...comment}] : [...point.comments]
                }))
              }))
            })) 
            :state.points.map(e => ({
              ...e,
              comments: state.editedItem.comments.filter(e => e.id !== id)
            }))
          } : {}
  
      dispatch({ type: 'SET_STATE', payload: { 
        editedItem: {
          ...state.editedItem,
          comments: state.editedItem.comments.filter(e => e.id !== id),
          comment: ''
        },
        ...commentsy
      }})    
      message.success(context.translation.comment_deleted[context.locale]);
    } catch (err) {
      console.log(err)
      message.error(context.translation.error_occurred[context.locale])
    }
  }

  const addComment = async (subscribe) => {
    try {

      if ((!!document.getElementById('comment-input').value || subscribe) && !state.sendingComment) {
        dispatch({ type: 'SET_STATE', payload: { sendingComment: true } })
        let comment = { 
          user_id: parseInt(auth.user.id), 
          type: activeTab == 1? 'client': 'developer',
          comments: document.getElementById('comment-input').value 
        }
  
        if (state.editedItem.id > 0 && !!!subscribe) {
          const mention_payload = mentions.map(e => {
            const isUserExist = state.members.find(user => user.name.replace(' ','') === e.value)
            return isUserExist ? { user_id: isUserExist.id }: null
          }).filter(mention => mention !== null)
          const { data } = await client.mutate({ mutation: UPSERT_COMMENT, variables: { data: {...comment, point_id: state.editedItem.id, point_nr: state.editedItem.point_nr, feedback_id: state.editedItem.feedback_id, proj_id: state.project.id, mentions: mention_payload } } });
          comment = data.upsertComment
        }
  
        if (subscribe) 
          comment = subscribe
  
        const commentsy = await state.editedItem.id > 0? 
          {
            [state.isFeedbackList == 'feedback'? 'feedbacks': 'points']: state.isFeedbackList == 'feedback'? 
            state.feedbacks.map(e => ({
              ...e,
              versions: e.versions.map(version => ({
                ...version,
                points: version.points.map(point => ({
                  ...point, 
                  comments: point.id === state.editedItem.id? [...point.comments, {...comment}] : [...point.comments]
                }))
              }))
            })) 
            :state.points.map(e => ({
              ...e,
              comments:  e.id === state.editedItem.id? [...e.comments, {...comment}] : [...e.comments]
            }))
          } : {}
  
        document.getElementById('comment-input').value = ''
        setComment('')
        autosize()
  
        dispatch({ type: 'SET_STATE', payload: { 
          sendingComment: false,
          editedItem: {
            ...state.editedItem,
            comments: [
              ...state.editedItem.comments, 
              {...comment}
            ],
            comment: ''
          },
          ...commentsy
        }})      
      }
    } catch (error) {
      console.log(error)
      dispatch({ type: 'SET_STATE', payload: { sendingComment: false } })
    }
  }

  const saveField = async (payload, rowData, table, column) => {
    let hide

    if (column !== 'editAssignedUsers')
      hide = message.loading(context.translation.saving_changes[context.locale], 0)
    try {
      let version = {}
      let statusCount = {};
      // Save changes to point
      const { data } = await client.mutate({ mutation: UPDATE_POINT, variables: { data: { id: state.editedItem.id, ...payload }, type: {table, user_id: state.editedItem.creator === 'Deleted User' ? null :state.editedItem.creator_id, user_type: (!['For Testing', 'Question'].includes(state.rowData.editedItem.status))? 'User': 'Tester', project_role: state.project_role} } });
      
      // Update point (table)
      const point = await state.editedItem.id > 0? 
          {
            [state.isFeedbackList == 'feedback'? 'feedbacks': state.isFeedbacklist != 'analysis'? 'points': 'assignedTasks']: state.isFeedbacklist != 'analysis'? state.isFeedbackList == 'feedback'? 
            // If saved field is on feedback list page 
            await state.feedbacks.map(e => ({
              ...e,
              versions: e.versions.concat((() => {  
                const [version_id, version_name] = data.updatePoint.version.split('|')
                let add_version = []

                // Checkes if udpated point (version) doesn't exist, then add version and point to the array
                if (!e.versions.find(version => version.id == version_id) && e.id == data.updatePoint.feedback_id)
                  add_version = {id: parseInt(version_id), version_name, points: [data.updatePoint]}

                return add_version
              })()).map(version => ({
                ...version,
                points: state.status.toLowerCase() === 'all' || state.status.toLowerCase() == data.updatePoint.status.toLowerCase()? 
                version.points.concat((() => {
                  let transfer_point = []

                  // Checks if updated point doesn't exist on the current version's point (on map function pointing to), then add updated point to the array
                  if (!version.points.find(point => point.id == data.updatePoint.id) && version.id == data.updatePoint.version.split('|')[0] && e.id == data.updatePoint.feedback_id)
                    transfer_point = data.updatePoint

                  return transfer_point
                })()).filter(point => point.id == data.updatePoint.id ? version.id == data.updatePoint.version.split('|')[0] : true).map(point => (point.id == data.updatePoint.id)? { ...point, ...data.updatePoint }: { ...point }):
                version.points.filter(point => point.id != data.updatePoint.id) // Filter points if updated point doen't exist on current version's point (on map function pointing to) if status of updated point is changed
              }))
              // .filter(e => e.points.length !== 0) // Filters the version array if it's points is empty
            }))
            // If saved field is on dashboard workplace
            :await state.points
              .map(e => (e.id == data.updatePoint.id)? { ...e, ...data.updatePoint }: { ...e })
              .filter(e => (
                  state.isFeedbackList == 1? e.team.find(es => es.id == auth.user.id) && !['Closed', 'Draft', 'Postpone'].includes(e.status) // If user is on dashboard (assigned points), filter points that is not assigned to logged in user
                    : state.isFeedbackList == 'dashboard'? e.team.length == 0: // If user is on dashboard (unassigned points), filter points that already has assigned points
                    true // Else no filters on other tables on dashboard if ther are any changes
                )
              )
              // If saved field is on dashboard analysis
            :await state.assignedTasks.map(e => {
              if (column !== 'editAssignedUsers') {
                return e 
              } else {
                let points = [...e.points], proj = [...e.project]

                // Checks points on every developer if user is still assigned to a point after update
                e.project.forEach(project => {
                  if (data.updatePoint.proj_id == project.id) { 
                    if (!!!project.points.find(point => data.updatePoint.id == point.id) && !!data.updatePoint.team.find(team => team.id == e.id)) { // If point doesn't exist, increment open/question point
                      points = updatePoint(points, data.updatePoint, 'increment')
                      proj = updateProject(proj, data.updatePoint, 'add')
                    } else if (!!project.points.find(point => data.updatePoint.id == point.id) && !!!data.updatePoint.team.find(team => team.id == e.id)) { 
                      points = updatePoint(points, data.updatePoint, 'decrement')
                      proj = updateProject(proj, data.updatePoint, 'delete')
                    }
                  }
                })

                return { ...e, points, project: proj } 
              }
            })
          } : {}
      // Toast.showToast('success', context.translation.msg_point_updated[context.locale]) 
      if (column !== 'editAssignedUsers') {
        hide()   
        message.success(context.translation.msg_point_updated[context.locale]);
      }

      if (column === 'editVersion')
        version = { version_name: state.editedItem.version.split('|')[1] }

      if (state.isFeedbackList == 'feedback' && column === 'editStatus' && state.statusCount) {
        let type = {}

        if (point_id != data.updatePoint.id) {
          point_id = data.updatePoint.id
          statsCount = Object.assign([], state.statusCount)
          temp_statsCount = Object.assign([], state.statusCount)
          type = 'minus'
        } else {
          if (state.status.toLowerCase() == data.updatePoint.status.toLowerCase())
            type = 'none'
          else 
            type = 'minus'
        }

        statusCount = {
          statusCount: statsCount.map(e => {
            if (e.status.toLowerCase().replace('_', ' ') == state.status.toLowerCase())
              return { ...e, ...updateStatusCount(type, e.no_status) }
            else if (e.status.toLowerCase().replace('_', ' ') == data.updatePoint.status.toLowerCase())
              return { ...e, ...updateStatusCount('add', e.no_status) }
            else 
              return e
          })
        }

        function updateStatusCount(type, count) {
          return type !== 'none' ? { no_status: type === 'add' ? parseInt(count) + 1 : parseInt(count) - 1} : {}
        }
      }

      dispatch({ type: 'SET_STATE', payload: {...point, ...rowData, [column]: false, ...version, ...statusCount, editedItem: {...state.editedItem, ...payload, users: data.updatePoint.team} } })
    } catch (error) {
      dispatch({ type: 'SET_STATE', payload: { [column]: false } })
      console.log(error)
      if (column !== 'editAssignedUsers')
        hide()
      message.error(context.translation.error_occurred[context.locale]);
    }
  } 

  function updatePoint(points, updatePoint, option) {
    return points.map(s => {
      if (s.proj_id !== updatePoint.proj_id) { // If project id of point doesn't match, return same data
        return s
      } else { // If project id matches, increment point 
        const status = updatePoint.status === 'Open'? 'open_status': 'question_status'
        const updateProperty = { [status]: option === 'increment' ? parseInt(s[status]) + 1 : parseInt(s[status]) - 1} 
        
        const total_time_estimate = updatePoint.estimate !== 'N/A' ? option === 'increment' ? 
            moment(s.total_time_estimate, 'HH:mm')
              .add(moment(updatePoint.time_estimate, 'HH:mm').format('HH'), 'hours')
                .add(moment(updatePoint.time_estimate, 'HH:mm').format('mm'), 'minutes').format('HH:mm') :
            moment(s.total_time_estimate, 'HH:mm')
              .subtract(moment(updatePoint.time_estimate, 'HH:mm').format('HH'), 'hours')
                .subtract(moment(updatePoint.time_estimate, 'HH:mm').format('mm'), 'minutes').format('HH:mm') : s.total_time_estimate

        return { 
          ...s, 
          total_time_estimate,
          ...updateProperty 
        }
      }
    })
  }

  function updateProject(projects, updatePoint, option) {
    return projects.map(project => {
      if (project.id != updatePoint.proj_id) { // If project id of point doesn't match, return same data
        return project
      } else { // If project id matches, increment point 
        return { 
          ...project, 
          points: option === 'add'? [...project.points, {id: updatePoint.id}]: project.points.filter(point => point.id != updatePoint.id),
        }
      }
    })
  }

  function assignOrUnassign(isAssign) {
    return isAssign? saveField({ team: [...state.editedItem.users, { id: auth.user.id, name: auth.user.first_name + ' ' + auth.user.last_name, project_role: state.project_role }] }, {rowData: {editedItem: {...state.rowData.editedItem, users: [...state.rowData.editedItem.users, { id: auth.user.id, name: auth.user.first_name + ' ' + auth.user.last_name, project_role: state.project_role }]} }}, 'team', 'editAssignedUsers')
            : saveField({ team: state.rowData.editedItem.users.filter(e => e.id !== auth.user.id) }, {rowData: {editedItem: {...state.rowData.editedItem, users: state.rowData.editedItem.users.filter(e => e.id !== auth.user.id)} }}, 'team', 'editAssignedUsers')
  }

  function handleOnKeyPress (e) {
    if (!(e.charCode >= 48 && e.charCode <= 57)) {
      return false;
    } else {
      if (!(state.editedItem.time_estimate.trim().length === 0 || state.editedItem.time_estimate.trim() === ':'? false : state.editedItem.time_estimate.trim().length !== 5) && e.key === 'Enter' && state.editTimeEstimate) 
        saveField({ time_estimate: state.editedItem.time_estimate === '  :  '? '': state.editedItem.time_estimate }, {rowData: {editedItem: {...state.rowData.editedItem, time_estimate: state.editedItem.time_estimate === '  :  '? '': state.editedItem.time_estimate, time_approve: 0} }, editedItem: {...state.editedItem, time_approve: 0}}, 'point', 'editTimeEstimate')
    }
  }

  function handleOnChange (e) {
    // check_time_len = document.getElementById('time_estimate').value.trim().length === 0 || document.getElementById('time_estimate').value.trim() === ':'? false : document.getElementById('time_estimate').value.trim().length !== 5 ? true : (document.getElementById('time_estimate').value.trim().replace(':', '').split('').every(num => num !== ' ') ? parseInt(document.getElementById('time_estimate').value.replace(':', '')) > 800 : true); 
    check_time_len = document.getElementById('time_estimate').value.trim().length === 0 || document.getElementById('time_estimate').value.trim() === ':'? false : document.getElementById('time_estimate').value.trim().length !== 5 ? true : (document.getElementById('time_estimate').value.trim().replace(':', '').split('').every(num => num !== ' ') ? false : true); 
    dispatch({ type: 'SET_STATE', payload: { editedItem: {...state.editedItem, time_estimate: e.currentTarget.value} } })
  }
  
  return (
    <>
      <Row>
        {
          /* IF OPENED MODAL IS NOT FROM SUBMIT FEEDBACK FROM FOR CLIENTS, DISPLAY ALL FORM */
          !state.isSubmitFeedbackForm ? (
            <>
              <Col xs={7} style={{ borderRight: '1px solid #ececec' }}>
                <Row>
                  <Col xs={12}>
                    <span style={{ fontWeight: 600 }}>{context.translation.feedback_title[context.locale]}:</span> {' '}
                    {
                      !state.feedbackForm ?
                        <span id="hoverable-title" onClick={() => {
                          dispatch({ type: 'SET_STATE', payload: { modal: false } });
                          history.push(`/feedback/view/${state.id}/${state.rowData.editedItem.status}`)}
                        }>
                          {state.title}
                        </span> : state.title
                    }
                  </Col>
                  
                  <Col xs={6} style={{ marginTop: (!state.editVersion)? '5px': '13px' }}>
                    <span style={{ fontWeight: 600 }}>{context.translation.clien[context.locale]}:</span> {state.editedItem.clientname}
                  </Col>

                  <Col xs={6} style={{ marginTop: (!state.editVersion)? '5px': '13px' }}>
                    <span style={{ fontWeight: 600 }}>{context.translation.date_created[context.locale]}:</span> <Moment interval={60000} locale={context.locale} fromNow>{ state.editedItem.date_created }</Moment>
                  </Col>

                  <Col xs={6} style={{ marginTop: (!state.editVersion)? '5px': '13px' }}>
                    <span style={{ fontWeight: 600 }}>{context.translation.nav.project[context.locale]}:</span> {state.project.name}
                  </Col>

                  <Col xs={6} style={{ marginTop: '5px', display: 'none'}}>
                    <FormGroup id="version-step">
                      <span style={{ fontWeight: 600 }}>Version: </span>{' '}
                      {
                        state.editedItem.id > 0 && !state.editVersion ?
                          (<>
                            <span>{state.editedItem.version_name}</span>
                            {
                              !!!state.editedItem.loggedUserNotOnTeam && 
                                <Tooltip title="Edit">
                                  <IconButton style={{ display: (validateForm(state.editVersion))? 'initial': 'none', marginTop: '-2px', marginLeft: 0 }}  aria-label="Edit"  onClick={() => dispatch({ type: 'SET_STATE', payload: { editVersion: true } })}>
                                    <EditIcon style={{ fontSize: '0.8rem', color: '#2745EC' }}/>
                                  </IconButton>
                                </Tooltip>
                            }
                          </>) :
                            (<>
                              <Input defaultValue={state.editedItem.version} onChange={e => dispatch({ type: 'SET_STATE', payload: { editedItem: {...state.editedItem, version: e.target.value, version_name: e.target.value.split('|')[1] } } })} type="select" name="version" id="version" style={{ width: (state.editVersion)? '50%': '78.8%', display: 'inline-block' }}>
                                  { 
                                    state.versions.length !== 0 &&
                                    state.versions.map((e, i) => (
                                      <option key={i} value={e.id + "|" + e.version_name}>{e.version_name}</option>
                                    ))
                                  }
                              </Input>

                              <EditAction show={state.editVersion} save={() => saveField({ version_id: state.editedItem.version.split('|')[0] }, {rowData: {editedItem: {...state.rowData.editedItem, version: state.editedItem.version} }}, 'point', 'editVersion')} cancel={() => dispatch({ type: 'SET_STATE', payload: { editVersion: false, editedItem: {...state.editedItem, version: state.rowData.editedItem.version, version_name: state.rowData.editedItem.version_name} } })}/>
                            </>)
                      }
                    </FormGroup>
                  </Col>
                  <Col xs={6} style={{ marginTop: '5px', marginBottom: '5px' }}>
                    <span style={{ fontWeight: 600 }}>{context.translation.nav.project[context.locale]} {context.translation.role[context.locale]}:</span> {' '}
                    {
                      !!project_role && 
                        context.translation[project_role.toLowerCase().replace(' ', '_')][context.locale]
                    }
                  </Col>

                  <Col xs={6} style={{ marginTop: '5px' }}>
                    <span style={{ fontWeight: 600 }}>{context.translation.created_by[context.locale]}: </span> 
                    {
                      (state.editedItem.id > 0)? 
                        (state.editedItem.creator === 'Deleted User' ? context.translation.deleted_user[context.locale] : state.editedItem.creator): 
                      (auth.user.first_name + ' ' + auth.user.last_name) 
                    } 
                    <small style={{ fontWeight: '700', marginleft: '5px' }}>
                      {
                        (state.editedItem.creator)? 
                          state.editedItem.creator === auth.user.first_name + ' ' + auth.user.last_name ? 
                            `(${context.translation.you[context.locale]})`: '' : `(${context.translation.you[context.locale]})`
                      }
                    </small>
                  </Col>

                  <Col xs={12}>
                    <FormGroup id="instruction-step">
                      <Label for="instruction" style={{ fontWeight: 600 }}>{context.translation.instruction[context.locale]}</Label>
                      {
                        !!!state.editedItem.loggedUserNotOnTeam && 
                          <Tooltip title="Edit">
                            <IconButton style={{ display: (!state.isNew && !state.editPoint && !state.editInstructions && auth.user.first_name + ' ' + auth.user.last_name === state.editedItem.creator)? 'initial': 'none', marginTop: '-2px', marginLeft: 0 }}  aria-label="Edit"  onClick={() => dispatch({ type: 'SET_STATE', payload: { editInstructions: true } })}>
                              <EditIcon style={{ fontSize: '0.8rem', color: '#2745EC' }}/>
                            </IconButton>
                          </Tooltip>
                      }
                      <br />
                      {
                        state.editedItem.id > 0 && !state.editInstructions ?
                        (<pre id="pre-instruction">{state.editedItem.instructions}</pre>):
                          (<>
                            <Input autoFocus type="textarea" name="instructions" id="instruction" defaultValue={state.editedItem.instructions} onBlur={e => dispatch({ type: 'SET_STATE', payload: { editedItem: {...state.editedItem, instructions: e.currentTarget.value} }})} required />
                            <div style={{ width: '100%', textAlign: 'right' }}>
                              <EditAction show={state.editInstructions} save={() => saveField({ instructions: state.editedItem.instructions }, {rowData: {editedItem: {...state.rowData.editedItem, instructions: state.editedItem.instructions} }}, 'point', 'editInstructions')} cancel={() => dispatch({ type: 'SET_STATE', payload: { editInstructions: false, editedItem: {...state.editedItem, instructions: state.rowData.editedItem.instructions} } })}/>
                            </div>
                          </>)
                      }
                    </FormGroup>
                  </Col>     

                  <Col xs={12}>
                    <ModalGateway>
                      {viewerIsOpen ? (
                        <Modal onClose={closeLightbox}>
                          <Carousel
                            currentIndex={currentImage}
                            views={state.gallery.map(x => {
                              // console.log(x)
                              return {
                                ...x,
                                srcset: x.srcSet,
                                caption: ''
                              }
                            })}
                          />
                        </Modal>
                      ) : null}
                    </ModalGateway>
                  </Col>

                  <Col xs={12}>
                    <div id="comment-step" className="comment-box" style={{ marginTop: '20px' }}>
                      {/* <span style={{ fontWeight: 600, display: 'inline-block', marginBottom: '7px' }}>{context.translation.comments[context.locale]}</span> */}
                      <div onClick={() => toggles('1')} className="tabit">
                        <div className={`tabi ${activeTab == '1'? 'tabiactive': ''}`} >{context.translation.client_comment[context.locale]} <Badge color="warning" style={{ display: 0 !== 0? 'inline-block': 'none', marginTop: '1.5px', marginLeft: '5px', backgroundColor: activeTab == '1'? '#FFF' : '#3663FD', color: activeTab == '1'? '#3765FD': '#fff' }} pill>{ 0 }</Badge></div>
                      </div>

                      <div onClick={() => toggles('2')} className="tabit">
                        <div className={`tabi ${activeTab == '2'? 'tabiactive': ''}`} >{context.translation.developer_comments[context.locale]} <Badge color="warning" style={{ display: 0 !== 0? 'inline-block': 'none', marginTop: '1.5px', marginLeft: '5px', backgroundColor: activeTab == '2'? '#FFF' : '#3663FD', color: activeTab == '2'? '#3765FD': '#fff' }} pill>{ 0 }</Badge></div>
                      </div>
                      <div className="comment-section">
                        <Scrollbar id="comment-scroll" ref={scrollbar} alwaysShowTracks={true} renderByPixels={true} continuousScrolling={true} style={{ minHeight: '63px', maxHeight: '300px', width: '100%' }}>
                          {
                            state.editedItem.comments.filter(e => activeTab == 2 ? e.type === 'developer' : e.type === 'client').length !== 0 ? 
                              state.editedItem.comments.filter(e => activeTab == 2 ? e.type === 'developer' : e.type === 'client').map((e, i) => {                        
                                if (parseInt(auth.user.id) === e.user_id) {
                                  const marginTop = (sameUser === true)? '-16px': '10px'
                                  sameUser = true
                                  return (
                                    <div key={i} className="comment-container" style={{ marginTop }}>
                                      <div className="comment-group">
                                        <div className="comments right">
                                          <div className="comment-c right">
                                            <div className="comment your"><pre>{e.comments}</pre></div>
                                              <Popconfirm
                                                title={context.translation.delete_comment[context.locale]}
                                                onConfirm={deleteComment(e.id)}
                                                okText={context.translation.yes[context.locale]}
                                                cancelText={context.translation.no[context.locale]}
                                              >
                                                <Icon type="delete" className={'delete-comment'}/>
                                              </Popconfirm>
                                            <small className="comment-time right"><Moment interval={30000} locale={context.locale} fromNow>{ e.date_commented }</Moment></small>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )
                                } else {
                                  sameUser = false
                                  let visibility = 'visible'
                                  if (i < state.editedItem.comments.length - 1) {
                                    if (e.user_id === state.editedItem.comments[i+1].user_id) { // Hide user project role if next loop is the same user
                                      visibility = 'hidden'
                                    }                             
                                  } 

                                  if (i !== 0) { // Skip first loop
                                    if (e.user_id === state.editedItem.comments[i-1].user_id) { // Hide name and adjust margin top if name is equals the previous loop
                                      marginTop = '-12px'
                                      display = 'none'
                                    } else {
                                      marginTop = '7px'
                                      display = 'initial'
                                    }
                                  }
                                  return (
                                    <div key={i} className="comment-container" style={{ marginTop }}>
                                      <div className="left-group" style={{ visibility }}>
                                        <div className="comment-profile-photo">{e.name.split(' ').map(s => s.charAt(0).toString().replace(',',''))}</div>
                                      </div>

                                      <div className="right-group">
                                        <div className="comment-group">

                                          <small className="comment-name" style={{ display }}>{(e.name === 'Deleted User' ? context.translation.deleted_user[context.locale] : (e.name + ' | ' + e.project_role))}</small>

                                          <div className="comments">
                                            <div className="comment-c">
                                              <div className="comment"><pre>{e.comments}</pre></div><small className="comment-time"><Moment interval={30000} locale={context.locale} fromNow>{ e.date_commented }</Moment></small>
                                            </div>
                                          </div>

                                        </div>
                                      </div>
                                    </div>
                                  )
                                }
                              }) : <Empty style={{ marginTop: '20px' }} />
                          }            
                          {/* {
                            loading && 
                            <div className="comment-container" style={{ marginTop }}>
                              <div className="left-group" style={{ visibility: 'hidden' }}>
                                <div className="comment-profile-photo">sd</div>
                              </div>

                              <div className="right-group">
                                <div className="comment-group">
                                  <div className="comments">
                                    <div className="comment-c">
                                      <div className="comment">
                                        <ChattingState />
                                      </div>
                                    </div>
                                  </div>

                                </div>
                              </div>
                            </div>
                          }       */}
                        </Scrollbar>
                        
                        {
                          (state.project_role !== 'Feedback Provider' || (state.project_role === 'Feedback Provider' && activeTab == 1))?
                            <div className="comment-bottom">
                              <InputGroup>
                                <Mentions
                                  onChange={e => {
                                    const el = document.getElementById('comment-input')
                                    setTimeout(function(){
                                      el.style.padding = '3px 8px';
                                      el.style.height = 'auto';
                                      // for box-sizing other than "content-box" use:
                                      // el.style.cssText = '-moz-box-sizing:content-box';
                                      el.style.height = el.scrollHeight + 'px';
                                    },0);

                                    console.log(isEnter)
                                    if (!isEnter)
                                      dispatch({ type: 'SET_STATE', payload: { editedItem: {...state.editedItem, comment: e} } })
                                    else
                                      isEnter = false
                                  }}
                                  onKeyPress={e => {
                                    if (e.key === 'Enter' && !e.shiftKey) {
                                      isEnter = true
                                    } else {
                                      isEnter = false
                                    }
                                  }}
                                  placeholder={context.translation.write_comment[context.locale]} 
                                  rows="1" 
                                  name="comment-input" 
                                  id="comment-input" 
                                  bssize="sm" 
                                  tabIndex="0"
                                  value={state.editedItem.comment}
                                >
                                  {
                                    state.members.map(user => <Option key={user.id} userID={user.id} value={user.name.replace(' ', '')}>{user.name}</Option>)
                                  }
                                </Mentions>
                                {/* <Input type="textarea" onChange={(e) => setComment(e.currentTarget.value)} onBlur={e => dispatch({ type: 'SET_STATE', payload: { editedItem: {...state.editedItem, comment: e.currentTarget.value} } })} placeholder={context.translation.write_comment[context.locale]} rows="1" name="comment-input" id="comment-input" bssize="sm" onKeyDown={e => handleKeyDown(e)} tabIndex="0"/> */}
                                
                                    <InputGroupAddon addonType="append">
                                      <InputGroupText id="send-comment" onClick={() => addComment(false)} bssize="sm" className={`${(!!state.editedItem.comment && !state.sendingComment)? 'send-comment' : ''} comment-here`}>
                                        {
                                          state.sendingComment ? 
                                            <Spinner size="sm" color="primary"/> 
                                            :
                                              <i className="fas fa-paper-plane"></i>
                                        }
                                      </InputGroupText>
                                    </InputGroupAddon>
                              </InputGroup>
                            </div> : ''
                        }
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>

              <Col>
                <Row style={{ marginLeft: '-15px' }}>

                  <Row style={{ marginLeft: 0, marginRight: 0, width: '100%' }}>
                    <Col>
                      <FormGroup id="status-step">
                        <Label for="status" style={{ fontWeight: 600 }}>Status</Label> 
                        {
                          !!!state.editedItem.loggedUserNotOnTeam && 
                            <Tooltip title="Edit">
                              <IconButton style={{ display: (!state.isNew && !state.editPoint && !state.editStatus && state.editedItem.creator)? 'initial': 'none', marginTop: '-2px', marginLeft: 0 }}  aria-label="Edit" onClick={() => dispatch({ type: 'SET_STATE', payload: { editStatus: true } })}>
                                <EditIcon style={{ fontSize: '0.8rem', color: '#2745EC' }}/>
                              </IconButton>
                            </Tooltip>
                        }
                        {
                          state.editedItem.id > 0 && !state.editStatus ?
                            (<>
                              <br /><span className={`badge badge-sm ${state.editedItem.status === 'Draft' && "bg-light"}`} style={{ ...badgeColorStatus.find(e => e.name === state.editedItem.status) }}>{context.translation.status[state.editedItem.status.toLowerCase().replace(' ', '_')][context.locale]}</span>{' '}
                            </>) :
                              (<>
                                <EditAction hide show={state.editStatus} save={() => saveField({ status: state.editedItem.status }, {rowData: {editedItem: {...state.rowData.editedItem, status: state.editedItem.status} }}, 'point', 'editStatus')} cancel={() => dispatch({ type: 'SET_STATE', payload: { editStatus: false, editedItem: {...state.editedItem, status: state.rowData.editedItem.status} } })}/>
                                <br />
                                <Input style={{ display: 'inline-block' }} type="select" name="status" id="status" defaultValue={state.editedItem.status} onChange={e => {
                                  if (state.editStatus) 
                                    saveField({ status: e.currentTarget.value }, {rowData: {editedItem: {...state.rowData.editedItem, status: e.currentTarget.value} }}, 'point', 'editStatus')
                                  else
                                    dispatch({ type: 'SET_STATE', payload: { editedItem: {...state.editedItem, status: e.currentTarget.value} } })
                                }}>
                                  {
                                    Object.entries(status).filter(e => project_role !== 'Feedback Provider' ? true: (!statusFP.includes(e[0]) ? true : state.editedItem.status.replace(' ', '_') == e[0])).map((el, index) => { 
                                      return (
                                        <option key={el[0]} value={el[0].split('_').toString().toString().replace(',',' ')}>{context.translation.status[el[0].toLowerCase()][context.locale]}</option>    
                                      )
                                    })
                                  }
                                </Input>

                              </>)

                        }
                        
                      </FormGroup>
                    </Col>

                  <Col style={{ display: state.project_role !== 'Feedback Provider' && state.editedItem.id > 0? 'initial': 'none' }}>
                    <FormGroup id="hours-worked-step">
                      <Label for="hours_worked" style={{ fontWeight: 600 }}>{context.translation.hours_worked[context.locale]}</Label>

                      {
                        state.project_role !== 'Feedback Provider' && 
                          (
                            <Popover 
                              id="popup-timelog"
                              content={<PopoverContent data={{ point_id: state.editedItem.id }} context={context} reducer={{ state, dispatch }} form={form} momentTime={{ momentTime: momentTime, setMomentTime }} popover={{ popover: popHoursWorked, setPopover: setPopHoursWorked }} tpOpen={{ tpOpen, setTpOpen }} />} 
                              title={context.translation.assign_user[context.locale]} 
                              trigger="click"
                              visible={popHoursWorked}
                              onVisibleChange={visible => setPopHoursWorked(visible)}
                            >
                              <IconButton style={{ marginTop: '-2px', marginLeft: 0 }}  aria-label="Edit"  onClick={() => dispatch({ type: 'SET_STATE', payload: { editHoursWorked: true } })}>
                                <EditIcon style={{ fontSize: '0.8rem', color: '#2745EC' }}/>
                              </IconButton>
                            </Popover>
                          )
                      }

                      {/* {
                        state.editedItem.id > 0 && !state.editHoursWorked ? 
                          (<> */}
                            <br />
                            {
                              state.editedItem.hours_worked !== ''? 
                                (
                                  <Popover 
                                    content={<Content logHistory={logHistory} historyLoading={historyLoading} context={context} />} 
                                    title={context.translation.timelog_history[context.locale]} 
                                    placement="bottomRight" 
                                    trigger="hover"
                                    visible={visible}
                                    onVisibleChange={visible => setVisible(visible)}
                                  >
                                    <span style={{ marginRight: '5px' }}>{state.editedItem.hours_worked !== ''? state.editedItem.hours_worked: 'N/A'}</span><i className="fa fa-history" aria-hidden="true"></i>
                                  </Popover>
                                ):
                                <><span style={{ marginRight: '5px' }}>{state.editedItem.hours_worked !== ''? state.editedItem.hours_worked: 'N/A'}</span><i className="fa fa-history" aria-hidden="true"></i></>
                            }
                            
                          {/* </>) :
                            (<>
                              <EditAction show={state.editHoursWorked} disable={state.editedItem.hours_worked.trim().length === 0 || state.editedItem.hours_worked.trim() === ':'? false : state.editedItem.hours_worked.trim().length !== 5} save={() => saveField({ hours_worked: state.editedItem.hours_worked }, {rowData: {editedItem: {...state.rowData.editedItem, hours_worked: state.editedItem.hours_worked, time_approve: 0} }, editedItem: {...state.editedItem, time_approve: 0}}, 'point', 'editHoursWorked')} cancel={() => dispatch({ type: 'SET_STATE', payload: { editHoursWorked: false, editedItem: {...state.editedItem, hours_worked: state.rowData.editedItem.hours_worked } } })}/>                      
                              <br />
                              <Input onKeyPress={e => {if (!(state.editedItem.hours_worked.trim().length === 0 || state.editedItem.hours_worked.trim() === ':'? false : state.editedItem.hours_worked.trim().length !== 5) && e.key === 'Enter' && state.editHoursWorked) saveField({ hours_worked: state.editedItem.hours_worked === '  :  '? '': state.editedItem.hours_worked }, {rowData: {editedItem: {...state.rowData.editedItem, hours_worked: state.editedItem.hours_worked === '  :  '? '': state.editedItem.hours_worked, time_approve: 0} }, editedItem: {...state.editedItem, time_approve: 0}}, 'point', 'editHoursWorked')}} invalid={check_hour_len} style={{ display: 'inline-block' }} tag={InputMask} maskChar=" " name="hours_worked" id="hours_worked" mask="99:99" placeholder="HH:mm" bssize="sm" minLength="5" value={state.editedItem.hours_worked} onChange={e => {check_hour_len = document.getElementById('hours_worked').value.trim().length === 0 || document.getElementById('hours_worked').value.trim() === ':'? false : document.getElementById('hours_worked').value.trim().length !== 5; dispatch({ type: 'SET_STATE', payload: { editedItem: {...state.editedItem, hours_worked: e.currentTarget.value} } })}} />
                              <FormFeedback>{context.translation.error_invalid_time[context.locale]}</FormFeedback>
                            </>)
                      } */}
                    </FormGroup>
                  </Col>
                  </Row>
                  
                  <Col xs={6} style={{ display: (state.project_role !== 'Feedback Provider')? !(state.editedItem.id > 0) && state.project_role === 'Project Staff'? 'none': 'initial': 'none' }}>
                    <FormGroup id="deadline-step">
                      <Label for="deadline" style={{ fontWeight: 600 }}>{context.translation.deadline[context.locale]}</Label>
                      {
                        !!!state.editedItem.loggedUserNotOnTeam && 
                          <Tooltip title="Edit">
                            <IconButton style={{ display: (!state.isNew && !state.editPoint && !state.editDeadline && state.project_role === 'Project Leader' && !!state.editedItem.id)? 'initial': 'none', marginTop: '-2px', marginLeft: 0 }}  aria-label="Edit"  onClick={() => dispatch({ type: 'SET_STATE', payload: { editDeadline: true } })}>
                              <EditIcon style={{ fontSize: '0.8rem', color: '#2745EC' }}/>
                            </IconButton>
                          </Tooltip>
                      }
                      {
                        state.editedItem.id > 0 && !state.editDeadline ?
                          (<>
                            <br /><span>{state.editedItem.deadlineDisplay}</span>
                          </>) :
                            (
                              <>
                                <EditAction show={state.editDeadline} save={() => saveField({ deadline: state.editedItem.deadline }, {rowData: {editedItem: {...state.rowData.editedItem, deadline: state.editedItem.deadline, deadlineDisplay: state.editedItem.deadlineDisplay} }}, 'point', 'editDeadline')} cancel={() => dispatch({ type: 'SET_STATE', payload: { editDeadline: false, editedItem: {...state.editedItem, deadline: state.rowData.editedItem.deadline, deadlineDisplay: state.rowData.editedItem.deadlineDisplay} } })}/>
                                <br />
                                <InputGroup>
                                  <Input style={{ display: 'inline-block' }} type="text" name="deadline" id="deadline" placeholder="N/A" value={state.editedItem.deadlineDisplay} readOnly />
                                  <InputGroupAddon style={{ height: '31px' }} addonType="append">
                                    <But id="dateme" outline onClick={() => showDate()} style={{ paddingTop: '2px' }}><DateIcon /></But>
                                  </InputGroupAddon>
                                  <i onClick={() => dispatch({ type: 'SET_STATE', payload: { editedItem: {...state.editedItem, deadline: moment(), deadlineDisplay: 'N/A'} } })} className="fas fa-times-circle clear-deadline" style={{ display: (state.editedItem.deadlineDisplay !== 'N/A')? 'initial': 'none', position: 'absolute', right: '55px', top: '10px' }}></i>
                                </InputGroup>
                                <InputMoment moment={state.editedItem.deadline} onChange={(e) => dispatch({ type: 'SET_STATE', payload: { editedItem: {...state.editedItem, deadline: e, deadlineDisplay: e.format('ll')} } })} onSave={() => { document.querySelector('.m-input-moment').classList.remove('slide-in')}} /> 
                              </>
                            )
                      }
                    </FormGroup>
                  </Col>

                  <Col xs={6} style={{ display: state.project_role !== 'Feedback Provider'? 'initial': 'none' }}>
                    <FormGroup id="time-estimate-step">
                      <Label for="time_estimate" style={{ fontWeight: 600 }}>{context.translation.time_estimate[context.locale]}</Label>
                      {
                        !!!state.editedItem.loggedUserNotOnTeam && 
                          <Tooltip title="Edit">
                            <IconButton style={{ display: (state.project_role !== 'Feedback Provider' && !state.editPoint && !state.editTimeEstimate && !!state.editedItem.id)? 'initial': 'none', marginTop: '-2px', marginLeft: 0 }}  aria-label="Edit"  onClick={() => dispatch({ type: 'SET_STATE', payload: { editTimeEstimate: true } })}>
                              <EditIcon style={{ fontSize: '0.8rem', color: '#2745EC' }}/>
                            </IconButton>
                          </Tooltip>
                      }
                      {
                        state.editedItem.id > 0 && !state.editTimeEstimate ?
                          (<><br /><TimeApprove time_approve={state.editedItem.time_approve} time_estimate={state.editedItem.time_estimate} /></>) :
                            (<>
                              <EditAction show={state.editTimeEstimate} disable={check_time_len} save={() => saveField({ time_estimate: state.editedItem.time_estimate }, {rowData: {editedItem: {...state.rowData.editedItem, time_estimate: state.editedItem.time_estimate, time_approve: 0} }, editedItem: {...state.editedItem, time_approve: 0}}, 'point', 'editTimeEstimate')} cancel={() => dispatch({ type: 'SET_STATE', payload: { editTimeEstimate: false, editedItem: {...state.editedItem, time_estimate: state.rowData.editedItem.time_estimate } } })}/>                      
                              <br />
                              <Input onKeyPress={handleOnKeyPress} invalid={check_time_len} style={{ display: 'inline-block' }} tag={InputMask} maskChar=" " name="time_estimate" id="time_estimate" mask="99:99" placeholder="HH:mm" bssize="sm" minLength="5" value={state.editedItem.time_estimate} onChange={handleOnChange} />
                              {/* <FormFeedback>{ check_time_len ? parseInt(state.editedItem.time_estimate.trim().replace(':', '')) > 800 ? context.translation.error_max_estimate[context.locale] : context.translation.error_invalid_time[context.locale] : '' }</FormFeedback> */}
                              <FormFeedback>{ check_time_len && context.translation.error_invalid_time[context.locale] }</FormFeedback>
                            </>)
                      }
                    </FormGroup>
                  </Col>

                  <Col xs={6} style={{ display: state.project_role !== 'Feedback Provider'? !(state.editedItem.id > 0) && state.project_role === 'Project Staff'? 'none': 'initial': 'none' }}>
                    <FormGroup id="priority-step">
                      <Label for="priority" style={{ fontWeight: 600 }}>{context.translation.priority[context.locale]}</Label>
                      {
                        !!!state.editedItem.loggedUserNotOnTeam && 
                          <Tooltip title="Edit">
                            <IconButton style={{ display: (!state.isNew && !state.editPoint && !state.editPriority && state.project_role === 'Project Leader' && !!state.editedItem.id)? 'initial': 'none', marginTop: '-2px', marginLeft: 0 }}  aria-label="Edit"  onClick={() => dispatch({ type: 'SET_STATE', payload: { editPriority: true } })}>
                              <EditIcon style={{ fontSize: '0.8rem', color: '#2745EC' }}/>
                            </IconButton>
                          </Tooltip>
                      }
                      {
                        state.editedItem.id > 0 && !state.editPriority ?
                        (<>
                          <br /><span className={`badge badge-sm`} style={{ ...badgeColorPriority.find(e => e.name === state.editedItem.priority) }}>{ context.translation[state.editedItem.priority.toLowerCase()][context.locale]}</span>
                        </>) :
                          (<>
                            <EditAction hide show={state.editPriority} save={() => saveField({ priority: state.editedItem.priority }, {rowData: {editedItem: {...state.rowData.editedItem, priority: state.editedItem.priority} }}, 'point', 'editPriority')} cancel={() => dispatch({ type: 'SET_STATE', payload: { editPriority: false, editedItem: {...state.editedItem, priority: state.rowData.editedItem.priority } } })}/>
                            <br />
                            <Input style={{ display: 'inline-block' }} type="select" name="priority" id="priority" defaultValue={state.editedItem.priority} onChange={e => {
                              if (state.editPriority) 
                                saveField({ priority: e.currentTarget.value }, {rowData: {editedItem: {...state.rowData.editedItem, priority: e.currentTarget.value} }}, 'point', 'editPriority')
                              else
                                dispatch({ type: 'SET_STATE', payload: { editedItem: {...state.editedItem, priority: e.currentTarget.value} } 
                            })}}>
                              <option value="Low">{ context.translation.low[context.locale] }</option>
                              <option value="Mid">{ context.translation.mid[context.locale] }</option>
                              <option value="High">{ context.translation.high[context.locale] }</option>
                            </Input>

                          </>)
                      }
                      
                    </FormGroup>
                  </Col>

                  <Col xs={6} style={{ display: state.project_role !== 'Feedback Provider'? !(state.editedItem.id > 0) && state.project_role === 'Project Staff'? 'none': 'initial': 'none' }}>
                    <Label for="priority" style={{ fontWeight: 600 }}>{context.translation.last_change[context.locale]} 
                    {
                      !!state.editedItem.last_change &&
                        <Popover 
                          content={<Content logHistory={changeHistory} historyLoading={historyLoading} context={context} />} 
                          title={context.translation.timelog_history[context.locale]} 
                          placement="bottomRight" 
                          trigger="hover"
                          visible={visible1}
                          onVisibleChange={visible1 => setVisible1(visible1)}
                        >
                          <i className="fa fa-history" aria-hidden="true" style={{ marginLeft: '3px' }}></i>
                        </Popover>
                    }
                    </Label>
                    <br />
                    {
                      !!state.editedItem.last_change && (
                        <>
                          <small style={{ display: 'block', color: '#cccccc' }}><b>{ state.editedItem.last_change.name }</b></small>
                          <p style={{ fontSize: '14px' }}>{ context.translation.type_of_change[(state.editedItem.last_change.description).split('*')[0]][context.locale] }</p> 
                        </>
                      )
                    }
                  </Col>

                            {/* isSelfAssigned ? (<Button id="assign-self" color="primary" variant="contained" size="small" onClick={() => saveField({ team: [...state.editedItem.users, { id: auth.user.id, name: auth.user.first_name + ' ' + auth.user.last_name, project_role: state.project_role }] }, {rowData: {editedItem: {...state.rowData.editedItem, users: [...state.rowData.editedItem.users, { id: auth.user.id, name: auth.user.first_name + ' ' + auth.user.last_name, project_role: state.project_role }]} }}, 'team', 'editAssignedUsers')} type="button" style={{ height: '20px', marginLeft: '10px' }}>{context.translation.assign_self[context.locale]}</Button>) 
                              : (<Button id="unassign-self" color="secondary" variant="outlined" size="small" onClick={() => saveField({ team: state.rowData.editedItem.users.filter(e => e.id !== auth.user.id) }, {rowData: {editedItem: {...state.rowData.editedItem, users: state.rowData.editedItem.users.filter(e => e.id !== auth.user.id)} }}, 'team', 'editAssignedUsers')} type="button" style={{ height: '20px', marginLeft: '10px' }}>{context.translation.unassign_self[context.locale]}</Button>)  */}

                  <Row style={{ marginLeft: 0, marginRight: 0, width: '100%', display: !(state.editedItem.id > 0) && state.project_role === 'Feedback Provider'? 'none': 'flex' }}>
                    <Col xs={10} style={{ display: 'inline-block' }}>
                      <FormGroup style={{ marginRight: '10px' }} id="assign-users-step">
                        <Label for="users" style={{ fontWeight: 600 }}>{(state.editedItem.id > 0 && !state.editPoint)? context.translation.assigned_user[context.locale]: context.translation.assign_user[context.locale]}</Label>
                        {
                          (state.project_role !== 'Feedback Provider' && !!!state.editedItem.loggedUserNotOnTeam) ? (
                            <Popover 
                              content={<AssignUserContent reducer={{ state, dispatch, saveField, visiblePopover }} context={context} />} 
                              title={context.translation.assign_user[context.locale]} 
                              trigger="click"
                              visible={visiblePopover}
                              placement="bottom"
                              onVisibleChange={visible => setVisiblePopover(visible)}
                            >
                              <IconButton style={{ display: (!state.isNew && !state.editPoint && !state.editAssignedUsers && state.project_role !== 'Feedback Provider' && !!state.editedItem.id)? 'initial': 'none', marginTop: '-2px', marginLeft: 0 }}  aria-label="Edit"> {/* onClick={() => dispatch({ type: 'SET_STATE', payload: { editAssignedUsers: true } })} */}
                                <EditIcon style={{ fontSize: '0.8rem', color: '#2745EC' }}/>
                              </IconButton>
                            </Popover>
                          ) : ''
                        }
                        {/* {
                          state.project_role === 'Project Staff' && !state.isNew && !state.editPoint ? 
                              <Button id={isSelfAssigned? "assign-self": 'unassign-self'} color={isSelfAssigned? "primary": "secondary"} variant={isSelfAssigned? "contained": "outlined"} size="small" onClick={() => assignOrUnassign(isSelfAssigned)} type="button" style={{ height: '20px', marginLeft: '10px' }}>{context.translation[isSelfAssigned? 'assign_self': 'unassign_self'][context.locale]}</Button>
                                : ''
                        } */}
                        {
                          state.editedItem.id > 0 && !state.editAssignedUsers ?
                            '' :
                              (<>
                                <EditAction show={state.editAssignedUsers} save={() => saveField({ team: state.editedItem.users }, {rowData: {editedItem: {...state.rowData.editedItem, users: state.editedItem.users} }}, 'team', 'editAssignedUsers')} cancel={() => dispatch({ type: 'SET_STATE', payload: { editAssignedUsers: false, editedItem: {...state.editedItem, users: state.rowData.editedItem.users } } })}/>                      
                                <Input onChange={(e) => handleChange(e)} type="select" id="users" bssize="sm">
                                  { 
                                    !!state.members && state.members.filter(e => { 
                                      for (let i in state.editedItem.users) {
                                        if (state.editedItem.users[i].id == e.id)
                                          return false
                                      }
                                      return true
                                    }).map(e => <option key={e.id} data-id={e.id} data-role={e.project_role} value={e.name}>{e.name} - {e.project_role}</option>)
                                  }
                                </Input>
                              </>)
                        }
                      </FormGroup>
                    </Col>
                    <Col xs={2} style={{ paddingTop: '31px', marginTop: '-5px', verticalAlign: 'middle', display: 'inline-block' }}>
                      {
                        state.editedItem.id > 0 && !state.editAssignedUsers ? '' :
                          <Button color="primary" size="small" onClick={addUser} type="button" style={{ width: '100%', float: 'right' }} disabled={state.editedItem.users.length === state.members.length}>{context.translation.add[context.locale]}</Button>
                      }
                    </Col>
                    <Col xs={12} style={{ marginTop: (state.editedItem.id > 0 && !state.editAssignedUsers)? '-15px': 0 }} id={`${(state.editedItem.id > 0 && !state.editAssignedUsers)? 'edit-assign-users': 'assigned-users'}`}>
                      {/* {
                        state.editedItem.id > 0 && !state.editPoint ? '' :
                          <span><strong style={{ verticalAlign: 'top', marginRight: '5px' }}>Team: </strong></span>
                          <Tooltip key={i} title={e.name + " - " + e.project_role}> //Temp loc
                      } */}
                      {
                        state.editedItem.users.length !== 0 ? state.editedItem.users.map((e, i) => (
                          state.editedItem.id > 0 && !state.editAssignedUsers && auth.user.id == e.id?
                            <Tooltip key={i} title={e.is_working !== 'active'? context.translation.set_active[context.locale]: context.translation.set_inactive[context.locale]}>
                              <Chip onClick={() => {
                                let current_datetime = new Date()
                                let formatted_date = current_datetime.getFullYear() + "-" + (current_datetime.getMonth() + 1) + "-" + current_datetime.getDate() + " " + current_datetime.getHours() + ":" + current_datetime.getMinutes() + ":" + current_datetime.getSeconds() 
                                const users = state.editedItem.users.map(e => e.id == auth.user.id? {...e, is_working: e.is_working !== 'active'? 'active': 'inactive', date_updated: formatted_date}: e)
                                
                                saveField({ team: users }, {rowData: {editedItem: {...state.rowData.editedItem, users } }}, 'team', 'editAssignedUsers')
                              }} variant={(!!e.id)? 'default': 'outlined'} style={{ marginRight: '5px', marginBottom: '5px', boxShadow: '0 2px 5px rgba(0,0,0,0.20)' }} key={i} color={(state.editedItem.id > 0 && !state.editAssignedUsers)? 'default': 'primary'} className={`MuiChip-sizeSmall clickable`} label={<>{e.name} <small style={{ marginLeft: '2px', fontWeight: '600' }}>{(auth.user.id === e.id) && ` (${context.translation.you[context.locale]})`}</small>{e.is_working === 'active' && <Badge style={{ paddingLeft: '5px', width: 0 }} status="processing" />}</>} onDelete={removeUser(e)} avatar={<Avatar>{e.project_role.split(' ').map(s => s.charAt(0).toString().replace(',',''))}</Avatar>} />
                            </Tooltip>
                            : 
                              !!e.name.trim() ? <Chip variant={(!!e.id)? 'default': 'outlined'} style={{ marginRight: '5px', marginBottom: '5px', boxShadow: '0 2px 5px rgba(0,0,0,0.20)' }} key={i} color={(state.editedItem.id > 0 && !state.editAssignedUsers)? 'default': 'primary'} className={`MuiChip-sizeSmall ${e.status} clickable`} label={<>{e.name} <small style={{ marginLeft: '2px', fontWeight: '600' }}>{(auth.user.id === e.id) && ` (${context.translation.you[context.locale]})`}</small>{e.is_working === 'active' && <Badge style={{ paddingLeft: '5px', width: 0 }} status="processing" />}</>} onDelete={removeUser(e)} avatar={<Avatar>{e.project_role.split(' ').map(s => s.charAt(0).toString().replace(',',''))}</Avatar>} /> : ''
                        )) :
                          context.translation.msg_no_user[context.locale]
                      }
                    </Col>
                  </Row>

                  <Col xs={12} style={{ marginTop: '10px' }}>
                    <FormGroup id="upload-step">
                      <Label for="Attachments" style={{ fontWeight: 600, marginBottom: '5px' }}>{context.translation.attachments[context.locale]}</Label><small style={{ display: 'block', marginBottom: '10px' }}><b>TIP</b>: {context.translation.msg_preview_image[context.locale]}</small>
                      <Scrollbar id="attachmentss" alwaysShowTracks={true} renderByPixels={true} continuousScrolling={true} style={{ minHeight: '63px', maxHeight: '400px', width: '100%', backgroundColor: '#F1F0EF', borderRadius: '7.5px' }}>
                        {
                          // state.editedItem.id > 0 && !state.editPoint ?
                          //   (state.files.length !== 0) ?
                              // (<Ponds filepondupload={filepondupload} state={state} dispatch={dispatch} context={context} files={files} removefiles={removefiles} openLightbox={openLightbox}/>) :
                                // (<div id="empty-background"><Empty /></div>) :
                                  (<Ponds filepondupload={filepondupload} state={state} dispatch={dispatch} context={context} removefiles={removefiles} openLightbox={openLightbox} history={history} auth={auth}/>)
                        }
                      </Scrollbar>
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
            </>
          ): (
            <>
              <Col xs={12}>
                <FormGroup id="instruction-step">
                  <Label for="instruction" style={{ fontWeight: 600 }}>{context.translation.instruction[context.locale]}</Label>
                  {
                    state.editedItem.id > 0 && !state.editInstructions ?
                    (<pre id="pre-instruction">{state.editedItem.instructions}</pre>):
                      (<>
                        <Input autoFocus type="textarea" name="instructions" id="instruction" defaultValue={state.editedItem.instructions} onBlur={e => dispatch({ type: 'SET_STATE', payload: { editedItem: {...state.editedItem, instructions: e.currentTarget.value} }})} required />
                        <div style={{ width: '100%', textAlign: 'right' }}>
                          <EditAction show={state.editInstructions} save={() => saveField({ instructions: state.editedItem.instructions }, {rowData: {editedItem: {...state.rowData.editedItem, instructions: state.editedItem.instructions} }}, 'point', 'editInstructions')} cancel={() => dispatch({ type: 'SET_STATE', payload: { editInstructions: false, editedItem: {...state.editedItem, instructions: state.rowData.editedItem.instructions} } })}/>
                        </div>
                      </>)
                  }
                </FormGroup>
              </Col> 

              <Col xs={12} style={{ marginTop: '10px' }}>
                <FormGroup id="upload-step">
                  <Label for="Attachments" style={{ fontWeight: 600, marginBottom: '5px' }}>{context.translation.attachments[context.locale]}</Label><small style={{ display: 'block', marginBottom: '10px' }}><b>TIP</b>: {context.translation.msg_preview_image[context.locale]}</small>
                  <Scrollbar id="attachmentss" alwaysShowTracks={true} renderByPixels={true} continuousScrolling={true} style={{ minHeight: '63px', maxHeight: '400px', width: '100%', backgroundColor: '#F1F0EF', borderRadius: '7.5px' }}>
                    {
                      // state.editedItem.id > 0 && !state.editPoint ?
                      //   (state.files.length !== 0) ?
                          // (<Ponds filepondupload={filepondupload} state={state} dispatch={dispatch} context={context} files={files} removefiles={removefiles} openLightbox={openLightbox}/>) :
                            // (<div id="empty-background"><Empty /></div>) :
                              (<Ponds filepondupload={filepondupload} state={state} dispatch={dispatch} context={context} removefiles={removefiles} openLightbox={openLightbox} history={history} auth={null}/>)
                    }
                  </Scrollbar>
                </FormGroup>
              </Col>
            </>
          )
        }
      </Row>
    </>
  )
})