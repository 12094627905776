import React, { useContext, useEffect, useState } from "react";
import { withRouter, Route, Switch } from "react-router-dom";

import { DatePicker } from "antd";
import moment from "moment";
import locale_us from "antd/es/date-picker/locale/en_US";
import locale_de from "antd/es/date-picker/locale/de_DE";

import { Page, Tabs } from "../../common";
import TimelogRecord from "./TimelogRecord";
import TimelogSummary from "./TimelogSummary";

/* Import Context Provider */
import { GlobalContext } from "../../../contexts/GlobalContext";
import { ApolloContext } from "react-apollo";
import { PLOTTED_DATES_QUERY } from "../../../queries";

const { RangePicker } = DatePicker;
let loading = false;
export default withRouter((props) => {
  const context = useContext(GlobalContext);
  const { client } = useContext(ApolloContext);
  const [dateReceived, setDateReceived] = useState({
    date_moment: "",
    date_string: "",
  });
  const [dateValue, setDateValue] = useState({ value: null, dateString: null });
  const [dateValues, setDateValues] = useState(
    [1, 2].map((e) => ({
      value: moment(),
      dateString: moment().format("YYYY-MM-DD"),
    }))
  );
  const [dates, setDates] = useState([]);
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    props.history.push(
      newValue === 0 ? "/time_log/summary" : "/time_log/record"
    );
    setValue(newValue);
  };

  useEffect(() => {
    if (props.location.pathname.toLowerCase() === "/time_log/summary") {
      loadPlottedDates(moment(), true);
      setValue(0);
    } else {
      loadPlottedDates(moment(), false);
      setValue(1);
    }
  }, [props.location]);

  async function loadPlottedDates(current, isSummary) {
    const {
      data: { plotted_dates },
    } = await client.query({
      query: PLOTTED_DATES_QUERY(current.format("YYYY-MM-DD"), isSummary),
    });
    setDates(plotted_dates.map((log) => log.date_received));
  }

  function time(property) {
    return !!property ? property.format("HH:mm") : "";
  }

  function handleChangeDate(values, dateString) {
    setDateValue({ value: values, dateString });
  }

  function handleChangeDate1(values, dateString) {
    setDateValues(
      [0, 1].map((e) => ({ value: values[e], dateString: dateString[e] }))
    );
  }

  return (
    <Page
      title={context.translation.time_log[context.locale]}
      customTabs={
        <Tabs
          reducer={{ value, handleChange }}
          tabs={[
            context.translation.summary[context.locale],
            context.translation.record_time[context.locale],
          ]}
        />
      }
      components={
        <div
          style={{ display: "inline-block", fontWeight: 600 }}
          className="right-component"
        >
          {/* <span>{context.translation.date[context.locale]}: </span> */}
          {value === 0 ? (
            <RangePicker
              dateRender={(current) => {
                const style = {};

                if (dates.includes(current.format("YYYY-MM-DD"))) {
                  style.backgroundColor = "#1890ff";
                  style.color = "#fff";
                  style.borderRadius = "200px";
                }
                return (
                  <div className="ant-calendar-date" style={style}>
                    {current.date()}
                  </div>
                );
              }}
              allowClear={false}
              locale={context.locale === "en" ? locale_us : locale_de}
              onChange={handleChangeDate1}
              defaultValue={[
                moment().locale(context.locale),
                moment().locale(context.locale),
              ]}
              // disabledDate={(current) => current > moment().endOf('day') || (current.isoWeekday() > 5)}
            />
          ) : (
            <DatePicker
              dateRender={(current) => {
                const style = {};

                if (dates.includes(current.format("YYYY-MM-DD"))) {
                  style.backgroundColor = "#1890ff";
                  style.color = "#fff";
                  style.borderRadius = "200px";
                }
                return (
                  <div className="ant-calendar-date" style={style}>
                    {current.date()}
                  </div>
                );
              }}
              allowClear={false}
              value={dateReceived.date_moment}
              onChange={handleChangeDate}
              locale={context.locale === "en" ? locale_us : locale_de}
              defaultValue={moment().locale(context.locale)}
              // disabledDate={(current) => current > moment().endOf('day') || (current.isoWeekday() > 5)}
              // className="right-component"
            />
          )}
        </div>
      }
    >
      <Switch>
        <Route
          path="/time_log/record"
          render={(props) => (
            <TimelogRecord
              context={context}
              time={time}
              dateState={{
                dateValue,
                setDateValue,
                dateReceived,
                setDateReceived,
              }}
              loadPlottedDates={loadPlottedDates}
            />
          )}
        />
        <Route
          path="/time_log/summary"
          render={(props) => (
            <TimelogSummary
              context={context}
              time={time}
              dateState={{
                dateValues,
                setDateValues,
                dateReceived,
                setDateReceived,
              }}
            />
          )}
        />
      </Switch>
    </Page>
  );
});
