import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { ListGroup, ListGroupItem } from 'reactstrap';
import Skeleton from 'react-loading-skeleton';

/* Import Context Provider */
import { GlobalContext } from '../../../contexts/GlobalContext';

const status = ['account', 'security']

export default ({ state, dispatch, stats, type }) => {
  const { translation, locale, selectedFilter } = useContext(GlobalContext)
  const [tempType, setType] = useState(type)
  // const type = match.params.type

  // useEffect(() => {
  //   console.log(match)
  // }, [])

  useEffect(() => {
    console.log(type)
  }, [type])

  return (
      <ListGroup id="status-feedback" style={{ display: 'inline-block', marginRight: '20px', marginTop: '32px' }}>
        <li style={{ listStyle: 'none', padding: '0.75rem 0 0.75rem 0', borderBottom: '1px solid #e3e3e3', marginBottom: '10px' }}><span><strong>{ translation.personal_settings[locale] }</strong></span></li>
        { 
          status.map((e, i) => {
            return (
              <Link key={i} to={e} onClick={() => setType(e)}><ListGroupItem className={`justify-content-between feedback-filter ${tempType === e? 'active-settings': ''}`} >
                { translation[e][locale] }
              </ListGroupItem></Link>
            )
          })
        }
      </ListGroup>
  )
}