import React, { Fragment, useContext, useEffect, useReducer, useRef } from 'react';
import { useSubscription } from 'react-apollo-hooks';
import { Row, Col, Card, CardTitle, CardSubtitle, CardDeck, CardBody } from 'reactstrap';
import { Spin, Icon } from 'antd';
import { withRouter } from 'react-router-dom'
import DashboardTables from './DashboardTables'
import DashboardModal from './modal/DashboardModal'
import Skeleton from 'react-loading-skeleton';
import moment from 'moment';
import Scrollbar from 'react-smooth-scrollbar';
import SmoothScrollbar from 'smooth-scrollbar';
import OverscrollPlugin from 'smooth-scrollbar/plugins/overscroll';
import your_diary from '../../../../brand/your_diary.jpg'


/* IMPORT QUERIES */
import DashboardReducer from '../DashboardReducer'
import { POINT_COUNT, POINT_QUERY, NEW_POINTS_DASHBOARD, PROJECTS_DASHBOARD_QUERY } from '../../../../queries';
import Modal from '../../feedback/form/FeedbackFormModal'

import { ApolloContext } from 'react-apollo'; 

const initialErrorState = {
  client: {
    clientnr: {
      invalid: false,
      message: ''
    },
    clientname: {
      invalid: false,
      message: ''
    },
    client_id: {
      invalid: false,
      message: ''
    }
  }, 
  project: {
    proj_id: {
      invalid: false,
      message: ''
    },
    proj_nr: {
      invalid: false,
      message: ''
    },
    proj_name: {
      invalid: false,
      message: ''
    },
    version: {
      invalid: false,
      message: ''
    },
    team: {
      invalid: false,
      message: ''
    }
  },
  user: {
    email: {
      invalid: false,
      message: ''
    },
    password: {
      invalid: false,
      valid: false,
      message: ''
    }
  }
};

const initialEditedItem = {
  user: { id: 0, name: '' },
  instructions: '',
  status: 'Open',
  deadline: '',
  time_estimate: '',
  hours_worked: '',
  version: 0,
  priority: 'Low',
  comments: [],
  users: [],
  deadline: moment(),
  deadlineDisplay: 'N/A',
  comment: '',
}

const editField = {
  editPoint: false,
  editInstructions: false,
  editStatus: false,
  editTimeEstimate: false,
  editHoursWorked: false,
  editDeadline: false,
  editPriority: false,
  editAssignedUsers: false,
  editVersion: false,
}

const initialModalFieldState = {
  client: {
    id: -1,
    clientnr: '',
    clientname: '',
    old_clientname: '',
    type: 'New',
  },
  project: {
    id: -1,
    proj_name: '',
    type: 'New'
  }
}

const initialFormState = {
  id: -1,
  first_name: '',
  last_name: '',
  email: '',
  password: '',
  password_confirm: '',
  user_type_id: 7,
  client_id: 0,
  isSendEmail: false
}

const initialState = {  
  ...editField,
  id: -1,
  selected: [],
  modal: false,
  modalType: '',
  files: [],
  removefiles: [],
  project: {
    id: -1,
    name: ''
  },
  role: '',
  projectprojectRole: '',
  gallery: [],
  members: [],
  editedItem: {...initialEditedItem},
  editPoint: false,
  uploadNewFiles: false,
  loadURI: '',
  rowData: {files: []},
  dupFiles: [],
  versions: [],
  points: [],
  ack: 0,
  query_count: 0,
  query_count_update_point: 0,
  isFeedbackList: 'dashq',
  isLoading: true,
  closedPoints: 0,
  assignedPoints: 0,
  unassignedPoints: 0,
  timeEstimatePoints: 0,
  openRequests: 0,
  errors: initialErrorState,
  clients: [],
  projects: [],
  modalField: initialModalFieldState,
  client_id: '',
  team: [],
  editVersion: { edit: false, id: 0, version_name: '' },
  selectedClient: '',
  tempVersions: [],
  version: '',
  user: {},
  users: [],
  proj_id: '',
  proj_nr: '',
  proj_name: '',
  isGenerate: false,
  form: initialFormState,
  buttonLoading: false
}

let toggleHeaders = {
  5: false, // Time estimate column hidden
  7: true, // Members column no hidden
  10: true, // Date column not hidden
  11: false // Actions column hidden
}
let points = []
let prev_tab = -1
let prev_tab_without_5 = -1
let query_count = 0
let loc_executed = ''
let timeEstimatePoints = 0
let closedPoints = 0

const StatusCards = ({ locale, status, statusEntries , history }) => {
  const colors = ['#f86c6b', '#20a8d8', '#6f42c1', '#acb4bc', '#e83e8c', '#4dbd74']
  statusEntries.shift();
  statusEntries.pop();
  // statusEntries.pop();

  return (
    <React.Fragment>
      {statusEntries.map((key, index) => {
        const statusKey = (key[1]['en'] === 'For Testing')? 'For_Testing' : key[1]['en'];

        return (
          <Col xs={2} key={index}>
            <Card onClick={() => history.push(`/feedbacks/${statusKey.toLowerCase()}`)} className="mx-0 status-card">
              <CardBody style={{ paddingTop: '10px', zIndex: 1 }}>
                <CardTitle><strong>{key[1][locale]}</strong></CardTitle>
                <CardSubtitle>{ (status[statusKey] === -1 )? <Skeleton /> : status[statusKey] }</CardSubtitle>
              </CardBody>
              <div className="bg-status"></div>
            </Card>
          </Col>
        )
      })} 
    </React.Fragment>
  )
}

export default withRouter(({ status, history, selectedFilter, tabs: { activeTab, setActiveTab }, context: { translation, locale, dis, projects, fetchingPoints }, user, loadTableChange }) => {
  const context = useContext(ApolloContext);
  const [state, dispatch] = useReducer(DashboardReducer, initialState)
  const statusEntries = Object.entries(translation.status)
  const Scrollbarr = useRef(null);

  const newPointsDashboard = useSubscription(NEW_POINTS_DASHBOARD, {variables: { data: {user_id: user.id , tab: activeTab, filter: selectedFilter} }})
  const toggleHeader = (id, toggle) => {
    if (document.querySelectorAll('#toggle-columns button')[id]) {
      document.querySelectorAll('#toggle-columns button')[id].click()
      toggleHeaders[id] = toggle
    }
  }

  const toggle = (tab) => {
    setActiveTab(tab);
    loadTable(selectedFilter, tab, 15, 'tab');  
  }

  useEffect(() => {
    console.log(activeTab , " | " ,prev_tab_without_5)

    // if (prev_tab_without_5 != activeTab) {
      if (activeTab != 5) {
        if (activeTab == 1) { // Assigned Points tab
          if (toggleHeaders[5])  // Time Estimate Column
            toggleHeader(5, false)
          
          if (!toggleHeaders[7])  // Last Worked By Column
            toggleHeader(7, true)
          
          if (!toggleHeaders[10])  // Last Update Column
            toggleHeader(10, true)
          
          if (toggleHeaders[11])  // Actions Column
            toggleHeader(11, false)
          
        } else if (activeTab == 2 || activeTab == 3) { // Unassign points, Closed Points Tab and Open Requests
          if (toggleHeaders[5])  // Time Estimate Column
            toggleHeader(5, false)
    
          if (toggleHeaders[7])  // Last Worked By Column
            toggleHeader(7, false)
    
          if (!toggleHeaders[10])  // Last Update Column
            toggleHeader(10, true)
          
          if (activeTab == 3)
            if (!toggleHeaders[11]) // Actions Column
              toggleHeader(11, true)
          
          if (activeTab == 2) 
            if (toggleHeaders[11]) //Actions Column
              toggleHeader(11, false) 
        } else if (activeTab == 4) { // Time Estimates Tab
          if (!toggleHeaders[5])  // Time Estimate Column
            toggleHeader(5, true)
    
          if (toggleHeaders[7])  // Last Worked By Column
            toggleHeader(7, false)
    
          if (!toggleHeaders[10])  // Last Update Column
            toggleHeader(10, true)
    
          if (!toggleHeaders[11])  // Actions Column
            toggleHeader(11, true)
        }
      // }
    }

    // Check if previous tab and current tab except fot 5th tab
    if (prev_tab_without_5 != activeTab && activeTab != 5) {
      prev_tab_without_5 = activeTab
    }
  }, [activeTab])

  const loadTable = (filter, tab, limit, loc) => {
    // Restart query count if switched to different tab
    let p = {}
    if (prev_tab != tab || ['tab', 'filter'].includes(loc)) {
      prev_tab = tab
      query_count = 1
      loc_executed = loc
      p = { points: [], ack: 0 }
    } else if (loc == 'scroll')  {
      query_count += 1      
    }    

    dispatch({ type: 'SET_STATE', payload: { isFeedbackList: (tab == 2)? 'dashboard': tab, isLoading: true, ...p, query_count } })

    context.client.query({ query: POINT_QUERY({...filter, limit, offset: (limit * (query_count - 1))}, tab)}).then(({ data }) => {
      points = (query_count <= 1) ? data.points : [...state.points, ...data.points]
      let isRequest = false

      if (tab == 5) {
        // Reconstruct data (Open Requests)
        points = points.reduce(function(rv, x) {
          isRequest = true
          let res = { points: [] };
          res.id = x.proj_id;
          res.proj_name = x.proj_name;
          res.client_id = x.client_id;
          res.clientname = x.clientname;
          res.date_created = x.date_created;
          res.points.push(x);
          if (rv.find(r => r.id == x.proj_id)) {
            rv = rv.map(r => {
              if (r.id == x.proj_id) {
                  r.points.push(x)
              }
              
              return r;
            })
          } else {
            rv.push(res)
          }
                
          return rv;
        }, [])
      }
      
      dis({ type: 'SET_STATE', payload: { fetchingPoints: false } })
      dispatch({ type: 'SET_STATE', payload: { points, isLoading: false, isRequest } })
    })
    
    context.client.query({ query: POINT_COUNT(filter)}).then(({ data }) => {
      const { assignedPoints, timeEstimatePoints, closedPoints, unassignedPoints, openRequests } = data.pointCount

      // Gets number of points based on current tab
      let totalPoints =  tab == 1 ? assignedPoints : tab == 2 ? unassignedPoints: tab == 3 ? closedPoints: timeEstimatePoints
      setActiveTab(tab)
      dis({ type: 'SET_STATE', payload: { hasMore: (query_count * limit) < totalPoints } })
      dispatch({ type: 'SET_STATE', payload: { assignedPoints, unassignedPoints, closedPoints, timeEstimatePoints, openRequests } })
    })

    if (tab == 5) {
      context.client.query({ query: PROJECTS_DASHBOARD_QUERY }).then(({ data }) => {
        dispatch({ type: 'SET_STATE', payload: { ...data } })
      })
    }
  }

  // useEffect(() => {
  //   if (state.ack !== 0) {
  //     loadTable(selectedFilter, activeTab, 15, 'ack')
  //   }
  // }, [state.ack])

  useEffect(() => {
    dis({ type: 'SET_STATE', payload: { isDashboardWorkplace: true } })
  }, [])

  useEffect(() => {
    if (fetchingPoints) {
      loadTable(selectedFilter, activeTab, 15, 'scroll');
    }
  }, [fetchingPoints])

  useEffect(() => {
    if (loadTableChange) {
      loadTable(...loadTableChange)
    } else {
      dispatch({ type: 'SET_STATE', payload: { isLoading: false } })
    }
  }, [loadTableChange])

  useEffect(() => {
    if (projects.length !== 0) {
      if (selectedFilter.type.toLowerCase() === 'all' && projects[0].id !== 0) {
        let project_role = ''
        projects.forEach(e => {
          e.team.forEach(a => {
            if (a.id === user.id && a.project_role === 'Project Leader') {
              project_role = 'Project Leader'
            }
          })
        })
        dispatch({ type: 'SET_STATE', payload: { projectRole: project_role } })
      } else if (projects[0].id !== 0 && selectedFilter.type !== 'Client') {
        // if ()
        const members =  projects.find(e =>  selectedFilter.id == e.id).team
  
        let project_role = ''
        members.forEach(e => {
          if (e.id == user.id) 
            project_role = e.project_role 
        })
  
        dispatch({ type: 'SET_STATE', payload: { projectRole: project_role } })
      } else if (projects[0].id !== 0 && selectedFilter.type === 'Client') {
        const members = projects.filter(e =>  selectedFilter.id == e.client_id)
        let project_role = ''
        members.forEach(member => {
          member.team.forEach(e => {
            if (e.id == user.id && e.project_role === 'Project Leader') 
              project_role = e.project_role 
          })
        })
        
        dispatch({ type: 'SET_STATE', payload: { projectRole: project_role } })
      }
    }
  }, [projects, selectedFilter])

  useEffect(() => {
    if (!state.modal)
      dispatch({ type: 'SET_STATE', payload: { editedItem: {...initialEditedItem}, files: [], removefiles: [], gallery: [], editPoint: false, id: -1, ...editField } })
  }, [state.modal])

  useEffect(() => {
    if (newPointsDashboard.data){
      if (newPointsDashboard.data.newPointsDashboard) {
        dispatch({ type: 'SET_STATE', payload: { points: [...newPointsDashboard.data.newPointsDashboard, ...state.points] } })
      }
    }
  }, [newPointsDashboard.data])
   
  return ( 
    <>
      <Modal state={state} dispatch={dispatch} />
      <DashboardModal reducer={{ state, dispatch }} context={{ translation, locale }} initialErrorState={initialErrorState} />

      <Row>
        <Col xs={12}>
          <CardDeck>
            <StatusCards history={history} locale={locale} status={status} statusEntries={statusEntries} />
          </CardDeck>
        </Col>
      </Row>
      <br />
      <Row style={{ marginBottom: '50px' }}>
        <Col>
          <DashboardTables reducers={{ state, dispatch }} selectedFilter={selectedFilter} toggle={toggle} activeTab={activeTab} setActiveTab={setActiveTab} points={state.points} initialStates={{ initialErrorState, initialModalFieldState, initialFormState }} />
          <div id="fetch-more" style={{ display: fetchingPoints? 'block': 'none' }}>
            <Spin indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />} />
            <span>{ translation.fetch_more[locale] }</span> <br />
          </div>
        </Col>
      </Row>
    </>
  );
})