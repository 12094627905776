import React, { useContext, useEffect, Fragment, useState, useCallback } from 'react'
import { withRouter } from 'react-router-dom'
import moment from 'moment';
import './style.css'
import "react-datepicker/dist/react-datepicker.css"
import { FormGroup, Label, Input, ButtonDropdown, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap';
import { Toast } from '../../../common'
import Skeleton from 'react-loading-skeleton';
import EditAction from './EditActions'
import Modal from './FeedbackFormModal'

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import Tooltip from '@material-ui/core/Tooltip';
import FeedbackPointList from './FeedbackPointList'
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';

import { ApolloContext } from 'react-apollo';
import { GlobalContext } from '../../../../contexts/GlobalContext';
import { AuthContext } from '../../../../contexts/AuthContext';
import { UPSERT_FEEDBACK } from '../../../../queries';

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  selectFormControl: {
    width: "100%",
  },
  chip: {
    margin: theme.spacing(1),
  },
 }));

let pass = true;
let isAssigned = false
let hide = null
let showing = false
export default withRouter((props) => {
  const context = useContext(GlobalContext)
  const { client } = useContext(ApolloContext)
  const { user } = useContext(AuthContext)
  const { state, dispatch, initialEditedItem } = props.reducer
  const classes = useStyles();
  const { history, match } = props
  const [dropdown, toggle] = useState(false)

  useEffect(() => {
    context.dispatch({ type: 'SET_STATE',
      payload: { 
        showFab: (state.project_role !== 'None')? true: false,
        tooltipTittle: props.new? context.translation.save_feedback[context.locale]: context.translation.add_point[context.locale], 
        fabAction: () => { 
          dispatch({ type: 'SET_STATE', payload: { modal: !state.modal, modalType: (props.new)? (state.title.trim() !== '')? 'create': 'prompt' : 'create', editedItem: {...initialEditedItem, clientname: state.clientname} } }) 
        },
      } 
    }) // Show FAB

    // if (!showing && state.project_role === 'None') {
    //   const show = !props.new ? !!state.title ? true: false: true
      
    //   if (show) {
    //     hide = message.error(context.translation.not_assigned[context.locale], 0)
    //     showing = true
    //   }
    // } else if (showing) {
    //   if (hide !== null)
    //     hide()

    //   showing = false
    // }

    return () => { // Trigger if component will unmount
      if (showing) hide()
      context.dispatch({ type: 'SET_STATE', payload: { showFab2: false, showFab: false } }) // Hide FAB      
    }
  }, [props.new, state.project_role])

  useEffect(() => {
    if (props.new) {
      const modalType = (props.new)? (state.title.trim() !== '')? 'create': 'prompt' : 'create'
      context.dispatch({ type: 'SET_STATE',
        payload: { 
          fabAction: () => { 
            dispatch({ type: 'SET_STATE', payload: { modal: true, modalType } }) 
          } 
        } 
      })
    }
  }, [state.points, state.title])

  // useEffect(() => {
  //   if (context.projects.id !== 0 && props.new) {
  //     dispatch({ type: 'SET_STATE', payload: { project: { id: context.projects[0].id, name: context.projects[0].name } } })
  //   }
  // }, [context.projects])

  function handleProjectChange(e) {
    const [id, name] = e.currentTarget.value.split('|*|')

    if (!!!state.unsavedData) {
      dispatch({ type: 'SET_STATE', payload: { project: { id, name } } })
    } else {
      if (window.confirm(context.translation.error_unsaved_changes[context.locale])) {
        dispatch({ type: 'SET_STATE', payload: { project: { id, name }, points: [] } })
      }
    }
  }       

  const editTitle = async () => {
    try { 
      const { data } = await client.mutate({ mutation: UPSERT_FEEDBACK, variables: { data: { id: state.id, proj_id: state.project.id, title: state.title } } })
      Toast.showToast('success', context.translation.msg_feedback_updated[context.locale])
      dispatch({ type: 'SET_STATE', payload: { edit: false } })
    } catch (error) {
      Toast.showToast('error', error)
      console.log(error)
    }
  }

  function deleteFeedback () {
    dispatch({ type: 'SET_STATE', payload: { modal: !state.modal, modalType: 'delete', deleteType: 'feedback' } })
  }

  const handleTitleOnChange = useCallback((e) => { 
    dispatch({ type: 'SET_STATE', payload: { title: e.currentTarget.value } })
  }, [state.title])

  return ( 
    <Fragment>
      <Modal state={state} dispatch={dispatch} />
      <Paper className={classes.paper}>
        <FormControl fullWidth>
          <Grid container spacing={3}>
            <Grid id="step-8" container spacing={3} xs={12} style={{ padding: '5px 12px' }}>
              <Grid item xs={12} sm={8}>
                <FormGroup>
                  <Label for="title" id="feedback-title">{context.translation.feedback_title[context.locale]}</Label><br />
                  {
                    !props.new ?
                      !!state.title ?
                        /* UPDATE FEEDBACK */
                        <>
                          <span style={{ display: (!state.edit)? 'initial': 'none', fontSize: '0.875rem' }}>{state.title}</span> 
                          <Input style={{display: (state.edit)? 'initial': 'none', width: '85%' }} name="title" id="title" value={state.title} onChange={e => dispatch({ type: 'SET_STATE', payload: { title: e.currentTarget.value } })} onKeyPress={(event) => { event.key === 'Enter' && editTitle() }} placeholder={context.translation.feedback_title[context.locale]}  />
                          <Tooltip style={{display: (!state.edit && user.id === state.creator.id)? 'initial': 'none' }} title={context.translation.edit[context.locale]}>
                            <IconButton id="edit-feedback-title" aria-label="Edit"  onClick={() => dispatch({ type: 'SET_STATE', payload: { edit: true } })}>
                              <EditIcon/>
                            </IconButton>
                          </Tooltip>
                        </>
                          : <Skeleton count={1} width={'30%'}/>
                            /* CREATE FEEDBACK */
                            : <Input name="title" id="title" value={state.title} onChange={handleTitleOnChange} placeholder={context.translation.feedback_title[context.locale]} />
                  }
                  
                  <EditAction show={state.edit} save={() => editTitle()} cancel={() => dispatch({ type: 'SET_STATE', payload: { edit: false } })} />
                  {/* <span style={{display: (state.edit)? 'initial': 'none' }} disabled={!!!state.title}>
                    <Tooltip title="Save">
                      <Button onClick={() => editTitle()} size="small" style={{ color: 'green', minWidth: 0, marginBottom: '3px', marginLeft: '5px' }}> 
                        <i className="fas fa-check"></i>
                      </Button>
                    </Tooltip>
                    <Tooltip title="Cancel">
                      <Button size="small" style={{ color: 'red', minWidth: 0, marginBottom: '3px' }} onClick={() => dispatch({ type: 'SET_STATE', payload: { edit: false } })}>
                        <i className="fas fa-times"></i>
                      </Button>
                    </Tooltip>
                  </span> */}
                </FormGroup>
              </Grid>

              <Grid item xs={12} sm={props.new? 4: 2}>
                <FormGroup>
                  <Label for="project">{context.translation.nav.project[context.locale]}</Label><br />
                  { 
                    !props.new ?
                      !!state.project.name ?
                      /* UPDATE FEEDBACK */
                        <span style={{ display: (!props.new)? 'initial': 'none', fontSize: '0.875rem' }}>{state.project.name}</span> 
                          : <Skeleton count={1} width={'20%'}/>
                          /* CREATE FEEDBACK */
                            : context.projects.length !== 0 ?
                                context.selectedFilter.type !== 'Project'?
                                  <>
                                    <Input style={{ display: (props.new)? 'initial': 'none' }} type="select" name="project" id="project" value={state.project.id + '|*|' + state.project.name} onChange={handleProjectChange}>
                                      {
                                        context.clients.filter(e => context.selectedFilter.type !== 'All'? context.selectedFilter.id === e.id: true)
                                          .map(client => (
                                            <optgroup label={client.clientname}>
                                              {
                                                context.projects.filter(e => client.id == e.client_id).map((e, i) => <option key={i} value={e.id + '|*|' + e.proj_name}>{ e.proj_name }</option>)
                                              }
                                            </optgroup>
                                          ))
                                      }
                                      {/* <optgroup label={context.selectedFilter.clientname}>
                                        {
                                          context.projects.filter(e => context.selectedFilter.id == e.client_id).map((e, i) => <option key={i} value={e.id + '|' + e.proj_name}>{ e.proj_name }</option>)
                                        }
                                      </optgroup> */}
                                    </Input><br />
                                  </> 
                                    : <span style={{ fontSize: '0.875rem' }}>{context.selectedFilter.name}</span> 
                                      : <Skeleton count={1} width={'20%'}/>
                  }
                  
                </FormGroup>
              </Grid>

              <Grid item xs={12} sm={2} style={{ display: !props.new? 'block': 'none' }}>
                <FormGroup>
                  <Label for="project">{context.translation.created_by[context.locale]}</Label><br />
                    {
                      state.creator.name ? 
                        <span style={{ fontSize: '0.875rem' }}>{state.creator.name}</span>
                        : <Skeleton count={1} width={'20%'}/>
                    }
                  </FormGroup>
              </Grid>
            </Grid>
            {
              user.id == state.creator.id && (
                <ButtonDropdown isOpen={dropdown} toggle={() => toggle(!dropdown)} id="more-options">
                  <DropdownToggle>
                    <i className="fas fa-ellipsis-v"></i>
                  </DropdownToggle>
                  <DropdownMenu >
                    <DropdownItem onClick={() => deleteFeedback()}>{context.translation.delete_feedback[context.locale]}</DropdownItem>
                  </DropdownMenu>
                </ButtonDropdown>
              )
            }


            <Grid item xs={12} sm={12} style={{ paddingTop: '5px' }}>
              <span>{context.translation.members[context.locale]} </span> <br />
              {state.members && (
                <Fragment>
                  {
                    state.members.map((e, i) => (
                      <Tooltip key={i} title={e.name +" - " + context.translation[e.project_role.toLowerCase().replace(' ', '_')][context.locale]}>
                        <Chip style={{ marginLeft: 0, boxShadow: '0 2px 5px rgba(0,0,0,0.20)' }} avatar={<Avatar>{e.project_role.split(' ').map(s => s.charAt(0).toString().replace(',',''))}</Avatar>} label={<>{e.name} <small style={{ marginLeft: '2px', fontWeight: '600' }}>{(user.id === e.id) && ` (${context.translation.you[context.locale]})`}</small></>}  className={`MuiChip-sizeSmall ` + classes.chip} />
                      </Tooltip>)
                    ) 
                  }
                </Fragment>
              )}
              {/* {
                props.new &&
                <Tooltip title={context.translation.add_member[context.locale]}>
                  <Chip label={<i className="fas fa-plus"></i>} onClick={() => alert('This function is still on development.')}  variant="outlined" style={{ borderStyle: 'dashed', width: '32px' }}/>
                </Tooltip>
              } */}
            </Grid>
          </Grid>
        </FormControl>
      </Paper>
    
      <Paper className={classes.paper}>
        <FormControl fullWidth>
          <Grid item xs={12} sm={12}>
            <Typography variant="h6" gutterBottom>{context.translation.feedback_points[context.locale]}</Typography>
            <Grid item xs={12} sm={12}>
              <FeedbackPointList state={state} dispatch={dispatch} points={state.points.sort(function(a, b) {
                  a = new Date(a.date);
                  b = new Date(b.date);
                  return a>b ? -1 : a<b ? 1 : 0;
                }).map(e => {
                    let team = ['','',''], members

                    e.team.forEach(e => {
                      // console.log(e)
                      const suffix = (e.id === user.id)? ` (${context.translation.you[context.locale]})` : ''
                      if (e.project_role === 'Project Leader') {
                        team[0] += (e.name + suffix + '\n')
                      } else if (e.project_role === 'Project Staff') {
                        team[1] += (e.name + suffix + '\n')
                      } else { 
                        team[2] += (e.name + suffix + '\n')
                      }
                    })
                    if (team[0] !== '' || team[1] !== '' || team[2] !== '') {
                      members = team.map((e, i) => {
                        if (!!e[i])
                          return (
                            <Tooltip key={i} title={e}>
                              <Chip style={{ margin: 0, boxShadow: '0 5px 7px rgba(0,0,0,0.20)', marginLeft: (i > 0)? '-4px': 0, paddingLeft: '20px', width: '24px', backgroundColor: 'transparent' }} avatar={<Avatar style={{ width: '25.5px', height: '25.5px', border: '1.5px white solid' }}>{(i === 0)? 'PL': (i === 1)? 'PS' : 'FP' }</Avatar>}  className={`MuiChip-sizeSmall ` + classes.chip} />
                            </Tooltip>
                          )
                      })
                    } else {
                      members = 'N/A'
                    }
                    
                    return {
                      ...e, 
                      members,
                      date: e.date,
                      deadlineMoment: (e.deadline !== 'N/A')? moment(e.deadline): moment()
                    }
                })} />
            </Grid>
          </Grid>
        </FormControl>
      </Paper>
    </Fragment> 
  )
});