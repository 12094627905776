import React, { useReducer, useEffect, useContext } from 'react'
import { withRouter } from 'react-router-dom'
import { Page, Filter } from '../../../common'
import FeedbackForm from './FeedbackForm';
import FeedbackFormReducer from './FeedbackFormReducer'
import { Prompt } from 'react-router'
import { message } from 'antd'
import moment from 'moment';

import { GlobalContext } from '../../../../contexts/GlobalContext';
import { AuthContext } from '../../../../contexts/AuthContext';
import { ApolloContext } from 'react-apollo';
import { FEEDBACK_QUERY, PROJECT_CLIENT_QUERY, IS_USER_ASSIGNED } from '../../../../queries';

const initialEditedItem = {
  user: { id: 0, name: '' },
  instructions: '',
  status: 'Open',
  deadline: '',
  time_estimate: '',
  hours_worked: '',
  version: 0,
  priority: 'Low',
  comments: [],
  users: [],
  deadline: moment(),
  deadlineDisplay: 'N/A',
  comment: ''
}

const editField = {
  editPoint: false,
  editInstructions: false,
  editStatus: false,
  editTimeEstimate: false,
  editHoursWorked: false,
  editDeadline: false,
  editPriority: false,
  editAssignedUsers: false,
  editVersion: false,
}

const initialState = {  
  id: 0,
  title: '',
  loading: false,
  project: {
    id: 0,
    name: ''
  },
  files: [],
  removefiles: [],
  versions: [],
  members: [],
  points: [],
  selected: [],
  edit: false,
  modal: false,
  modalType: 'create',
  confirmModal: false,
  isNew: false,
  feedbackForm: true,
  unsavedData: false,
  editedItem: {...initialEditedItem},
  rowData: {editedItem: {...initialEditedItem}, files: []},
  ...editField,
  gallery: [],
  payloadPoints: [],
  uploadNewFiles: false,
  loadURI: '',
  dupFiles: [],
  isFeedbackList: 'form',
  status: 'Open',
  creator: {
    id: -1,
    name: ''
  },
  project_role: 'None'
}

export default withRouter((props) => {
  const [state, dispatch] = useReducer(FeedbackFormReducer, initialState)
  const id = props.match.params.id 
  const isNew = !id || (isNaN(id));
  const { client } = useContext(ApolloContext)
  const context = useContext(GlobalContext)
  const { user } = useContext(AuthContext)

  useEffect(() => {
    dispatch({ type: 'SET_STATE', payload: { ...initialState, isNew, status: (isNew)? 'All': capital_letter(props.match.params.status, 'upper') } })
    client.query({ query: PROJECT_CLIENT_QUERY('true') }).then(({ data }) => {
      const { projects, clients } = data;
      context.dispatch({ type: 'SET_PROJECT_CLIENT_DATA', projects, clients })
    })
  }, [isNew, id])

  useEffect(() => {
    if (context.projects.length !== 0) {
      if (isNew && context.selectedFilter.id !== 0)
        loadTable(context.selectedFilter,0,0,context.projects)
    }
  }, [context.selectedFilter, isNew, id])

  useEffect(() => {
    /* UPDATE STATE WHEN SELECTED PROJECT CHANGES */
    if (context.projects.length !== 0) {
      if (context.projects[0].id !== 0 && isNew) {
        const proj = (state.project.id === 0 || state.project.id === 'all')? context.projects[0] : context.projects.filter(e => e.id == state.project.id)[0]
  
        let project_role = 'None'
        proj.team.forEach(e => {
          if (e.id == user.id) 
            project_role = e.project_role
        })
  
        dispatch({ type: 'SET_STATE', 
          payload: { 
            // user: proj.team.length !== 0? { id: proj.team[0].id, name: proj.team[0].name, project_role: proj.team[0].project_role }: { id: 0, name: '' },
            members: proj.team,
            project_role,
            versions: proj.project_versions,
          } 
        })
      }
    } else {
      message.warning(context.translation.not_assigned_to_you[context.locale])
      props.history.push('/feedbacks')
    }
  }, [state.project, context.projects])

  useEffect(() => {
    if (state.project.id !== 0 && context.projects.length !== 0) {
      if (context.projects[0].id !== 0) {
        const clientname = context.clients.find(client => client.id == context.projects.find(project => project.id == state.project.id).client_id).clientname
        dispatch({ type: 'SET_STATE', payload: { clientname, editedItem: {...state.editedItem, clientname} }})
      }
    }
  }, [state.project])

  useEffect(() => {
    if (!state.modal) 
      dispatch({ type: 'SET_STATE', payload: { rowData: {editedItem: {...initialEditedItem}, files: []}, editedItem: {...initialEditedItem}, files: [], removefiles: [], gallery: [], editPoint: false, ...editField, } })
  }, [state.modal])

  useEffect(()=> {
    if (isNew)
      dispatch({ type: 'SET_STATE', payload: { unsavedData: (state.points.length !== 0) } })
  }, [state.points])

  // useEffect(() => {
  //   console.table(state.members)
  //   if (state.users.length !== 0)
  //     dispatch({ type: 'SET_STATE', payload: { user: { id: state.members[0].id, name: state.members[0].name } } })
  // }, [state.users.length]);

  useEffect(() => {
    if (!isNew) {
      client.query({ query: IS_USER_ASSIGNED(parseInt(id)) }).then(({data}) => {
        if (data.isUserAssigned == null) {
          message.error(context.translation.err_not_assigned[context.locale])
          props.history.push('/feedbacks')
        } else {
          initialize()
        }
      }).catch(err => {
        console.log(err)
        message.error(context.translation.error_occurred[context.locale])
        initialize()
      })
    } else {
      initialize()
    }
  }, [id])

  function initialize() {
    if (id) {
      dispatch({ type: 'SET_STATE', payload: { loading: true } })
      client.query({ query: FEEDBACK_QUERY(id) }).then(({ data }) => {
        const { title, proj_id, proj_name, points, team, versions, creator, creator_id, clientname } = data.feedback
        let project_role = context.translation.none[context.locale]
        team.forEach(e => {
          if (e.id == user.id) 
            project_role = e.project_role
        })
        dispatch({ type: 'SET_STATE', 
          payload: { 
            id,
            title,
            clientname,
            project: { 
              id: proj_id, 
              name: proj_name,
            }, 
            creator: {
              id: creator_id,
              name: creator === 'Deleted User' ? context.translation.deleted_user[context.locale] : creator
            },
            points: points.map(e => ({...e, clientname})),
            members: [...team],
            project_role,
            versions: [...versions],
            loading: false
          } 
        })
      })
    }
  }

  function capital_letter(str, option) {
    str = str.split("_");

    for (var i = 0, x = str.length; i < x; i++) {
      const cassie = (option === 'upper')? str[i][0].toUpperCase(): str[i][0].toLowerCase()
      str[i] = cassie + str[i].substr(1);
    }

    return str.join(" ");
  }

  const loadTable = (filter, tab, offset, projects) => {
    if (isNew) {
      if (filter.type !== 'Project') {
        let s = (context.projects[0].proj_name !== 'Loading. . .')? context.projects : projects
        const proj = s.filter(e => e.client_id == filter.id)

        if (proj.length !== 0) {
          dispatch({ type: 'SET_STATE', payload: { project: { id: proj[0].id, name: proj[0].proj_name }, points: [], filter } })
        } else if (filter.type === 'All') {
          dispatch({ type: 'SET_STATE', payload: { project: { id: s[0].id, name: s[0].proj_name }, points: [], filter } })
        }
      } else {
        dispatch({ type: 'SET_STATE', payload: { project: { id: filter.id, name: filter.name }, points: [], filter } })
      }
    } else {
      props.history.push('/feedbacks/Open')
    }
  }

  return (
    <Page title={(isNew? context.translation.add_new_feedback[context.locale] : 'Feedback #' + id)} components={<Filter activeTab={1} unsavedData={state.unsavedData} loadTable={loadTable} projects={context.projects} clients={context.clients} selectedFilter={context.selectedFilter} dispatch={context.dispatch} />} back>
      <FeedbackForm reducer={{ state, dispatch, initialEditedItem }} new={isNew}/>
      <Prompt
        when={isNew && state.unsavedData && !state.modal}
        message={context.translation.error_unsaved_changes[context.locale]}
      />
    </Page>
  )
})