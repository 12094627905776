import React, { useContext } from 'react'
import { Spinner } from 'reactstrap'
import Avatar from './Avatar'
import './style.css'
import { GlobalContext } from '../../contexts/GlobalContext'

const Loading = () => {
  const { translation, locale } = useContext(GlobalContext)

  return (
    <div className="loading-banner" style={{ position: 'absolute', right: 0, left: 0, top: 0, bottom: 0 }}>
        <Avatar />
        <div className="mb-2" >
          <Spinner style={{ width: '1rem', height: '1rem' }} type="grow" color="danger" className="ml-2" />
          <Spinner style={{ width: '1rem', height: '1rem' }} type="grow" color="danger" className="ml-2" />
          <Spinner style={{ width: '1rem', height: '1rem' }} type="grow" color="danger" className="ml-2" />
        </div>
        
        {translation.loading_resource[locale]}
    </div>
  )
}

export default Loading