import React, { useState, useEffect, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import { Empty } from '../../../common';
import moment from 'moment';

import { AuthContext } from '../../../../contexts/AuthContext';
import PointList from './PointList'

import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import { Icon } from 'antd';
import { UPDATE_PRIORITY } from '../../../../queries';
import { ApolloContext } from 'react-apollo';

export default React.memo(withRouter(({ reducer: { state, dispatch, points }, context, history }) => {
  const { client } = useContext(ApolloContext)
  const { user } = useContext(AuthContext)
  const [expand, setExpand] = useState([])
  const [ tableRows, setTableRows ] = useState([]);

  useEffect(() => {
    let expand = points.map(e => e.id)
    setExpand(expand)

    let _tableRows = points.filter(point => point.open_status != 0 || point.question_status != 0).map(point => point);
    _tableRows.sort((a, b) => a.order - b.order);

    setTableRows(_tableRows);
  }, [points])

  const DragHandle = sortableHandle(() => <span className='staff-drag-handle'><Icon type="menu" /></span>);

  const SortableContainer = sortableContainer(({children}) => {
    return <>{children}</>;
  });
  
  const SortableItem = sortableElement(({children, style}) => <tr style={style}>{children}</tr>);
  
  const onSortEnd = async ({oldIndex, newIndex}) => {
    if(oldIndex !== newIndex){
      let newSortOrder = arrayMove(tableRows, oldIndex, newIndex);
      setTableRows(newSortOrder);
  
      const team = newSortOrder.map((row, indx) => ({
        id: row.proj_team_primary_id,
        proj_team_id: row.proj_id,
        user_id: row.user_id,
        priority: indx
      }))
  
      await client.mutate({ mutation: UPDATE_PRIORITY, variables: { data: { team } } });
    }
  };

  const checkDragAccess = (info) => {
    const { id, role } = user;
    const row = info.row.original;

    if(['Super Administrator', 'Administrator', 'Project Leader'].includes(role)) return <DragHandle />;

    if(['Project Staff', 'Member'].includes(role)){
      if(row.user_id === id){
        return <DragHandle />;
      }
    }

    return <></>; // ['Feedback Provider', 'Client'] || row.user_id !== id
  }

  const columns = [{
    accessorKey: 'action',
    header: 'action',
    cell: info => checkDragAccess(info)
  },{
    accessorKey: 'clientname',
    header: 'clientname',
    cell: info => {
      const row = info.row.original;

      return (
        <div style={{ paddingLeft: '25px' }}>
          <span href="#" className="link-hovers" onClick={() => history.push(`/dashboard/analysis/${row.user_id}/client/${row.client_id}`)}>
            {info.getValue()}
          </span>
        </div>
      );
    },
  }, {
    accessorKey: 'proj_name',
    header: 'proj_name',
    cell: info => {
      const row = info.row.original;

      return (
        <span href="#" className="link-hovers" onClick={() => history.push(`/dashboard/analysis/${row.user_id}/client/${row.client_id}`)}>
          {info.getValue()}
        </span>
      );
    },
  }, {
    accessorKey: 'total_time_estimate',
    header: 'total_time_estimate',
    cell: info => {
      const row = info.row.original;
      
      // Check if null and convert the time_b to hours format
      const time_a_converted = row.total_time_estimate == undefined ? '00:00' : row.total_time_estimate;
      const time_b = row.total_hours_used_individual == undefined ? '00:00' : moment.duration(parseInt(row.total_hours_used_individual), 'seconds').format("HH:mm") || '00:00';
      let time_b_converted = 0;

      if (!time_b.includes(":")) {
        time_b_converted = "00:" + time_b;
      } else {
        time_b_converted = time_b;
      }

      // Split the times and convert to minutes
      const [hours1, minutes1] = time_a_converted.split(":").map(Number);
      const [hours2, minutes2] = time_b_converted.split(":").map(Number);
      
      // Calculate the total minutes for each time
      const totalMinutes1 = hours1 * 60 + minutes1;
      const totalMinutes2 = hours2 * 60 + minutes2;
      
      // Calculate the time difference in minutes
      const timeDifferenceMinutes = totalMinutes1 - totalMinutes2;

      // Take the absolute value of the time difference for calculations
      const absTimeDifferenceMinutes = Math.abs(timeDifferenceMinutes);

      // Convert the time difference back to "HH:MM" format
      const hoursDiff = Math.floor(absTimeDifferenceMinutes / 60);
      const minutesDiff = absTimeDifferenceMinutes % 60;

      // Determine the sign of the time difference
      const sign = timeDifferenceMinutes < 0 ? '-' : '';

      // Format the time difference in "HH:mm" format with the sign
      const timeDifference = `${sign}${Math.abs(hoursDiff).toString().padStart(2, "0")}:${minutesDiff.toString().padStart(2, "0")}`;
      
      return <div style={{ textAlign: 'center' }}>{timeDifference}</div>
    },
  }, {
    accessorKey: 'total_hours_used_individual',
    header: 'total_hours_used_individual',
    cell: info => {
      const total = moment.duration(parseInt(info.getValue()), 'seconds').format("HH:mm", { useGrouping: false }) || '00:00'
      const total_hours_used = total.length == 2 ? '00:' + total : total;

      return <div style={{ textAlign: 'center' }}>{total_hours_used}</div>
    },
  }, {
    accessorKey: 'total_hours_used',
    header: 'total_hours_used',
    cell: info => {
      const total = moment.duration(parseInt(info.getValue()), 'seconds').format("HH:mm", { useGrouping: false }) || '00:00'
      const total_hours_used = total != '00' ? total : total.length == 2 ? '00:' + total : total

      return <div style={{ textAlign: 'center' }}>{total_hours_used}</div>
    },
  }, {
    accessorKey: 'open_status',
    header: 'open_status',
    cell: info => {
      const row = info.row.original;
      const cell = info.getValue();

      const reducer = {
        state,
        dispatch
      }

      return (
        <div style={{ textAlign: 'center' }}>
          <PointList data={{ cell, row, old_count: row.old_open, status: 'Open', table: '1' }} reducer={reducer} context={context} />
        </div>
      )
    },
  }, {
    accessorKey: 'question_status',
    header: 'question_status',
    cell: info => {
      const row = info.row.original;
      const cell = info.getValue();

      const reducer = {
        state,
        dispatch
      }

      return (
        <div style={{ textAlign: 'center' }}>
          <PointList data={{ cell, row, old_count: row.old_questions, status: 'Question', table: '1' }} reducer={reducer} context={context} />
        </div>
      )
    },
  }]

  const table = useReactTable({
    columns,
    data: tableRows,
    getCoreRowModel: getCoreRowModel()
  })

  return (
    // helperClass is the element Class/Style used while dragging an element
    <SortableContainer onSortEnd={onSortEnd} helperClass="sortableTableRowHelper" useDragHandle>
      <div >
        <table className='table-no-border'>
          <colgroup>
            <col style={{ width: '1%' }} />
            <col style={{ width: '24%' }} />
            <col />
            <col />
            <col />
            <col />
            <col />
          </colgroup>
          <tbody>
            {
              table.getRowModel().rows.length ?

                table.getRowModel().rows.map((row, indx) => {
                  const rowData = row.original;
                  const style = {};

                  if (rowData.old_open > 0 || rowData.old_questions > 0) {
                    style.backgroundColor = '#cc2a35';
                    style.color = '#fff'
                  }
                  
                  return (
                    <SortableItem key={indx} index={indx} style={style}>
                        {row.getVisibleCells().map(cell => (
                          <td key={cell.id}>
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </td>
                        ))}
                    </SortableItem>
                  )
                })

                : <tr><td> <Empty imgStyle={{ height: '25px' }} hideLabel /> </td></tr>
            }
          </tbody>
        </table>
      </div>
    </SortableContainer>
  )
}), (prevProps, nextProps) => !(prevProps.context.clients.length != nextProps.context.clients.length || prevProps.context.temp_projects.length != nextProps.context.temp_projects.length || prevProps.reducer.state.editedItem != nextProps.reducer.state.editedItem || prevProps.reducer.state.versions != nextProps.reducer.state.versions || prevProps.points != nextProps.points));