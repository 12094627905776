import React, { useContext, useReducer, createContext, useEffect } from 'react';
import GlobalReducer, { actionType } from '../reducers/GlobalReducer';

/* Import Apollo and Queries */
import { ApolloContext } from 'react-apollo';
import { PROJECT_CLIENT_QUERY } from '../queries';

/* Import Language File */
import translation from '../lang/data.json';

import { AuthProvider } from './AuthContext';

const initialState = {
  uri:
    process.env.NODE_ENV === 'development'
      ? 'http://localhost:4001/upload'
      : '/upload',
  // uri: 'http://localhost:4001/upload',
  uri2:
    process.env.NODE_ENV === 'development'
      ? 'http://localhost:4001'
      : '',
  translation: translation,
  locale:
    localStorage.getItem('locale') === null ||
    localStorage.getItem('locale') === undefined
      ? 'de'
      : localStorage.getItem('locale'),
  isOpen:
    localStorage.getItem('expand-sidebar') === null ||
    localStorage.getItem('expand-sidebar') === undefined
      ? true
      : localStorage.getItem('expand-sidebar') == 'true'
      ? true
      : false,
  tableLoading: false,
  uploadFiles: false,
  uploadDone: false,
  isDashboardWorkplace: false,
  fetchingPoints: false,
  hasMore: false,
  goToStep: null,
  modal1: false,
  isTourOpen: true,
  files: [],
  fileName: '',
  point_no: '',
  temp_projects: [],
  status: {
    Draft: -1,
    Postpone: -1,
    Open: -1,
    Question: -1,
    For_Testing: -1,
    Closed: -1,
    Archive: -1,
  },
  filteredCategory: null,
  projects: [
    {
      id: 0,
      proj_name: 'Loading. . .',
    },
  ],
  clients: [
    {
      id: 0,
      clientname: 'Loading. . .',
    },
  ],
  selectedFilter:
    localStorage.getItem('selectedFilter') === null ||
    localStorage.getItem('selectedFilter') === undefined ||
    Object.entries(JSON.parse(localStorage.getItem('selectedFilter')))
      .length === 0
      ? {
          id: 0,
          name: 'Loading',
          type: 'All',
          clientname: 'Loading',
          // name: 'All',
          // type: 'All',
          // clientname: 'All'
        }
      : JSON.parse(localStorage.getItem('selectedFilter')),
  open: false,
  message: '',
  feature_modal: false,
  statusCount: {},
  loc: '',
  currentStep: 0,
  showFab: false,
  showFab2: false,
  showFab3: false,
  fabAction: () => {
    console.log('testing');
  },
  fabAction3: () => {
    console.log('testing');
  },
  fabActionAdd: () => {
    console.log('testing');
  },
  disableButton: false,
  tooltipTittle: '',
  isLoading: true,
  showSidebar: true,
  disableInteraction: false
};

export const GlobalContext = createContext();

export const GlobalProvider = (props) => {
  const context = useContext(ApolloContext);
  const [globalState, dispatch] = useReducer(GlobalReducer, initialState);

  const setLocale = (locale) => {
    dispatch({ type: actionType.SET_LOCALE, locale });
  };

  return (
    <GlobalContext.Provider
      value={{
        ...globalState,
        setLocale,
        dispatch,
      }}
    >
      <AuthProvider>{props.children}</AuthProvider>
    </GlobalContext.Provider>
  );
};
