import React, { useContext, useState, useEffect } from 'react';
import { Row, Col, FormGroup, Label, Input, FormFeedback, FormText } from 'reactstrap';
import { Modal, Toast } from '../../common';
import { message } from 'antd'
import ModalCreate, { confirmCreate } from './UserModalCreate'

import { ApolloContext } from 'react-apollo';
import { GlobalContext } from '../../../contexts/GlobalContext'
import { CREATE_USER, UPDATE_USER, DELETE_USER } from '../../../queries';

const changePasswordBody = (selectedUser, errors, pass, setPass, c_pass, setCPass, translation, locale) => (
  <>
    <Row>
      <Col xs={6}><strong>Name:</strong> <br />{selectedUser.name}</Col>
      <Col xs={6}><strong>Email:</strong> <br />{selectedUser.email}</Col>
    </Row>
    <hr />
    <FormGroup style={{ position: 'relative' }}>
      <Label for="new_password">{translation.new_password[locale]}</Label>
      <Input name="new_password" type={pass} id="new_password" invalid={errors.password.invalid} valid={errors.password.valid} pattern='(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}' title={translation.password_requirements[locale]} required bsSize="sm" />
      <FormFeedback>{errors.password.message}</FormFeedback>
      <FormText>{translation.password_requirements[locale]}</FormText>
      <i onClick={() => setPass(pass == 'password' ? 'text': 'password')} className={`fas ${pass == 'password'? 'fa-eye': 'fa-eye-slash'} view-password`} style={{ position: 'absolute', top: '34px', right: '10px', color: '#007BFF', fontSize: '14px' }}></i>
    </FormGroup>
    <FormGroup style={{ position: 'relative' }}>
      <Label for="confirm_password">{translation.confirm_new_password[locale]}</Label>
      <Input type={c_pass} id="confirm_password" required bsSize="sm" />
      <i onClick={() => setCPass(c_pass == 'password' ? 'text': 'password')} className={`fas ${c_pass == 'password'? 'fa-eye': 'fa-eye-slash'} view-password`} style={{ position: 'absolute', top: '34px', right: '10px', color: '#007BFF', fontSize: '14px' }}></i>
    </FormGroup>
  </>
)

export default (props => {
  const { isGenerate, setIsGenerate, statusCount, setStatusCount, handleConfirm, setSelected, setSelectedUser, setModalType, form, clients, setForm, setUsers, setErrors, setType, modalType, setModal, modal, users, selectedUser, errors, selected, initialErrorState, type, items } = props.payload
  const [isChangePass, toggleChangePass] = useState(false);
  const context = useContext(ApolloContext);
  const { translation, locale, dispatch } = useContext(GlobalContext);
  const [pass, setPass] = useState('password')
  const [c_pass, setCPass] = useState('password')

  useEffect(() => {
    if (modal) {
      toggleChangePass(false)
    }

    dispatch({ type: 'SET_STATE', payload: { isInteraction: modal } })
  }, [modal])

  useEffect(() => {
    return () => {
      dispatch({ type: 'SET_STATE', payload: { isInteraction: false, modal1: false } })
    }
  }, [])

  const updateFormState = (event, object_name) => {
    setForm({ ...form, [object_name]: event.target.value });
  }

  /* MODAL OK METHOD */
  const confirmDelete = async (e) => {
    e.preventDefault();
    selected.sort((a, b) => a - b);

    const hide = message.loading(translation.saving_changes[locale], 0)
    try{
      const { data } = await context.client.mutate({ mutation: DELETE_USER, variables: { ids: selected } });
      setUsers(users.filter(x => !selected.includes(x.id)));
      setSelected([])
      
      hide()
      message.success(translation.msg_user_deleted[locale])
      // Toast.showToast('success', translation.msg_user_deleted[context.locale])
    } catch (error) {
      const err = error.toString().split(':')
      console.log(err);
      hide()
      message.error(`${err[3]}`)
      // Toast.showToast('error', `${err[3]}`)
    }
    
    return true;
  }

  const confirmChangePassword = async (e) => {
    e.preventDefault();
    const password = document.getElementById('new_password').value
    const c_password = document.getElementById('confirm_password').value
    const hide = message.loading(translation.saving_changes[locale], 0)
    if (password === c_password) {
      setErrors(initialErrorState)

      try{
        const formData = new FormData(document.forms.namedItem('modal-form'))
        const { data } = await context.client.mutate({ mutation: UPDATE_USER, variables: { data: { password: password }, id: parseInt(selectedUser.id) } });
        
        hide()
        message.success(translation.msg_user_updated[locale])
        // Toast.showToast('success', translation.msg_user_updated[locale])
      } catch (error) {
        const err = error.toString().split(':')
        console.log(err);
        hide()
        message.error(`${err[3]}`)
        // Toast.showToast('error', `${err[3]}`)
      }
      return true;
    } else {
      setErrors({ ...initialErrorState, password: { invalid: true, valid: false, message: translation.password_no_match[locale] } })
      return false;
    }
  }

  
  const modalSettings = {
    method: {
      create: confirmCreate({ forms: form, modal, setType, setModal, setSelectedUser, handleConfirm, isGenerate, setModalType, isChangePass, toggleChangePass, users, setUsers, context, setErrors, setForm, errors, type, initialErrorState, statusCount, setStatusCount, translation, locale, items }),
      delete: confirmDelete,
      changePassword: confirmChangePassword,
    },
    title: {
      create: translation.add_user[locale],
      delete: translation.delete_user[locale],
      changePassword: translation.change_password[locale]
    },
    body: {
      create: (<ModalCreate payload={{ form, errors, type, isChangePass, toggleChangePass, updateFormState, items, clients, setType, setIsGenerate, isGenerate, state: {} }} />),
      delete: translation.msg_delete_user[locale],
      changePassword: changePasswordBody(selectedUser, errors, pass, setPass, c_pass, setCPass, translation, locale)
    }
  }
  /* MODAL OK METHOD END */

  return (
    <Modal location={9} saveLabel={(modalType === 'delete' && translation.proceed[locale])} title={modalSettings.title[modalType]} modal={modal} setModal={setModal} accessFunction={modalSettings.method[modalType]}>{modalSettings.body[modalType]}</Modal>
  )
})