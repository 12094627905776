import React, { useContext, useEffect } from 'react'
import { AuthContext } from './contexts/AuthContext'
import {
   Route,
   Redirect
 } from "react-router-dom";

import { ComponentNotFound } from './components/common';
import { ApolloContext } from 'react-apollo';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { client } = useContext(ApolloContext); 
  const Auth = useContext(AuthContext)

  useEffect(() => {
    // client.clearStore()
  }, [])

  function authRoute(roles, props) {
    if (roles.includes(Auth.user.role)) {
      return <Component {...props} />
    } else {
      return <ComponentNotFound />
    }
  }

  return (
    <Route
      {...rest}

      render = { props => {
        if (Auth.isOnline) {
          if (rest.path === '/users' ) {
            return authRoute(['Administrator'], props)
          } else if (rest.path === '/dashboard/analysis/:staff?/:type?/:id?' ) {
            return authRoute(['Member', 'Administrator'], props)
          } else if (rest.path === '/clients' ) {
            return authRoute(['Member', 'Administrator'], props)
          } else if (rest.path === '/time_log/summary' || rest.path === '/time_log/record') {
            return authRoute(['Member', 'Administrator'], props)
          } else if (rest.path === '/dashboard/summary' ) {
            return authRoute(['Member', 'Administrator'], props)
          } else {
            return <Component {...props} />
          }
        } else {
          return <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location }
            }}
          />
        }
      }
        // || rest.path === '/clients'
      }
    />
  )
}

export default PrivateRoute
