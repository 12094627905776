import React, { useEffect } from 'react';
import { Toast } from './index.js';
import { message } from 'antd';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CloseIcon from '@material-ui/icons/Close';
import { FilePond } from 'react-filepond';

const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: 345,
    position: 'fixed',
    right: '22px',
    bottom: 0,
    zIndex: 2,
    boxShadow: '0 2px 8px 0 rgba(0,0,0,.2)',
  },
  header: {
    backgroundColor: '#323232',
    color: '#F5F5F5',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

let files = [];
let metadata_points = [];
let metadata_feedback = [];
export default (props) => {
  let uploads = -1;
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(true);
  const [no_files, set_no_files] = React.useState(0);

  function handleExpandClick() {
    setExpanded(!expanded);
  }

  function handleClose() {
    setExpanded(false);
    setTimeout(() => {
      props.dispatch({
        type: 'SET_STATE',
        payload: { uploadFiles: false, files: [], uploadDone: false },
      });
    }, 400);
  }

  // useEffect(() => {
  //   if (uploads === 0) {
  //     props.dispatch({ type: 'SET_STATE', payload: { uploadFiles: false } })
  //   }
  // }, [uploads])

  return (
    <Card
      className={classes.card}
      id="notificationi"
      style={{ display: props.show ? 'initial' : 'none' }}
    >
      <CardHeader
        action={
          <>
            <IconButton
              className={clsx(classes.expand, {
                [classes.expandOpen]: expanded,
              })}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
              id="show-more-upload"
            >
              <ExpandMoreIcon />
            </IconButton>
            <IconButton onClick={handleClose} aria-label="Exit">
              <CloseIcon />
            </IconButton>
          </>
        }
        subheader={
          no_files !== 0 && props.files.length !== 0
            ? `Uploading ${no_files} item`
            : `${props.files.length} upload complete`
        }
        className={classes.header}
      />
      <Collapse in={expanded} timeout="auto">
        <CardContent>
          <FilePond
            allowImagePreview={false}
            allowMultiple={true}
            server={{
              url: props.url,
              revert: {
                url: '',
                headers: { 'Content-Type': 'application/json' },
              },
            }}
            files={props.files.map((e, i) => {
              metadata_points[i] = e.getMetadata('points');
              metadata_feedback[i] = e.getMetadata('feedback');
              return e.file;
            })}
            onupdatefiles={(fileItems) => {
              files = fileItems.map((fileItem, i) => {
                fileItem.setMetadata('points', metadata_points[i]);
                fileItem.setMetadata('feedback', metadata_feedback[i]);
                return fileItem;
              });
              props.dispatch({ type: 'SET_STATE', payload: { files } });
            }}
            onprocessfilestart={(file) => {
              uploads = uploads === -1 ? 1 : 1 + uploads;
            }}
            onprocessfile={(error, file) => {
              --uploads;
              if (uploads === 0) {
                props.dispatch({
                  type: 'SET_STATE',
                  payload: { uploadDone: true },
                });
              }
            }}
            allowDrop={false}
            allowPaste={false}
            allowRevert={false}
          />
        </CardContent>
      </Collapse>
    </Card>
  );
};
