import React, { useContext } from 'react'
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import { GlobalContext } from '../../../../contexts/GlobalContext';

export default ({ show, cancel, save, disable, hide }) => {
  const { translation, locale } = useContext(GlobalContext)
  return (
    <span style={{display: (show)? 'initial': 'none' }}>
      <Tooltip title={translation.save[locale]}  placement="top">
        <Button disabled={disable} onClick={() => save()} size="small" style={{ color: 'green', minWidth: 0, marginBottom: '3px', marginLeft: '5px', display: hide? 'none': 'inline-block' }}> 
          <i className="fas fa-check"></i>
        </Button>
      </Tooltip>
      <Tooltip title={translation.cancel[locale]}  placement="top">
        <Button size="small" style={{ color: hide? '#007BFF': 'red', minWidth: 0, marginBottom: '3px' }} onClick={() => cancel()}>
          {
            hide? 
              <i className="fas fa-arrow-left"></i>
            :
              <i className="fas fa-times"></i>

          }
        </Button>
      </Tooltip>
    </span>
  )
}