import React, { useContext, useEffect } from 'react';
import { useSpring, animated } from 'react-spring'
import { Row, Col, Form, FormGroup, Label, Input, FormFeedback, Spinner } from 'reactstrap';
import SpringStyle from './SpringStyle'
import Button from '@material-ui/core/Button';
import { message } from 'antd'

/* Import Context Provider */
import { GlobalContext } from '../../../contexts/GlobalContext';
import { AuthContext } from '../../../contexts/AuthContext';
import { ApolloContext } from 'react-apollo';

import { Toast } from '../../common';
import { UPDATE_ACCOUNT_SETTINGS } from '../../../queries';

const SettingsAccount = props => {
  const context = useContext(GlobalContext)
  const { user, dispatch: authDispatch } = useContext(AuthContext)
  const { client } = useContext(ApolloContext)
  const { state, dispatch } = props.reducer

  useEffect(() => {
    dispatch({ type: 'SET_STATE', payload: { first_name: user.first_name, last_name: user.last_name, email: user.email } })
  }, [])

  const saveChanges = async () => {
    try {
      dispatch({ type: 'SET_STATE', payload: { isLoading: true, errors: {...state.defaultErrors} } })
      const { data } = await client.mutate({ mutation: UPDATE_ACCOUNT_SETTINGS, variables: { data: { first_name: state.first_name, last_name: state.last_name, email: state.email, type: 'account', lang: context.locale } } })
  
      if (data.updateAccountInfo.status === 401) {
        dispatch({ type: 'SET_STATE', payload: { errors: { ...state.errors, email: { invalid: true, message: context.translation.email_exists[context.locale] } } } })
      } else if (data.updateAccountInfo.status === 200) {
        localStorage.setItem('techVoiceToken', data.updateAccountInfo.token)
        authDispatch({ type: 'SET_STATE', payload: { user: data.updateAccountInfo.user } })
        message.success(context.translation.reset_pass_email_msg[context.locale])
        // Toast.showToast('success', context.translation.reset_pass_email_msg[context.locale])
      }
    } catch (error) {
      console.log(error)
    }
    dispatch({ type: 'SET_STATE', payload: { isLoading: false } })
  }

  return (
    <animated.div style={useSpring(SpringStyle)}>
      <h5 style={{ paddingBottom: '0.41rem', borderBottom: '1px solid #e3e3e3', width: '100%' }}>{ context.translation.account_settings[context.locale] }</h5><br />
      <Row>
        <Col xs={6}>
          <FormGroup>
            <Label for="first_name">{context.translation.first_name[context.locale]}</Label>
            <Input name="first_name" id="first_name" invalid={state.errors.first_name.invalid} value={state.first_name} onChange={(e) => { dispatch({ type: 'SET_STATE', payload: { first_name: e.target.value } })}} bsSize="sm" required />
            <FormFeedback>{state.errors.first_name.message}</FormFeedback>
          </FormGroup>
        </Col>

        <Col xs={6}>
          <FormGroup>
            <Label for="last_name">{context.translation.last_name[context.locale]}</Label>
            <Input name="last_name" id="last_name" invalid={state.errors.last_name.invalid} value={state.last_name} onChange={(e) => { dispatch({ type: 'SET_STATE', payload: { last_name: e.target.value } })}} bsSize="sm" required />
            <FormFeedback>{state.errors.last_name.message}</FormFeedback>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <FormGroup>
            <Label for="email">{context.translation.username[context.locale]}</Label>
            <Input name="email" id="email" invalid={state.errors.email.invalid} value={state.email} onChange={(e) => { dispatch({ type: 'SET_STATE', payload: { email: e.target.value } })}} bsSize="sm" required />
            <FormFeedback>{state.errors.email.message}</FormFeedback>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Button disabled={state.isLoading} variant="contained" color="primary" style={{ background: (state.isLoading)? 'rgba(41,72,255,0.6)': 'linear-gradient(-45deg, #396afc, #2948ff)' }} onClick={() => saveChanges()}><Spinner color="light" size="sm" style={{ marginRight: '10px', display: (state.isLoading)? 'inline-block' : 'none' }} /> <span>{context.translation.update_account[context.locale]}</span></Button>
        </Col>
      </Row>
    </animated.div>
  );
}
 
export default SettingsAccount;