import React, { useContext } from 'react'
import Tooltip from '@material-ui/core/Tooltip';
import Moment from 'react-moment';
import moment from 'moment';
import { Popover, Avatar, message, Icon } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faUserCircle } from '@fortawesome/free-regular-svg-icons';

const badgeColorStatus = [
  { name: 'Open', border: '1px solid #CF0F22', backgroundColor: '#FAE6E8', color: '#CF0F22', fontWeight: '400', borderRadius: '2px' },
  { name: 'Question', border: '1px solid #219FC7', backgroundColor: '#E8F5F9', color: '#219FC7', fontWeight: '400', borderRadius: '2px' },
  { name: 'For Testing', border: '1px solid #5B3694', backgroundColor: '#EEEAF4', color: '#5B3694', fontWeight: '400', borderRadius: '2px' },
  { name: 'Postpone', border: '1px solid #E43988', backgroundColor: '#FDEBF3', color: '#E43988', fontWeight: '400', borderRadius: '2px' },
  { name: 'Closed', border: '1px solid #53AD57', backgroundColor: '#EDF7EE', color: '#53AD57', fontWeight: '400', borderRadius: '2px' },
]

const badgeColorPriority = [
  { name: 'High', border: '1px solid #CF0F22', backgroundColor: '#FAE6E8', color: '#CF0F22', fontWeight: '400', borderRadius: '2px' },
  { name: 'Mid', border: '1px solid #FF8B00', backgroundColor: '#FFF3E5', color: '#FF8B00', fontWeight: '400', borderRadius: '2px' },
  { name: 'Low', border: '1px solid #FFC400', backgroundColor: '#FFF9E5', color: '#FFC400', fontWeight: '400', borderRadius: '2px' },
]

const Users = ({ team }) => {
  let members = (<></>), teams = ['', '', '']
  team.forEach(e => {
    // console.log(e)
    if (e.project_role === 'Project Leader') {
      teams[0] += (e.name + '\n')
    } else if (e.project_role === 'Project Staff') {
      teams[1] += (e.name + '\n')
    } else { 
      teams[2] += (e.name + '\n')
    }
  })
  if (teams[0] !== '' || teams[1] !== '' || teams[2] !== '') {
    members = teams.map((users, i) => {
      if (!!users[i])
        return (
        <Tooltip key={i} title={<div style={{whiteSpace: 'pre-line'}}>{users}</div>}>
          <Avatar>{(i === 0)? 'PL': (i === 1)? 'PS' : 'FP' }</Avatar>
        </Tooltip>
        )
    })
  } 

  return members
}


export default ({ data: { point, index, hideCreator }, context, clickEvent }) => {
  const date = new Date(point.date).toISOString().substring(0,10);
  const customStyle = moment().isAfter(moment(date, 'YYYY-MM-DD').add('14', 'days')) || moment(date, 'YYYY-MM-DD').add('14', 'days').isSame(moment()) ?
                      { color: '#CC2A35'} : {}
  return (
    <div className="content-card" onClick={() => clickEvent(point, index)}>
      {/* Top section */}
      <div className="top">
        <div className="left">
          <span className={`status badge badge-sm ${'TEMPORARY' === 'Draft' && "bg-light"}`} style={{ ...badgeColorStatus.find(e => e.name === point.status) }}>{context.translation.status[point.status.toLowerCase().replace(' ', '_')][context.locale]}</span>
          <span className={`priority badge badge-sm`} style={{ ...badgeColorPriority.find(e => e.name === point.priority) }}>{context.translation[point.priority.toLowerCase()][context.locale]}</span>
        </div>

        <div className="right">
          <small style={{ marginRight: '5px' }}>
            <b>
              { point.hours_worked + ' / ' + (point.time_estimate == 'N/A' ? '00:00' : point.time_estimate) }
            </b>
          </small>
          <b style={customStyle}>#{++index}</b>
        </div>
      </div>

      {/* Body */}
      <div className="body">
        <h6 className="title">{ point.title }</h6>
        <pre className="pre-instructions">{ point.instructions }</pre>
      </div>

      {/* Footer */}
      <div className="footer">
        <div className="left">
          <div className="inner-left">
            {
              !hideCreator && 
                <small><FontAwesomeIcon icon={faUserCircle} style={{ verticalAlign: 'middle' }}/> { point.creator }</small>
            }
            <small style={customStyle}><FontAwesomeIcon icon={faClock} style={{ verticalAlign: 'middle' }}/> <Moment style={{ verticalAlign: 'middle' }} locale={context.locale} interval={60000} fromNow>{ point.date }</Moment></small>
          </div>
          {
            point.deadline !== 'N/A' && 
              <span className={`deadline badge badge-sm`}><span className="deadline-label">{ context.translation.deadline[context.locale] }</span> <span className="deadline-moment"><Moment format="ll" locale={context.locale} >{ point.deadlineDisplay }</Moment></span></span>
          }
        </div>

        <div className="right">
          <Users team={point.team}/>
        </div>
      </div>
    </div>
  )
}