import React from 'react'

export default (props) => {
  
  const { children } = props
  
   return (
      <div className="shadow-sm bg-white rounded" style={{ padding: '0.85rem 1rem', position: 'relative', ...props.style }}>
        { children }
      </div>
   )
}