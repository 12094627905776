import React from 'react'
import logo from '../../brand/ec.jpg'

const Avatar = () => {
   return (
      <div className="d-flex justify-content-center my-3">
         <img src={logo} height="80" width="90" alt="logo" />
      </div>
   )
}

export default Avatar
