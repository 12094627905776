
const LoginReducer = (state, action) => {

   switch (action.type) {

      case 'INPUT': 

         return {

            ...state,
            [action.field]: action.value
         }

      case 'LOGIN': 

         return {

            ...state,
            inValidForm: false,
            formMessage: '',
            formPassMessage: '',
            isLoading: true
         }

      case 'SET_ERROR':
        return {
          ...state,
          isError: action.isError,
          errorMessage: action.message,
          isLoading: false
        }

      case 'FAILED_PASSWORD': 

         return {

            ...state,
            inValidForm: true,
            password: '',
            formPassMessage: action.message,
            isLoading: false
         }

      case 'FAILED_USERNAME': 

         return {

            ...state,
            inValidForm: true,
            password: '',
            formMessage: action.message,
            isLoading: false
         }

      case 'USENAME_EMPTY': 

         return {

            ...state,
            inValidForm: true,
            password: '',
            formMessage: action.message,
            isLoading: false
         }
      case 'PASSWORD_EMPTY': 

         return {

            ...state,
            inValidForm: true,
            password: '',
            formPassMessage: action.message,
            isLoading: false
         }

      case 'SET_STATE': 
         return {
         ...state,
         ...action.payload
         }

      default:
         return state
   }
}

export default LoginReducer