import React, { useState, useEffect, useContext } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import moment from 'moment';

import PointList from './PointList'

export default ({ reducer }) => {
  const { state, dispatch, versions } = reducer
  const [expand, setExpand] = useState([])

  useEffect(() => {
    let expand = versions.map(e => e.id)
    setExpand(expand)
  }, [versions])

  // useEffect

  /* TABLE SETTINGS */
  const columns = [{
    dataField: 'id',
    text: 'id',
    hidden: true,
  }, {
    dataField: 'version_name',
    text: 'Version',
    // headerStyle: (colum, colIndex) => ({ width: '80px' }),
    style: {
      // fontStyle: 'italic',
      // paddingBottom: '3px',
      width: 'inherit'      
    },
    sort: true
  }];

  const handleOnExpand = (row, isExpand, rowIndex, e) => {
    if (isExpand) 
      setExpand([...expand])
    else 
      setExpand([...expand])
  }


  const expandRow = {
    renderer: row => <PointList reducer={{ state, dispatch, points: row.points.sort(function(a, b) {
      a = new Date(a.date);
      b = new Date(b.date);
      return a>b ? -1 : a<b ? 1 : 0;
    }).map(e => {
    // const instructions = (<><span className="badge badge-sm bg-light border">{e.point_nr}</span> {e.instructions}</>)
    return {
      ...e,
      // instructions,
      clientname: row.clientname,
      date: e.date,
      deadlineMoment: (e.deadline !== 'N/A')? moment(e.deadline): moment(),
      proj_id: row.proj_id,
      proj_name: row.proj_name
    }
  }) }} />,
    showExpandColumn: true,
    expanded: expand,
    onExpand: handleOnExpand,
    expandColumnPosition: 'right',
    expandColumnRenderer: (column) => {
    //   if (column.expanded) {
    //     return (
    //       <span className="points"><ExpandMoreIcon /></span>
    //     );
    //   }
    //   return (
    //     <span className="points"><ExpandLessIcon /></span>
    //   );
    }
  };
  
  return (
    <div className="versions-table">
      <BootstrapTable
        keyField="id"
        bootstrap4
        columns = { columns }
        data = { versions }
        bordered = { false }
        expandRow = { expandRow }
      />
    </div>
  )
};