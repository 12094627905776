import React, { useContext } from 'react';
import Moment from 'react-moment';
import BootstrapTable from 'react-bootstrap-table-next';
import { Empty, TimeApprove, Users } from '../../common';
import { GlobalContext } from '../../../contexts/GlobalContext';
import { AuthContext } from '../../../contexts/AuthContext';

const badgeColorStatus = [
	{
		name: 'Open',
		border: '1px solid #CF0F22',
		backgroundColor: '#FAE6E8',
		color: '#CF0F22',
		fontWeight: '400',
		borderRadius: '2px',
	},
	{
		name: 'Question',
		border: '1px solid #219FC7',
		backgroundColor: '#E8F5F9',
		color: '#219FC7',
		fontWeight: '400',
		borderRadius: '2px',
	},
	{
		name: 'For Testing',
		border: '1px solid #5B3694',
		backgroundColor: '#EEEAF4',
		color: '#5B3694',
		fontWeight: '400',
		borderRadius: '2px',
	},
	{
		name: 'Postpone',
		border: '1px solid #E43988',
		backgroundColor: '#FDEBF3',
		color: '#E43988',
		fontWeight: '400',
		borderRadius: '2px',
	},
	{
		name: 'Closed',
		border: '1px solid #53AD57',
		backgroundColor: '#EDF7EE',
		color: '#53AD57',
		fontWeight: '400',
		borderRadius: '2px',
	},
];
const badgeColorPriority = [
	{
		name: 'High',
		border: '1px solid #CF0F22',
		backgroundColor: '#FAE6E8',
		color: '#CF0F22',
		fontWeight: '400',
		borderRadius: '2px',
	},
	{
		name: 'Mid',
		border: '1px solid #FF8B00',
		backgroundColor: '#FFF3E5',
		color: '#FF8B00',
		fontWeight: '400',
		borderRadius: '2px',
	},
	{
		name: 'Low',
		border: '1px solid #FFC400',
		backgroundColor: '#FFF9E5',
		color: '#FFC400',
		fontWeight: '400',
		borderRadius: '2px',
	},
];

export default ({ reducer }) => {
	const { state, dispatch, points } = reducer;
	const context = useContext(GlobalContext);
	const { user } = useContext(AuthContext);

	// useEffect(() => {
	//   console.log(points)
	// }, [points ])

	/* TABLE SETTINGS */
	const selectOptions = {
		Open: 'Open',
		Question: 'Question',
		'For Testing': 'For Testing',
		Closed: 'Closed',
	};

	const columns = [
		{
			dataField: 'point_nr',
			text: 'id',
			headerStyle: (colum, colIndex) => ({ width: '45px' }),
		},
		{
			dataField: 'instructions',
			text: context.translation.instruction[context.locale],
			headerStyle: (colum, colIndex) => ({ width: '40%' }),
		},
		{
			dataField: 'status',
			text: 'Status',
			formatter: (cell, row, rowIndex, formatExtraData) => (
				<span
					className={`badge badge-sm ${cell === 'Draft' && 'bg-light'}`}
					style={{ ...badgeColorStatus.find((e) => e.name === cell) }}
				>
					{
						context.translation.status[cell.toLowerCase().replace(' ', '_')][
							context.locale
						]
					}
				</span>
			),
			align: 'center',
			// headerStyle: (colum, colIndex) => ({ width: '25.03%'}),
			// sort: true,
			// formatter: cell => {
			//   return selectOptions[cell]
			// },
			// filter: selectFilter({
			//   options: selectOptions,
			//   placeholder: 'All',
			//   defaultValue: 'Open'
			// })
		},
		// {
		// 	dataField: 'priority',
		// 	text: context.translation.priority[context.locale],
		// 	formatter: (cell, row, rowIndex, formatExtraData) =>
		// 		cell && (
		// 			<span
		// 				className={`badge badge-sm`}
		// 				style={{ ...badgeColorPriority.find((e) => e.name === cell) }}
		// 			>
		// 				{context.translation[cell.toLowerCase()][context.locale]}
		// 			</span>
		// 		),
		// 	align: 'center',
		// },
		{
			dataField: 'team',
			text: context.translation.assignd_to[context.locale],
			formatter: (cell, row, rowIndex, formatExtraData) => (
				<Users team={cell} />
			),
			align: 'center',
			hidden: user.role === 'Client',
		},
		{
			dataField: 'time_estimate',
			text: context.translation.time_estimate[context.locale],
			formatter: (cell, row, rowIndex, formatExtraData) => (
				<TimeApprove
					time_approve={row.time_approve}
					time_estimate={row.time_estimate === 'N/A' ? '' : row.time_estimate}
				/>
			),
			align: 'center',
			hidden: user.role === 'Client',
		},
		{
			dataField: 'hours_worked',
			text: context.translation.hours_worked[context.locale],
			align: 'center',
			hidden: user.role === 'Client',
		},
		{
			dataField: 'date',
			text: context.translation.last_update[context.locale],
			formatter: (cell) => (
				<Moment interval={60000} locale={context.locale} fromNow>
					{cell}
				</Moment>
			),
			align: user.role === 'Client' ? 'left' : 'center',
		},
	];

	return (
		<div className='points-table'>
			<BootstrapTable
				keyField='id'
				bootstrap4
				columns={columns}
				data={points}
				bordered={false}
				// filter={ filterFactory() }
				noDataIndication={Empty}
				hover
				rowEvents={{
					onClick: async (e, row, rowIndex) => {
						const {
							last_change,
							feedback_id,
							title,
							creator_id,
							creator,
							comments,
							deadline,
							deadlineMoment,
							files,
							team: users,
							date,
							date_created,
							hours_worked,
							time_estimate,
							time_approve,
							version,
							version_name,
							instructions,
							priority,
							status,
							clientname,
						} = row;
						const members = context.projects.find(
							(e) => e.id == row.proj_id
						).team;
						let editItem =
							row.id > 0 ? { id: row.id, point_nr: row.point_nr } : {};

						let project_role = context.translation.none[context.locale];
						members.forEach((e) => {
							if (e.id == user.id) project_role = e.project_role;
						});

						const payload = {
							editedItem: {
								...state.editedItem,
								...editItem,
								feedback_id,
								comments,
								last_change,
								clientname,
								users,
								date,
								date_created,
								creator_id,
								creator,
								time_estimate: time_estimate === 'N/A' ? '' : time_estimate,
								hours_worked: hours_worked === 'N/A' ? '' : hours_worked,
								version:
									version === 'N/A'
										? state.versions[0].id +
										  '|' +
										  state.versions[0].version_name
										: version,
								version_name,
								time_approve,
								instructions,
								priority,
								status,
								deadline: deadlineMoment,
								deadlineDisplay: deadline,
								rowIndex,
							},
							files:
								row.id > 0
									? files.map((e) => ({
											source: `project_${row.proj_id}/feedback_${row.feedback_id}/point_${row.point_nr}_${row.id}/${e.filename}`,
											options: { type: 'local' },
											filename: e.filename,
									  }))
									: files,
						};
						dispatch({
							type: 'SET_STATE',
							payload: {
								id: row.feedback_id,
								title,
								modal: true,
								modalType: 'update',
								project: {
									id: row.proj_id,
									name: row.proj_name,
								},
								project_role,
								members,
								versions: state.feedbacks
									.find((e) => e.id == row.feedback_id)
									.versions.map((e) => ({
										id: e.id,
										version_name: e.version_name,
									})),
								...payload,
								rowData: { ...payload }, // For default data when cancel is clicked
								// gallery: files.map(e => ({ filename: e.filename, src: `${context.uri}?load=project_${state.project.id}/feedback_${state.id}/point_${row.point_nr}_${row.id}/${e.filename}`, width: 1, height: 1 }))
							},
						});
					},
				}}
			/>
		</div>
	);
};
