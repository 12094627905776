import React, { useContext, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { ListGroup, ListGroupItem, Badge } from "reactstrap";
import Skeleton from "react-loading-skeleton";

/* Import Context Provider */
import { GlobalContext } from "../../../contexts/GlobalContext";
import { AuthContext } from "../../../contexts/AuthContext";
import { ApolloContext } from "react-apollo";
import { FEEDBACKS_QUERY, FEEDBACK_COUNT_QUERY } from "../../../queries";

let match = false;
const scrollClass = {
  down: "sticky-scroll-down",
  up: "sticky-scroll-up",
};

const status = {
  open: -1,
  question: -1,
  for_testing: -1,
  draft: -1,
  postpone: -1,
  closed: -1,
};

export default withRouter(
  ({ state, dispatch, stats, history, initialStatusCount }) => {
    const { translation, locale, selectedFilter } = useContext(GlobalContext);
    const { client } = useContext(ApolloContext);
    const { user } = useContext(AuthContext);

    useEffect(() => {
      const st = capital_letter(stats, "lower");
      let index = -1;
      Object.entries(status).map((e, i) => {
        if (e[0] === st) index = i + 1;
      });

      if (index !== -1 || st === "all")
        document
          .getElementsByClassName("feedback-filter")
          [index !== -1 ? index : 0].classList.add(
            `active-${st}`
          ); // Default active status
      else {
        history.push("/feedbacks/Open");
        window.location.reload();
      }
      /* Count points on every status */
      // state.feedbacks.forEach(e => {
      //   e.points.for
      // })
    }, []);

    function capital_letter(str, option) {
      str = str.split(" ");

      for (var i = 0, x = str.length; i < x; i++) {
        const cassie =
          option === "upper"
            ? str[i][0].toUpperCase()
            : str[i][0].toLowerCase();
        str[i] = cassie + str[i].substr(1);
      }

      return str.join(" ");
    }

    const filter = async (id, status) => {
      const search = document.getElementById("search-bar-0").value;
      //const nameFilter = document.getElementsByClassName('filter text-filter form-control')[0].value

      /* Convert HTMLCoLLECTION to array */
      const el = [...document.getElementsByClassName("feedback-filter")];

      /* Add active class of clicked status */
      el.forEach((e, i) => {
        if (i === id) {
          e.classList.add(`active-${status}`); // Add active class
        } else {
          e.classList.remove(e.classList[3] || "none"); // Remove active class
        }
      });

      const stats = status
        .split("_")
        .map((e) => e.charAt(0).toUpperCase() + e.substring(1))
        .toString()
        .replace(",", " ");

      let queryFilter = { ...selectedFilter };

      // if(nameFilter) {
      // 	queryFilter = {...queryFilter, team: nameFilter}
      // }

      history.push("/feedbacks/" + capital_letter(status, "upper"));
      dispatch({
        type: "SET_STATE",
        payload: {
          feedbacks: [],
          count: 20,
          tableLoading: true,
          status: status.replace("_", " "),
          page: 1,
          feedbackPageLength: 0,
        },
      });

      client
        .query({ query: FEEDBACKS_QUERY(queryFilter, stats, 0, search) })
        .then(async ({ data }) => {
          dispatch({
            type: "SET_STATE",
            payload: {
              prevFeedbacks: search === "" ? [...data.feedbacks] : [],
              feedbacks: [...data.feedbacks],
              tableLoading: false,
              prevFeedbackPageLength: data.feedbacksCount[0].totalCount,
              feedbackPageLength: data.feedbacksCount[0].totalCount,
            },
          });
        });
    };

    return (
      <>
        <ListGroup id="status-feedback">
          <ListGroupItem
            className="justify-content-between feedback-filter"
            onClick={() => filter(0, "all")}
          >
            {translation.status.all[locale]}
          </ListGroupItem>
          {Object.entries(status).map((e, i) => {
            match = false;

            return (
              <ListGroupItem
                key={i}
                className="justify-content-between feedback-filter"
                onClick={() => filter(i + 1, e[0])}
              >
                {translation.status[e[0]][locale]}

                <Badge pill>
                  {!state.loading ? (
                    state.statusCount.length !== 0 ? (
                      state.statusCount.map((el, index) => {
                        let res = false;

                        if (!match && state.statusCount.length - 1 === index) {
                          res = 0;
                        }

                        if (
                          el.status.toLowerCase().replace(" ", "_") === e[0]
                        ) {
                          match = true;
                          res = el.no_status;
                        }

                        return res;
                      }) // Modify status name from fetch to match from local object status name
                    ) : (
                      0
                    )
                  ) : (
                    <Skeleton height={10} width={20} />
                  )}
                </Badge>
              </ListGroupItem>
            );
          })}
        </ListGroup>

        {user.role !== "Client" &&
        !["all", "closed"].includes(
          state.status.toLowerCase().replace("_", " ")
        ) ? (
          <div style={{ marginTop: "20px", textAlign: "center" }}>
            <span style={{ display: "block", fontSize: "1.5em" }}>
              {state.statusCount.find(
                (e) =>
                  e.status.toLowerCase().replace("_", " ") ==
                  stats.toLowerCase().replace("_", " ")
              ).total_time_estimate || "00:00"}
            </span>
            <span>{translation.total_time_estimate[locale]}</span>
          </div>
        ) : (
          ""
        )}
      </>
    );
  }
);
