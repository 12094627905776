import React, { useState, useEffect, useContext } from 'react';
import { Empty, Spinner2, Breadcrumb } from '../../../common';
import { Select, DatePicker, Row, Col } from 'antd';
import moment from 'moment'

import DeveloperAnalysisTable from './DeveloperAnalysisTable'

import { DEVELOPER_ANALYSIS } from '../../../../queries';
import { ApolloContext } from 'react-apollo'; 

const { WeekPicker } = DatePicker
const { Option } = Select

export default ({ reducer: { state, dispatch }, context }) => {
  const { client } = useContext(ApolloContext)
  const [visible, setVisible] = useState()
  const [name, setName] = useState()
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])
  const [filter, setFilter] = useState({ client: null, project: null, date: moment() })
  const [clients, setClients] = useState([])
  const [projects, setProjects] = useState([])

  useEffect(() => {
    if (state.assignedTasks.length !== 0) {
      setName(state.assignedTasks.find(user => user.id == context.params.staff).name)
    } else {
      setName(null)
    }
  }, [state.assignedTasks.length])

  useEffect(() => {
    // If both project and client variable is done fetching, then initialize value of filter
    if (context.temp_projects.length !== 0) { 
      // Reconstruct client and project variable from context.temp_projects
      let temp_projects = context.temp_projects.filter(e => e.team.find(t => t.id == context.params.staff)).map(e => ({ id: e.id, proj_name: e.proj_name, client_id: e.client_id }))
      let temp_clients = context.temp_projects.filter(e => e.team.find(t => t.id == context.params.staff)).reduce((a, b) => {
        if (!a.find(e => e.id == b.client_id)) {
          const res = { 
            id: b.client_id,
            clientname: b.client.clientname
          }
          
          a.push(res)
        }
        
        return a
      }, [])

      // Set default value of both client and project
      const client = (context.params.type == 'client' ? context.params.id : 
      context.params.type == 'project' ? temp_projects.find(e => e.id == context.params.id).client_id :
        'all')
      const project = '' + context.params.type == 'project' ? context.params.id : 'all' 

      
      setClients(temp_clients)
      setProjects(temp_projects)
      setFilter(e => { 
        return {
          client: client != 'all' ? parseInt(client): client, 
          date: e.date, 
          project 
        }
      })
    }
  }, [context.temp_projects])

  function loadTable(client_id, proj_id, date) {
    client.query({ query: DEVELOPER_ANALYSIS({ user_id: context.params.staff, client_id, proj_id, date }), }).then(({ data: { developerAnalysis } }) => {
      setData(reconstructData(developerAnalysis))
      setLoading(false)
    }).catch(err => {
      setLoading(false)
    })
  }

  // Reconstruct data to fit in table
  function reconstructData(data) {
    return data.map(a => {
      let date_received
      let { timelogs, ...rest } = a
      let counter = 0
  
      // Add total hours worked by date received
      const w = timelogs.reduce((a,b) => {
        let found
        if (!Array.isArray(a))
            a = [a]

        if (date_received != b.date_received) {
          date_received = b.date_received

          if (found = a.find(e => e.date_received == date_received)) {
            found.hours_worked = sumTime(found.hours_worked, b.hours_worked)
          } else {
            a.push(b)
          }
        } else {
          found = a.find(e => e.date_received == date_received)
          found.hours_worked = sumTime(found.hours_worked, b.hours_worked)
        }
        
        return a
      }, [])
  
      // Reconstruct timelog array to object from child to parent
      let logs = w.reduce((a,b) => {
        counter++
        let res = counter == 1 ? { [a.date_received]: a.hours_worked } : a
        res[b.date_received] = b.hours_worked
                    
        return res
      }, {})

      // Calculate for the total hours worked on a point
      const total_hours = w.reduce((a,b) => sumTime(a || '00:00', b.hours_worked), '00:00')
        
      return {
        ...rest,    
        ...logs,
        total_hours,
        client: rest.clientname + " (" + rest.proj_name + ")",
      }
    })
  }

  function sumTime(a = '00:00', b = '00:00') {
    // const duration = moment.duration({ hours: moment(b, 'HH:mm').format('HH'), minutes: moment(b, 'HH:mm').format('mm') })
    // return moment(a, 'HH:mm').add(duration).format('HH:mm')

    let a_h = a.split(':')[0], a_m = a.split(':')[1],
        b_h = b.split(':')[0], b_m = b.split(':')[1],
        r_h = 0, r_m = 0, r_r = 0, r_tm = 0, res = ''

    // ======== MINUTES ========
    // Add minutes then get the remainder / modolus as minutes
    r_tm = parseInt(a_m) + parseInt(b_m)
    r_m = r_tm % 60 
    r_r = r_tm / 60 // Get the dividend of the total minutes to add it to the total hours 

    // ======== Hours ========
    r_h = parseInt(a_h) + parseInt(b_h) + parseInt(r_r)

    return `${r_h + 100}`.substring(1) + ':' + `${r_m + 100}`.substring(1)
  }

  useEffect(() => {
    if (filter.client !== null && filter.date !== null) {
      loadTable(
        filter.client != 'all' ? filter.client : -1, // Client
        filter.project != 'all' ? filter.project : -1,  // Project
        { start: moment(filter.date).day(1).format('YYYY-MM-DD'), end: moment(filter.date).day(7).format('YYYY-MM-DD')}
      )
    }
  }, [filter])

  const onChange = type => val => {
    if (type != 'date') {
      setFilter(e => {
        const otherType = type == 'client' ? 
          {
            date: e.date,
            project: 'all'
          } :
          e
  
          
        return {
          ...otherType,
          [type]: val
        }
      })
        
        if ((type == 'client' && val == 'all') || ((type == 'project' && val == 'all') && (filter.client == 'all'))) {
          type = 'all'
        } 
  
        console.log(type)
        console.log(val)
  
      context.history.push(`/dashboard/analysis/${context.params.staff}/${type}/${val}`)
    } else { 
      setFilter(e => ({ ...e, date: val }))
    }
  }

  const onSearch = type => search => {
    console.log('search ' + search)
    console.log('type ' + type)
  }

  return (
    <>
      <div id="developer-analysis">

        <Breadcrumb
          items={[
            {
              key: '1',
              link: '/dashboard/analysis',
              label: context.translation.assigned_task[context.locale],
            }, {
              key: '2',
              label: name,
              loading: !!!name
            }
          ]}
          context={context} 
        />

        <div className="c-input-group">
          <span><b>{ context.translation.date[context.locale] }</b></span>  
          <WeekPicker 
            value={filter.date}
            style={{ width: 280, marginRight: 0 }}
            onChange={onChange('date')} 
            placeholder="Select week" 
          />
        </div>
        <div className="c-input-group">
          <span><b>{ context.translation.client[context.locale] }</b></span>  
          <Select
            showSearch
            style={{ width: 280 }}
            value={filter.client}
            placeholder={context.translation.select_client[context.locale]}
            optionFilterProp="children"
            onChange={onChange('client')}
            onSearch={onSearch('client')}
            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            <Option value="all">{ context.translation.status.all[context.locale] }</Option>
            {
              clients.length !== 0 &&
                clients.map((e, i) => <Option key={i} value={parseInt(e.id)}>{e.clientname}</Option>)
            }
          </Select>
        </div>
    
        <div className="c-input-group">
          <span><b>{ context.translation.nav.project[context.locale] }</b></span>  
          <Select
            showSearch
            style={{ width: 280 }}
            value={filter.project}
            placeholder={context.translation.select_project[context.locale]}
            optionFilterProp="children"
            onChange={onChange('project')}
            onSearch={onSearch('project')}
            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          > 
            <Option value="all">{ context.translation.status.all[context.locale] }</Option>
            {
              projects.length !== 0 &&
                projects.filter(e => 
                  context.params.type == 'all' ? 
                    clients.map(s => s.id).includes(e.client_id) : 
                      context.params.type == 'client' ? 
                        filter.client == e.client_id :
                        projects.find(s => s.id == context.params.id).client_id == e.client_id
                )
                  .map((e, i) => <Option key={i} value={e.id}>{e.proj_name}</Option>)
            }
          </Select>
        </div>

        <DeveloperAnalysisTable data={data} loading={loading} context={context} sumTime={sumTime} filter={filter}/>
      </div>
    </>
  )
}