import React, { useContext, useReducer, useEffect } from 'react';
import axios from 'axios';
import { USER_LOADING, LOAD_USER_DATA, AuthReducer } from '../reducers/AuthReducer';

/* Import Apollo and Queries */
import { ApolloContext, graphql } from 'react-apollo';
import { CURRENT_USER_QUERY, LOGIN } from '../queries';

const initialAuthState = {
  isOnline: false,
  isLoading: true,
  token: null,
  user: null,
  isRegister: false,
}

export const AuthContext = React.createContext()

export const AuthProvider = graphql(LOGIN)(props => {
  const context = useContext(ApolloContext); 
  const [authState, dispatch] = useReducer(AuthReducer, initialAuthState);
  
  useEffect(() => {
      
    ( async () => {
        
      const token = localStorage.getItem('techVoiceToken');
    
      if(token) {
        try {

          /* Verify Token */
          const user = await context.client.query({ query: CURRENT_USER_QUERY })
          const { currentUser } = user.data;

          loadUserData(currentUser);
        } catch (error) {
          console.log(error);
          // localStorage.removeItem('techVoiceToken');
          localStorage.removeItem('expand-sidebar')
       
          dispatch({ type: USER_LOADING, isLoading: false });
        }
      } else {
        localStorage.removeItem('expand-sidebar')
        
        setTimeout(() => {
          dispatch({type: USER_LOADING, isLoading: false })
        }, 1000)
      
      }
    }) ()

  }, [])

  const authenticateUser = async (payload) => {

    try {
      context.client.clearStore()
      const { data } = await props.mutate({
        variables: payload,
      });

      const { login } = data;

      if (login.status === 200) {

        localStorage.setItem('techVoiceToken', login.token)
        
        loadUserData(login.user)
      }

      return login
    } catch (error) {
      console.log(error);
      return { 
        success: false,
        message: error.response.data.msg,
        errorType: error.response.data.errorType,
      }
    }
  }

  const loadUserData = (user) => {
      dispatch({
        type: LOAD_USER_DATA, 
        user
      })
  }   
  
  return (
    <AuthContext.Provider value={{ 
      ...authState,
      authenticateUser,
      loadUserData,
      dispatch
    }}>
      { props.children }
    </AuthContext.Provider>
  )
})