import React, { useEffect } from 'react';
import { Radio, Select, Form } from 'antd';
import { Container, Row, Col, Input, FormFeedback, FormGroup, Label } from 'reactstrap';

const { Option } = Select;

export default Form.create()(({ state, dispatch, context, form: { getFieldDecorator, setFields, setFieldsValue } }) => {
  function onChangeRadio(e) {
    let form = { 
      ...state.form,
      client_id: (e.target.value === 'New') ? state.client_id : state.modalField.client.id
    }

    dispatch({ type: 'SET_STATE', payload: { modalField: {...state.modalField, client: {...state.modalField.client, type: e.target.value} }, form } })
  }

  function onChangeSelect(e) {    
    // Reset the selected project id if the client id is changed
    dispatch({ 
      type: 'SET_STATE', 
      payload: { 
        modalField: { 
          client: {
            ...state.modalField.client, 
            id: e
          }, 
          project: {
            ...state.modalField.project, 
            id: -1
          } 
        }, 
        form: { 
          ...state.form,
          client_id: e
        } 
      } 
    })
  }

  function onChangeStrap(e, property) {
    e.stopPropagation()
    dispatch({ type: 'SET_STATE', payload: { modalField: {...state.modalField, client: {...state.modalField.client, [property]: e.target.value}} } })
  }

  useEffect(() => {
    if (state.errors.client.client_id.invalid) {
      setFields({
        client_id: {
          errors: [new Error(state.errors.client.client_id.message)],
        },
      });
    }
  }, [state.errors.client.client_id.invalid])

  useEffect(() => {
    setFieldsValue({ client_id: state.modalField.client.id });
  }, [state.modalField.client.id])

  return (
    <Container>
      <Row style={{ marginBottom: '24px' }}>
        <Col sm="12">
          <center>
            <Radio.Group onChange={onChangeRadio} value={state.modalField.client.type} buttonStyle="solid">
              <Radio.Button value="New">{ context.translation.new[context.locale] }</Radio.Button>
              <Radio.Button value="Existing">{ context.translation.existing[context.locale] }</Radio.Button>
            </Radio.Group>
          </center>
        </Col>
      </Row>
      <Row>
        {
          state.modalField.client.type === 'New' ?
            (
              <>
                <Col sm="3">
                  <FormGroup>
                    <Label for="clientnr">
                      {context.translation.client[context.locale]} ID
                    </Label>
                    <Input
                      name="clientnr"
                      id="clientnr"
                      value={state.modalField.client.clientnr}
                      invalid={state.errors.client.clientnr.invalid}
                      bsSize="sm"
                      onChange={e => onChangeStrap(e, 'clientnr')}
                      required
                    />
                    <FormFeedback>{state.errors.client.clientnr.message}</FormFeedback>
                  </FormGroup>
                </Col>
                <Col sm="9">
                  <FormGroup>
                    <Label for="clientname">
                      {context.translation.client_name[context.locale]}
                    </Label>
                    <Input
                      name="clientname"
                      id="clientname"
                      value={state.modalField.client.clientname}
                      invalid={state.errors.client.clientname.invalid}
                      bsSize="sm"
                      onChange={e => onChangeStrap(e, 'clientname')}
                      required
                    />
                    <FormFeedback>{state.errors.client.clientname.message}</FormFeedback>
                  </FormGroup>
                </Col>
              </>
            ) :
            (
              <Col sm="12">
                <span style={{ marginBottom: '6px', display: 'block' }}>{ context.translation.client[context.locale] }</span>
                <Form wrapperCol={{ xs: { span: 24 } }}>
                  <Form.Item>
                    {
                      getFieldDecorator('client_id', { initialValue: state.modalField.client.id })(
                        <Select
                          showSearch
                          id="client_id"
                          optionFilterProp="children"
                          onChange={onChangeSelect}
                          filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          <Option value={-1} disabled>{context.translation.search_a_client[context.locale]}</Option>
                          {
                            state.clients.map((client) => (<Option value={client.id} key={client.id}>{client.clientname}</Option>))
                          }                  
                        </Select>
                      )
                    }
                  </Form.Item>
                </Form>
              </Col>
            )
        }
      </Row>
    </Container>
  )
})