import React, { useContext, useState, useReducer, useEffect } from 'react'
import { useSpring, animated } from 'react-spring'
import { withRouter } from 'react-router-dom'
import { AuthContext } from '../../contexts/AuthContext'
import { GlobalContext } from '../../contexts/GlobalContext'
import { isEmpty } from '../../api/validation'
import { Navbar, Collapse, Nav, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Container, Row, Col } from 'reactstrap'
import { ComponentNotFound } from '../common';
import gerFlag from '../../brand/ger-flag.png';
import engFlag from '../../brand/eng-flag.png'

import Icon from '../common/Icon';

// Login includes
import LoginForm from './LoginForm'

// Login Reducer
import LoginReducer from './LoginReducer'

import { Toast } from '../common';
/* Import Apollo and Queries */
import { ApolloContext } from 'react-apollo';
import { RESET_PASSWORD, VERIFY_USER } from '../../queries';

const Login = withRouter(({ history, mutate, match, location }) => {
  const { type } = match.params
  const isTypeNotEmpty = !!type;
  const { client } = useContext(ApolloContext); 
  const initialState = {
    user_id: -1,
    userName: '',
    password: '',
    formMessage: '',
    formPassMessage: '',
    errorMessage: '',
    verificationKey: '',
    isLoading: false,
    inValidForm: false,
    isError: false,
    forgotPassword: false,
    clicked: false,
    isNewDevice: false,
    verifyUser: false
  }
  const Auth = useContext(AuthContext)
  const context = useContext(GlobalContext)
  const [state, dispatch] = useReducer(LoginReducer, initialState)
  const { userName, password } = state


  useEffect(() => {
    localStorage.clear()
  }, [])

  const setActive = lang => (context.locale === lang) ? true : false;
  const setFlag = () => (context.locale === 'de') ? gerFlag : engFlag;

  const handleForgotPassword = async e => {
    e.preventDefault()
    dispatch({type: 'SET_STATE', payload: { inValidForm: false, formMessage: '', isError: false, isLoading: true }})
    try {
      const { data } = await client.mutate({
        mutation: RESET_PASSWORD,
        variables: { data: { email: userName, lang: context.locale } },
      });

      if(data.resetPassword.status === 400) {
        dispatch({ type:'FAILED_USERNAME', message: `${context.translation.error_email_exist[context.locale]}!` })
      } else {
        dispatch({ type: 'SET_STATE', payload: { forgotPassword: !state.forgotPassword, isLoading: false, userName: '' } })
        Toast.showToast('success', context.translation.reset_pass_email_msg[context.locale])
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: 'SET_ERROR', payload: { isError: true, message: `${context.translation.error_connection_timeout[context.locale]}!`, isLoading: false }})
    }
  }

  const handleVerifyUser = async e => {
    e.preventDefault()
    dispatch({type: 'SET_STATE', payload: { inValidForm: false, formMessage: '', isError: false, isLoading: true }})
    try {
      const { data } = await client.mutate({
        mutation: VERIFY_USER,
        variables: { email: userName, verifyUserID: state.user_id, verificationKey: state.verificationKey, type: 'verify' },
      });

      if (data.verifyUser.status === 400) {
        if (data.verifyUser.errorType === 1) {
          dispatch({ type:'SET_ERROR', isError: true, message: `${context.translation.invalid_verification_key[context.locale]}!`, isLoading: false })
        } else {
          dispatch({ type:'SET_ERROR', isError: true, message: `${context.translation.invalid_user_id[context.locale]}!`, isLoading: false })
        }
      } else if(data.verifyUser.status === 200) {

        localStorage.setItem('techVoiceToken', data.verifyUser.token)
        Auth.loadUserData(data.verifyUser.user)
    
        if (data.verifyUser.user.first_login === 0)
          history.push('/settings/security')
        else
          history.push('/dashboard/workplace');

      }
    } catch (error) {
      console.log(error);
      dispatch({ type: 'SET_ERROR', isError: true, message: `${context.translation.error_connection_timeout[context.locale]}!`, isLoading: false })
    }
  }

  const handleSendVerificationKey = async e => {
    e.preventDefault()
    try {
      dispatch({ type: 'SET_STATE', payload: { isLoading: true } });
      const { data } = await client.mutate({
        mutation: VERIFY_USER,
        variables: { email: userName, verifyUserID: state.user_id, verificationKey: '', type: 'send_email' },
      });


      dispatch({ type: 'SET_STATE', payload: { isLoading: false, verifyUser: true } })
      history.push('/login/verifyUser?' + state.user_id)
    } catch (error) {
      console.log(error);
      dispatch({ type: 'SET_ERROR', isError: true, message: `${context.translation.error_connection_timeout[context.locale]}!`, isLoading: false })
    }
  }

  const handleSubmit = async (e) => {

    e.preventDefault();

    dispatch({ type:'LOGIN' });

    localStorage.removeItem('techVoiceToken');
    localStorage.removeItem('selectedFilter');

    if(!isEmpty(userName) && !isEmpty(password)) {
      const _data = {
        email: userName,
        password: password
      }

      try {
          
        const res = await Auth.authenticateUser(_data);

        if(res.status === 200) {
          if (res.user.first_login === 0)
            history.push('/settings/security')
          else
            history.push('/dashboard/workplace');

        } else if (res.status === 401) {

          if(res.errorType === 1) {
            dispatch({ type:'FAILED_USERNAME', message: `${context.translation.error_email_exist[context.locale]}!` })
          } else {
            dispatch({ type:'FAILED_PASSWORD', message: `${context.translation.error_password_incorrect[context.locale]}!` })
          }
        } else if (res.status === 403) {
          dispatch({ type: 'SET_STATE', payload: { user_id: res.user.id, forgotPassword: true, isNewDevice: true } })
        }

      } catch (error) {
        dispatch({ type: 'SET_ERROR', isError: true, message: `${context.translation.error_connection_timeout[context.locale]}!` })
        console.log(error)
      }
        
    } else {

      if(isEmpty(userName)) {

        dispatch({ type:'USENAME_EMPTY', message: context.translation.error_email_required[context.locale] })
      }

      if( isEmpty(password)) {

        dispatch({ type:'PASSWORD_EMPTY', message: context.translation.error_password_required[context.locale] })
      }
      
    }
  }

  const goToLogin = async e => {
    dispatch({ type: 'SET_STATE', payload: { forgotPassword: !state.forgotPassword, verifyUser: false, isNewDevice: false }})
    history.push('/login');
  }

  const goToRegister = async e => {
    context.dispatch({ type: 'SET_STATE', payload: { isRegister: !context.isRegister } })
    history.push('/register');
  }

  return (
    (!isTypeNotEmpty || (isTypeNotEmpty && type === 'resetPassword') || (isTypeNotEmpty && type === 'verifyUser'))?
      <div id="meow" style={{ display: 'flex', flexDirection: 'column', height: 'inherit' }}> 
        <animated.div style={useSpring({  to: { opacity: 1, marginTop: 0 }, from: { opacity: 0, marginTop: -500 }, leave: { opacity: 0, transform: 'translate3d(100%,0,0)' }, delay: 200 })} className="container">
          {/* <Navbar className="main-navbar flex-md-nowrap p-0" style={{ backgroundColor: 'transparent', height: '49px', marginTop: '-62px' }} light expand="md">
            <Collapse className="mr-2" navbar>
              <Nav className="ml-auto" style={{ height: '50px', paddingTop: '7px' }} navbar>
                <UncontrolledDropdown id="lang" className="ml-2" style={{ marginRight: '7px' ,backgroundColor: 'transparent' }} nav inNavbar>
                  <DropdownToggle nav>
                    <Icon height="13" width="15" src={setFlag()}  />
                  </DropdownToggle>
                  <DropdownMenu right className="mb-2" style={{ fontSize: '.795rem' }}>
                    <DropdownItem onClick={() => context.setLocale('en')} style={{ paddingLeft: '15px' }} active={setActive('en')}><small style={{ marginRight: '10px' }}>EN</small> English</DropdownItem>
                    <DropdownItem onClick={() => context.setLocale('de')} style={{ paddingLeft: '15px' }} active={setActive('de')}><small style={{ marginRight: '10px' }}>DE</small> German</DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </Nav>
            </Collapse>
          </Navbar> */}

          <Container className="">
            <Row noGutters className="justify-content-center">

              <Col sm="12" md="6" lg="6" xl="6">
                  
                <LoginForm state={state} dispatch={dispatch} params={{ type, isTypeNotEmpty, search: location.search }} handleSubmit={handleSubmit} handleForgotPassword={handleForgotPassword} handleSendVerificationKey={handleSendVerificationKey} handleVerifyUser={handleVerifyUser} goToLogin={goToLogin} goToRegister={goToRegister} />
                  
              </Col>
            </Row>
          </Container>
        </animated.div>
        <animated.div style={useSpring({  to: { opacity: 1, marginTop: 0 }, from: { pacity: 0, marginTop: 1500 }, leave: { opacity: 0, transform: 'translate3d(100%,0,0)' }, delay: 400 })} className="footer-login">
          <div className="footer-content">
            <div className="footer-content-column">
              <h4 id="logo-namee" style={{ fontSize: '2.5em', marginTop: '-10px', marginLeft: 0 }}>exactplace</h4>
              <hr style={{ borderTop: '1px solid #fff' }} />
              <span style={{ display: 'inline-block', marginBottom: '40px' }}>{context.translation.tagline[context.locale]}</span><br />
              © 2023 exactplace, All Rights Reserved.
            </div>
            <div className="footer-content-column">
              <strong>{context.translation.products[context.locale]}</strong>
              <ul>
                {/* <li><a target="_blank" href="http://www.planiton.ch">planiton.ch</a></li> */}
                <li><a target="_blank" href="https://exactfinance.ch">exactfinance.ch</a></li>
                <li><a target="_blank" href="https://risk-tool.exactflow.ch/">Risikomatrix-Tool</a></li>
                <li><a target="_blank" href="https://beschwerdemanagement.exactflow.ch/">Beschwerdemanagement-Tool</a></li>
              </ul>
            </div>
            <div className="footer-content-column">
              <strong>{context.translation.services[context.locale]}</strong>
              <ul>
                <li><a target="_blank" href="https://excel-hilfe.ch">excel-hilfe.ch</a></li>
                <li><a target="_blank" href="https://exactflow.ch">exactflow.ch</a></li>
                <li><a target="_blank" href="http://exact-construct.ch">exact-construct.ch</a></li>
              </ul>
            </div>
            <div className="footer-content-column">
              <strong>{context.translation.follow_us[context.locale]}</strong>
              <ul>
                <li><a target="_blank" href="https://ph.linkedin.com/company/exact-construct-gmbh">LinkedIn</a></li>
                <li><a target="_blank" href="https://www.facebook.com/exactconstruct/?ref=br_rs">Facebook</a></li>
                {/* <li><a target="_blank" href="https://twitter.com/exactconstruct">Twitter</a></li> */}
                <li><a target="_blank" href="https://www.xing.com/companies/exactconstructgmbh">Xing</a></li>
              </ul>
            </div>
            <div className="footer-content-column">
              <strong>{context.translation.contact[context.locale]}</strong>
              <ul>
                <li>exact construct GmbH</li>
                <li>Hungerbüelstrasse 22</li>
                <li>8500 Frauenfeld</li>
                <li>+ 41 (0) 52 511 05 25</li>
              </ul>
            </div>
          </div>
        </animated.div>
      </div>
      :
      <ComponentNotFound />
  )
})

export default Login
