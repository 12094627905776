import React from 'react'
import { Spinner } from 'reactstrap'
import './style.css'

const Spinner2 = (props) => {
   return (
    <div className="atom-spinner" style={{ marginLeft: 'auto', marginRight: 'auto', ...props.style  }}>
      <div className="spinner-inner">
        <div className="spinner-line"></div>
        <div className="spinner-line"></div>
        <div className="spinner-line"></div>
        <div className="spinner-circle">
          &#9679;
        </div>
      </div>
    </div>
   )
}

export default Spinner2