import React, { useState, useEffect, useContext } from 'react';
import Moment from 'react-moment';
import { Badge } from 'reactstrap'
import BootstrapTable from 'react-bootstrap-table-next';
import { Empty, Spinner2 } from '../../../common';
import moment from 'moment';

import PointList from './PointList'

export default ({ reducer: { state, dispatch, loading }, data, type, context }) => {
  const [expand, setExpand] = useState([])

  const columns = {
    tasks: [{
      dataField: 'clientname',
      text: context.translation.client[context.locale],
      style: {
        width: '25%'
      },
    }, {
      dataField: 'proj_name',
      text: context.translation.nav.project[context.locale],
    }, {
      dataField: 'total_time_estimate',
      text: context.translation.estimated_hours[context.locale],
      headerAlign: 'center',
      align: 'center',
    }, {
      dataField: 'open_status',
      text: context.translation.open_points[context.locale],
      headerAlign: 'center',
      align: 'center',
      formatter: (cell, row, rowIndex, formatExtraData) => <PointList data={{ cell, row, status: 'Open', table: '2' }} reducer={{ state, dispatch }} context={context} />,
    }],
    projects: [{
      dataField: 'clientname',
      text: context.translation.client[context.locale],
      style: {
        width: '25%'
      },
    }, {
      dataField: 'proj_name',
      text: context.translation.nav.project[context.locale],
    }, {
      dataField: 'project_lead',
      text: context.translation.project_leader[context.locale],
    }, {
      dataField: 'date_created',
      text: context.translation.date_created[context.locale],
      headerAlign: 'center',
      align: 'center',
    }]
  };

  
  return (
    <div >
      <BootstrapTable
        id="table-unassigned-task-list"
        keyField="id"
        bootstrap4
        columns = { columns[type] }
        data = { data }
        noDataIndication = { () => loading ? <Spinner2 />: <Empty /> }
        bordered = { false }
      />
    </div>
  )
};