export default ({
  to: {
    opacity: 1, 
    marginTop: 0,
  }, 

  from: { 
    opacity: 0, 
    marginTop: 50 ,
  }, 
  delay: 100,
});