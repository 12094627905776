import React, { useState, useEffect } from 'react';
import { Toast } from '../../common';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import { message, Popconfirm, Alert as Alerta } from 'antd'
import { Row, Col, Alert, Form, FormGroup, Label, Input, FormFeedback, FormText } from 'reactstrap';
import { CREATE_PROJECT, UPDATE_PROJECT } from '../../../queries';
import Button from '@material-ui/core/Button';

export const saveProject = async (e, type, state, dispatch, clearState, context, initialErrorState, updateProject, createTable, translation, locale) => {
  e.preventDefault();
  if (validateFields()) {
    dispatch({ type: 'setErrors', payload: { ...initialErrorState } })
    const hide = message.loading(translation.saving_changes[locale], 0)
    try {
      const formData = new FormData(document.forms.namedItem('modal-form'))
      let payload = {};
      formData.forEach((value,key) => payload[key] = value )
      payload.team = state.team
      payload.versions = state.versions

      if (type === 'create') {
        const { data } = await context.client.mutate({ mutation: CREATE_PROJECT, variables: { data: payload } });
        let temp_project = [...state.projects, {...data.createProject}]

        const project = createTable({ projects: temp_project})
        dispatch({ type: 'INITIAL_STATE', 
          payload: {
            projects: [...temp_project],
            projectsTable: [...project] 
          }
        });
        
        dispatch({ type: 'setStatusCount', payload: { ...state.statusCount, open_projects: ++state.statusCount.open_projects, total_projects: ++state.statusCount.total_projects } })
      } else {
        const { data } = await context.client.mutate({ mutation: UPDATE_PROJECT, variables: { data: {...payload, isNrSame: state.temp_proj_nr === state.proj_nr, isNameSame: state.temp_proj_name === state.proj_name }, id: parseInt(state.proj_id) } });
        let temp_project = state.projects.map(e => {
          if (e.id === data.updateProject.id) 
            e = data.updateProject;
          return e 
        })
    
        const project = createTable({ projects: temp_project})
    
        if (!!payload.status) {
          if (payload.status === 'Open') {
            state.statusCount.open_projects++
            state.statusCount.closed_projects--
          } else {
            state.statusCount.open_projects--
            state.statusCount.closed_projects++
          }
          
          dispatch({ type: 'setStatusCount', payload: { ...state.statusCount, open_projects: state.statusCount.open_projects, closed_projects: state.statusCount.closed_projects } })
        }
        
        dispatch({ type: 'INITIAL_STATE', 
          payload: {
            projects: [...temp_project],
            projectsTable: [...project]
          } 
        })
      }

      // Toast.showToast('success', (type === 'create')? translation.msg_project_success[locale]: translation.msg_project_updated[locale])
      hide()
      message.success((type === 'create')? translation.msg_project_success[locale]: translation.msg_project_updated[locale])
      clearState()
      return true;
    } catch (error) {
      hide()
      error.message = error.message.replace('GraphQL error: ', '')
      const error_list = ['proj_nr', 'proj_name', 'version', 'team']
      let error_msg = JSON.parse(error.message)
      error_list.forEach(e => {
        if (error_msg[e]) 
          if(error_msg[e].invalid)
            error_msg[e].message = translation.errors.project[e][locale]
      })
      dispatch({ type: 'setErrors', payload: { project: { ...state.errors.project, ...error_msg } } })
      return false
    }
  }

  function validateFields () {
    let hasProjectLead = false
    let hasVersion = state.versions.length !== 0
  
    for (let user in state.team) {
      if (state.team[user].proj_user_type_id === 3) {
        hasProjectLead = true
        break
      }
    }
    
    let errorState = {};
    if (!hasProjectLead && !hasVersion)
      errorState = { ...state.errors.project, version: { invalid: true, message: translation.errors.project.version[locale] }, team: { invalid: true, message: translation.errors.project.team[locale] } }
    else if (!hasVersion) 
      errorState = { ...state.errors.project, version: { invalid: true, message: translation.errors.project.version[locale] }}
    else if (!hasProjectLead)
      errorState = { ...state.errors.project, team: { invalid: true, message: translation.errors.project.team[locale] } }
  
    dispatch({ type: 'setErrors', payload: { project: errorState } })
  
    return hasProjectLead && hasVersion
  }
}

export default ({ state, dispatch, initialErrorState, translation, locale, condis, currentStep }) => {
  const [visible, setVisible] = useState([])

  useEffect(() => {
    if (state.users.length !== 0) {
      dispatch({ type: 'INITIAL_STATE', 
        payload: { 
          user: {
            id: state.users[0].id, 
            name: state.users[0].name, 
            user_type: state.users[0].user_type_id
          },
          role: {
            id: state.users[0].user_type_id == 7? 5: 3,
            name: state.users[0].user_type_id == 7? translation.feedback_provider[locale]: translation.project_leader[locale]
          }
        } 
      })
    }
  }, [state.users.length]);

  useEffect(() => {
    if ([11,12].some(e => currentStep == e) && state.modal) {
      const property = currentStep == 11 ? 'team' : 'versions'

      if (!!state[property]) {
        let isInteraction = true
  
        if (state[property].length !== 0) {
          isInteraction = false
        } 
  
        condis({ type: 'SET_STATE', payload: { isInteraction } })
      }
    }
  }, [state.team, state.versions, currentStep])

  useEffect(() => {
    if (visible.length !== state.versions.length)
      setVisible(state.versions.map(e => false))
  }, [state.versions])

  useEffect(() => {
    if (state.team.length !== 0) {
      state.team.forEach(e => {
        if (e.user_type_id == 7 && e.client_id != state.selectedClient) {
          removeUser(e)()
        }
      })
    }
  }, [state.selectedClient])

  const removeUser = user => () => {
    dispatch({ type: 'INITIAL_STATE', 
      payload: {
        users: [ ...state.users, {...user, id: user.user_id } ],
        team: state.team.filter(x => parseInt(user.user_id) !== parseInt(x.user_id))
      }
    })
  }

  const removeVersion = v => () => {
    if (v.id == state.editVersion.id) {
      dispatch({ type: 'INITIAL_STATE', 
        payload: {
          editVersion: { edit: false, id: 0, version_name: '' },
          version: ''
        }
      })
    }
    dispatch({ type: 'setVersions', payload: state.versions.filter(x => x.version_name != v.version_name) })
  }

  const addUser = () => {
    dispatch({ type: 'INITIAL_STATE', 
      payload: {
        team: [ ...state.team, { user_id: state.user.id, name: state.user.name, project_role: state.role.name, proj_user_type_id: state.role.id, user_type_id: state.user.user_type, client_id: state.user.client_id }],
        users: state.users.filter(x => parseInt(state.user.id) !== parseInt(x.id))
      }
    })

    // let name = document.getElementById('version');
    // console.log("version", name.value)
    // if (!!name.value) {
        if(state.versions.length !== 1){
          dispatch({ type: 'INITIAL_STATE', 
            payload: {
              versions: [ ...state.versions, { version_name: "default" }],
              version: ''
            }
          })
        }
      // } 
    
    if (state.errors.project.team.invalid === true) 
      dispatch({ type: 'setErrors', payload: { project: { ...state.errors.project, team: { invalid: false, message: '' } } } })
  }

  const isVersionDuplicate = (version_name) => {
    let result = false
    state.versions.forEach(e => {
      if (e.version_name == version_name)
        result = true
    })

    if (result) {
      dispatch({ type: 'setErrors', payload: { project: { ...state.errors.project, version: { invalid: true, message: translation.error_version_exists[locale] } } } })
    }

    return result
  }

  const addVersion = () => {
    let name = document.getElementById('version');
    console.log("version", name.value)
    if (!!name.value) {
      if (!isVersionDuplicate(name.value)) {
        if (state.errors.project.version.invalid === true) 
          dispatch({ type: 'setErrors', payload: { project: {...state.errors.project, version: { invalid: false, message: '' }} } })
        
        dispatch({ type: 'INITIAL_STATE', 
          payload: {
            versions: [ ...state.versions, { version_name: name.value }],
            version: ''
          }
        })
      } 
    }
  }

  const handleKeyPress = (e) => { 
    if(e.key === 'Enter') { 
      e.preventDefault();
      if (!state.editVersion.edit) {
        addVersion(); 
      } else {
        updateVersion()
      }
      return false;
    } 
  }

  const updateState = (e, property) => {
    if (property === 'user') {
      dispatch({ type: 'INITIAL_STATE', 
        payload: { 
          user: {
            id: e.target.options[e.target.selectedIndex].dataset.id, 
            name: e.target.value, 
            user_type: e.target.options[e.target.selectedIndex].dataset.type,
            client_id: e.target.options[e.target.selectedIndex].dataset.client_id
          }, 
          role: {
            id: e.target.options[e.target.selectedIndex].dataset.type == 7? 5: state.role.id,
            name: e.target.options[e.target.selectedIndex].dataset.type == 7? translation.feedback_provider[locale]: state.role.name
          }
        } 
      })
    }
    else if (property === 'role') {
      dispatch({ type: 'setRole', payload: { id: e.target.options[e.target.selectedIndex].dataset.id, name: e.target.value } })
    }
    else {
      dispatch({ type: 'setVersion', payload: e.target.value })
    }
  }

  const updateVersion = () => {
    if (!isVersionDuplicate(state.version)) {
      dispatch({ type: 'INITIAL_STATE', 
        payload: {
          editVersion: { edit: false, id: 0, version_name: '' },
          version: '',
          versions: state.versions.map(e => {
            let v = e.version_name
            if (state.editVersion.id == e.id)
              v = state.version
            return { ...e, version_name: v }
          })
        }
      })
    }
  }

  function handleVisibleChange (visible, i) {
    setVisible(x => x.map((s, index) => i == index? visible: s))
  }

  return (
    <>
      <Row>
        <Col xs={3}>
          <FormGroup>
            <Label for="proj_nr">{translation.nav.project[locale]} ID</Label>
            <Input name="proj_nr" id="proj_nr" invalid={state.errors.project.proj_nr.invalid} value={state.proj_nr} onChange={(e) => { dispatch({ type: 'setProj_nr', payload: e.target.value })}} bsSize="sm" required />
            <FormFeedback>{state.errors.project.proj_nr.message}</FormFeedback>
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Label for="proj_name">{translation.project_name[locale]}</Label>
            <Input name="proj_name" id="proj_name" invalid={state.errors.project.proj_name.invalid} value={state.proj_name} onChange={(e) => {dispatch({ type: 'setProj_name', payload: e.target.value })}} bsSize="sm" required />
            <FormFeedback>{state.errors.project.proj_name.message}</FormFeedback>
          </FormGroup>
        </Col>
        <Col xs={12}>
          <FormGroup>
            <Label for="client">{translation.client[locale]}</Label>
            {
              !!!state.modalField ? 
                (
                  <Input type="select" name="client_id" id="client" bsSize="sm" defaultValue={state.selectedClient} onChange={(event) => {event.stopPropagation(); dispatch({ type: 'INITIAL_STATE', payload: { selectedClient: event.target.value } })}}>
                  {
                      !!state.clients && 
                      state.clients.sort(function(a, b){
                          var nameA=a.clientname.toLowerCase(), nameB=b.clientname.toLowerCase()
                          if (nameA < nameB) //sort string ascending
                              return -1 
                          if (nameA > nameB)
                              return 1
                          return 0 //default return value (no sorting)
                      }).map(e => <option key={e.id} value={e.id}>{e.clientname}</option>)
                    }
                  </Input>
                ) : 
                state.modalField.client.type === 'New' ?
                  <span style={{ display: 'block' }}><b>{ state.modalField.client.clientname }</b></span>
                : <span style={{ display: 'block' }}><b>{ state.clients.find(e => e.id == state.modalField.client.id).clientname }</b></span>
            }
          </FormGroup>
        </Col>
        <Row id="user-step" style={{ margin: 0, padding: 0, width: '100%' }}>
          <Col xs={5}>
            <FormGroup>
              <Label for="users">{translation.users[locale]}</Label>
              <Input onChange={(e) => updateState(e, 'user')} invalid={state.errors.project.team.invalid} type="select" id="users" bsSize="sm">
                {
                  !!state.users && state.users.filter(e => e.user_type_id != 7 ? true : (state.selectedClient == e.client_id))
                    .map(e => (
                      <option key={e.id} data-id={e.id} data-type={e.user_type_id} data-client_id={e.client_id} value={e.name}>{`${e.name} ${e.user_type_id == 7 ? ' - ' + translation.clien[locale] : ''}`}</option>
                      )
                    )
                }
              </Input>
              {/* <FormFeedback>{state.errors.project.team.message}</FormFeedback> */}
              <FormText className={state.errors.project.team.invalid? 'error-text': ''} style={{ position: 'absolute', width: '500px' }}>{locale != 'de' ?(<>A project should have at least <strong>1 Project Leader</strong>.</>): translation.error_one_project_lead[locale]}</FormText>
            </FormGroup>
          </Col>
          <Col xs={5}>
            <FormGroup>
              <Label for="role">{translation.role[locale]}</Label>
              <Input value={state.role.name} onChange={(e) => updateState(e, 'role')} type="select" id="role" bsSize="sm">
                {
                  state.user.user_type != 7 ?
                    <>
                      <option key={3} data-id={3} value="Project Leader">{translation.project_leader[locale]}</option>
                      <option key={4} data-id={4} value="Project Staff">{translation.project_staff[locale]}</option>
                      <option key={5} data-id={5} value="Feedback Provider">{translation.feedback_provider[locale]}</option>
                    </>
                  :
                    <option key={5} data-id={5} value="Feedback Provider" selected="selected">{translation.feedback_provider[locale]}</option>
                }
              </Input>
            </FormGroup>
          </Col>
          <Col xs={2} style={{ marginTop: '25px' }}>
            <Button color="primary" size="small" onClick={addUser} type="button" style={{ width: '100%', float: 'right' }} disabled={state.users.length === 0}>{translation.add[locale]}</Button>
          </Col>
        </Row>
        <Row id="version-step" style={{ margin: 0, padding: 0, width: '100%' }}>
          <Col xs={12} style={{ marginTop: '20px' }}>
            <span><strong style={{ verticalAlign: 'top', marginRight: '5px' }}>Team: </strong></span>
            {
              !!state.team && state.team.map((e, i) => <Chip variant={(!!e.id)? 'default': 'outlined'} style={{ marginRight: '5px', marginBottom: '5px' }} key={i} color="primary" className="MuiChip-sizeSmall" label={e.name} onDelete={removeUser(e)} avatar={<Avatar>{(e.proj_user_type_id == 3)? 'PL': (e.proj_user_type_id == 4) ? 'PS' : 'FP'}</Avatar>} />)
            }
          </Col>
          <Col id="version-form" xs={10} style={{ marginTop: '10px' }}> 
            {/* <FormGroup>
              <Label for="version">Version Name</Label>
              <Input invalid={state.errors.project.version.invalid} onKeyPress={(e) => handleKeyPress(e)} onChange={(e) => updateState(e, 'version')} value={state.version} id="version" bsSize="sm" />
          
              <FormText className={state.errors.project.version.invalid? 'error-text': ''}>{locale != 'de'? (<>A project should have atleast <strong>1 version</strong>.</>): translation.error_one_version[locale]}</FormText>
            </FormGroup> */}
          </Col>
          {/* { !state.editVersion.edit ? (
            <Col xs={2} style={{ marginTop: '35px' }}>
              <Button color="primary" size="small" onClick={addVersion} type="button" style={{ width: '100%', float: 'right' }} disabled={!!!state.version}>{translation.add[locale]}</Button>
            </Col>
          ) :
          (
            <>
              <Col xs={1} style={{ marginTop: '35px', paddingLeft: 0 }}>
                <Button size="small" onClick={() => updateVersion()} type="button" style={{ color: 'green', minWidth: 0 }} disabled={!!!state.version}>
                  <i className="fas fa-check"></i>
                </Button>
              </Col>
              <Col xs={1} style={{ marginTop: '35px', paddingLeft: 0 }}>
                <Button size="small" onClick={() => {
                  dispatch({ type: 'INITIAL_STATE', 
                    payload: {
                      editVersion: { edit: false, id: 0, version_name: '' },
                      version: '',
                      errors: { ...initialErrorState }
                    }
                  })
                }} type="button" style={{ color: 'red', minWidth: 0 }}>
                  <i className="fas fa-times"></i>
                </Button>
              </Col>
            </>
          )} */}
        </Row>

          
        
        {/* <Col xs={12}>
          {
            state.versions.length !== 0 && state.versions.find(e => !!e.id) ? (
              <Alerta message={translation.tip_edit[locale]} type="info" closable showIcon style={{ marginBottom: '5px' }} />
            ): <></>
          }
          <span><strong style={{ verticalAlign: 'top', marginRight: '5px' }}>Versions: </strong></span>
          {
            !!state.versions && 
            state.versions.map((e, i) => 
              (
                <Popconfirm
                  key={i} 
                  title={translation.affected_version[locale]}
                  visible={visible[i]}
                  // onVisibleChange={visible => handleVisibleChange(visible, i)}
                  onConfirm={removeVersion(e)}
                  onCancel={() => setVisible(x => x.map((s, index) => i == index? false: s))}
                  okText={translation.continue[locale]}
                  cancelText={translation.cancel[locale]}
                >
                  <Chip 
                    key={i} 
                    onClick={() => {
                      if (!!e.id) {
                        dispatch({ type: 'setEditVersion', payload: { edit: true, id: e.id, version_name: e.version_name } }); 
                        dispatch({ type: 'setVersion', payload: e.version_name })
                        document.getElementById('version').focus(); 
                      }
                    }} 
                    variant={(!!e.id)? 'default': 'outlined'} 
                    style={{ marginRight: '5px', marginBottom: '5px' }} 
                    color="secondary" 
                    className={`MuiChip-sizeSmall ${(!!e.id)? 'chip-hover': 'chip-unhoverable'}`} 
                    label={(state.editVersion.id == e.id)? state.version: e.version_name} 
                    onDelete={() => setVisible(x => x.map((s, index) => i == index? true: s))} 
                    avatar={(state.editVersion.id == e.id)? <Avatar><i className="fas fa-edit" style={{ fontSize: '12.5px', margin: '4px 0 0 5.3px' }}></i></Avatar>: <></> } 
                  />
                </Popconfirm>
              ) 
            )
          }
        </Col> */}
      </Row>
    </>
  )
}