import React, { useEffect, useContext } from 'react';
import { useSpring, animated } from 'react-spring';
import { withRouter, Link } from 'react-router-dom';
import { ApolloContext } from 'react-apollo';
import { AuthContext } from '../../contexts/AuthContext';
import { GlobalContext } from '../../contexts/GlobalContext';
import { LOGOUT } from '../../reducers/AuthReducer';
import {
  Collapse,
  Navbar,
  NavbarBrand,
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAlignLeft } from '@fortawesome/free-solid-svg-icons';
import MenuIcon from '@material-ui/icons/Menu';

import gerFlag from '../../brand/ger-flag.png';
import engFlag from '../../brand/eng-flag.png';

import Icon from './Icon';
import Notif from './Notif';

const uri =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3000'
    : 'http://exactplacev0.exactconstruct.synology.me/';

let steps = [];
const MainNavbar = withRouter(({ history, toggleSideNav, sideNavIsOpen }) => {
  // const [isOpen, toggleIsOpen] = useState(false);
  const Auth = useContext(AuthContext);
  const {
    translation,
    locale,
    setLocale,
    dispatch,
    feature_modal,
    isOpen,
    showSidebar,
    isTourOpen,
  } = useContext(GlobalContext);
  const { client } = useContext(ApolloContext);

  const props = useSpring({
    to: {
      opacity: 1,
      marginTop: 0,
    },

    from: {
      opacity: 0,
      marginTop: -500,
    },
  });

  const logOut = () => {
    localStorage.removeItem('techVoiceToken');
    localStorage.removeItem('expand-sidebar');

    client.cache.reset(); // Clears query cache

    Auth.dispatch({ type: LOGOUT });

    // window.location = '/'
  };

  const setActive = (lang) => (locale === lang ? true : false);
  const setFlag = () => (locale === 'de' ? gerFlag : engFlag);

  return (
    <animated.div className="main-nav sticky-top navi" style={props}>
      <header className="main-nav">
        <Navbar
          className="main-navbar flex-md-nowrap p-0"
          style={{ boxShadow: '0 1px 4px rgba(0,0,0,0.1)', height: '49px' }}
          light
          expand="md"
        >
          <div className="logo-box d-flex align-items-center">
            <div
              className="sidebar-header"
              style={{
                zIndex: 1,
                backgroundColor: showSidebar ? '#fff' : 'transparent',
              }}
            >
              {showSidebar ? (
                <Button
                  className="btn-outline-exact"
                  style={{ marginLeft: isOpen ? '166px' : '65px' }}
                  onClick={() => {
                    dispatch({ type: 'SET_IS_OPEN', isOpen: !isOpen });
                  }}
                >
                  <MenuIcon />
                </Button>
              ) : (
                <a
                  onClick={() => history.push('/')}
                  style={{ display: 'initial', margin: 0 }}
                >
                  <div id="side-header">
                    {/* <span className="logo-name">e</span> */}
                    <h4
                      id="logo-namee"
                      className={`ico ${isOpen ? '' : 'opac'}`}
                      style={{ zIndex: 1, padding: '1px 0 0 0' }}
                    >
                      xactplace
                    </h4>
                    <div id="logsgo"></div>
                  </div>
                </a>
              )}
              {/* <h4 style={{ paddingTop: '4.5px', paddingBottom: '5px', paddingLeft: '15px', borderBottom: '2px solid #ff3a3a' }}><span className="logo-name">e</span>xactplace</h4> */}
            </div>
            {/* <NavbarBrand href="/" className="px-3 py-1 mr-0"><h4><span className="logo-name">e</span>xactplace</h4></NavbarBrand> */}
            {/* <span onClick={() => toggleSideNav(!sideNavIsOpen) } className="ml-3 icon"><i className="fas fa-bars fa-lg"></i></span> */}
          </div>

          {/* <button onClick={ () => toggleIsOpen(!isOpen) } type="button" className="navbar-toggler">
              <span><i className="fas fa-ellipsis-v"></i></span>
          </button> */}

          <Notif />

          <Collapse className="mr-2" navbar>
            <Nav
              className="ml-auto"
              style={{ height: '50px', paddingTop: '7px' }}
              navbar
            >
              <UncontrolledDropdown
                nav
                inNavbar
                className="ml-2"
                style={{ backgroundColor: 'transparent' }}
              >
                <DropdownToggle nav caret>
                  {`${Auth.user.first_name} ${Auth.user.last_name}`}
                </DropdownToggle>
                <DropdownMenu
                  right
                  className="mb-2"
                  style={{ fontSize: '.795rem' }}
                >
                  {/* <DropdownItem header>{translation.account[locale]}</DropdownItem>
                  <DropdownItem>{translation.nav.plan[locale]}</DropdownItem>
                  <DropdownItem>{translation.nav.invoice[locale]}</DropdownItem> */}
                  {/* <DropdownItem divider /> */}
                  {/* <DropdownItem>{translation.nav.how[locale]}</DropdownItem> */}

                  {Auth.user.role !== 'Client' && (
                    <>
                      <DropdownItem
                        onClick={() =>
                          dispatch({
                            type: 'SET_STATE',
                            payload: { feature_modal: !feature_modal },
                          })
                        }
                      >
                        {translation.whats_new[locale]}
                      </DropdownItem>
                      {/* <DropdownItem onClick={() => history.push('/how_to_use')}>
                        {translation.how_to_use[locale]}
                      </DropdownItem> */}
                      <hr style={{ margin: '5px' }} />
                    </>
                  )}
                  <Link to="/settings">
                    <DropdownItem>
                      {translation.nav.settings[locale]}
                    </DropdownItem>
                  </Link>
                  <DropdownItem onClick={logOut}>Logout</DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>

              <UncontrolledDropdown
                className="ml-2"
                style={{ marginRight: '7px', backgroundColor: 'transparent' }}
                nav
                inNavbar
              >
                <DropdownToggle nav>
                  <Icon height="13" width="15" src={setFlag()} />
                </DropdownToggle>
                <DropdownMenu
                  right
                  className="mb-2"
                  style={{ fontSize: '.795rem' }}
                >
                  <DropdownItem
                    onClick={() => setLocale('en')}
                    style={{ paddingLeft: '15px' }}
                    active={setActive('en')}
                  >
                    <small style={{ marginRight: '10px' }}>EN</small> English
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => setLocale('de')}
                    style={{ paddingLeft: '15px' }}
                    active={setActive('de')}
                  >
                    <small style={{ marginRight: '10px' }}>DE</small> German
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Collapse>
        </Navbar>
      </header>
    </animated.div>
  );
});

export default MainNavbar;
