import React, { useEffect } from "react";
import { Row, Col } from "reactstrap";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
// import Switch from '@material-ui/core/Switch';
import chroma from "chroma-js";
import { Switch, DatePicker } from "antd";
import moment from "moment";

const animatedComponents = makeAnimated();

const optionStatus = [
  { value: "open", label: "Open", color: "#CF0F22" },
  { value: "question", label: "Question", color: "#219FC7" },
  { value: "for_testing", label: "For Testing", color: "#5B3694" },
  { value: "draft", label: "Draft", color: "#B9BDC2" },
  { value: "postpone", label: "Postpone", color: "#E43988" },
  { value: "closed", label: "Closed", color: "#53AD57" },
];

const colourStyles = {
  control: (styles) => ({ ...styles, backgroundColor: "white" }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? data.color
        : isFocused
        ? color.alpha(0.1).css()
        : null,
      color: isDisabled
        ? "#ccc"
        : isSelected
        ? chroma.contrast(color, "white") > 2
          ? "white"
          : "black"
        : data.color,
      cursor: isDisabled ? "not-allowed" : "default",

      ":active": {
        ...styles[":active"],
        backgroundColor:
          !isDisabled && (isSelected ? data.color : color.alpha(0.3).css()),
      },
    };
  },
  multiValue: (styles, { data }) => {
    const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: color.alpha(0.1).css(),
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: data.color,
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: data.color,
    ":hover": {
      backgroundColor: data.color,
      color: "white",
    },
  }),
};

export default ({ reducer, context, updateNode, loadPoints }) => {
  const { state, dispatch } = reducer;
  const {
    project: { value: proj_id },
    client: { value: client_id },
  } = state.filter;
  const isValueNotAll = (property, compareProperty, value) =>
    property === compareProperty && value.value !== "all";

  const currentDateFilter = localStorage.getItem("currentDateFilter")
    ? JSON.parse(localStorage.getItem("currentDateFilter")).map((date) =>
        moment(date).utc()
      )
    : [
        moment.utc().subtract(1, "month").startOf("month"),
        moment.utc().endOf("month"),
      ];

  const selectedFilter = (property, value, isSwitch) =>
    isSwitch
      ? proj_id !== "all"
        ? { id: proj_id, type: "Project" }
        : client_id !== "all"
        ? { id: client_id, type: "Client" }
        : { id: "all", type: "all" }
      : isValueNotAll(property, "Project", value)
      ? { id: value.value, type: "Project" }
      : isValueNotAll(property, "Client", value)
      ? { id: value.value, type: "Client" }
      : { id: "all", type: "all" };

  useEffect(() => {
    if (state.expandFilter)
      dispatch({ type: "SET_STATE", payload: { filterStyle: { opacity: 1 } } });
    else
      dispatch({
        type: "SET_STATE",
        payload: {
          filterStyle: { maxHeight: 0, overflow: "hidden", marginTop: "-30px" },
        },
      });

    localStorage.setItem("filterExpand", state.expandFilter);
  }, [state.expandFilter]);

  useEffect(() => {
    (async () => {
      updateNode(
        document.getElementById("search-filter").value,
        state.nodes,
        false
      );
    })();
  }, [state.filter.status, state.filter.priority]);

  function loadNewPoints(filter, isActivePoint = state.filter.active_points) {
    loadPoints(
      {
        ...filter,
        time_log: true,
        priority: state.filter.priority.join("|"),
        isActivePoint,
      },
      "Open|Question|For Testing|Draft|Postpone|Closed"
    );
  }

  const handleFilterChange =
    (property) =>
    (value, { action, removedValue }) => {
      switch (action) {
        case "remove-value":
        case "pop-value":
          if (removedValue.isFixed) {
            return;
          }
          break;
        case "clear":
          value = [];
          break;

        default:
          value = value;
      }

      const filter = selectedFilter(property, value, false);
      loadNewPoints(filter);
      dispatch({
        type: "SET_STATE",
        payload: {
          filter: { ...state.filter, [property.toLowerCase()]: value },
        },
      });
    };

  const handleActiveChange = (checked) => {
    const filter = selectedFilter(null, null, true);
    loadNewPoints(filter, checked);
    // loadPoints({...filter, time_log: true, priority: state.filter.priority.join('|'), isActivePoint: checked}, state.filter.status.map(e => e.label).join('|'))
    dispatch({
      type: "SET_STATE",
      payload: { filter: { ...state.filter, active_points: checked } },
    });
  };

  return (
    <div id="feedback-filter-search">
      <div id="hide-filter-group" style={state.filterStyle}>
        <Row>
          <Col>
            <span>{context.translation.priority[context.locale]}</span>
            <div>
              <ToggleButtonGroup
                id="priority-filter"
                size="small"
                value={state.filter.priority}
                onChange={(event, newPriorities) => {
                  dispatch({
                    type: "SET_STATE",
                    payload: {
                      filter: { ...state.filter, priority: newPriorities },
                    },
                  });
                }}
                arial-label="text formatting"
              >
                <ToggleButton
                  value="High"
                  aria-label="High"
                  style={{
                    borderTopLeftRadius: ".2rem",
                    borderBottomLeftRadius: ".2rem",
                  }}
                >
                  {context.translation.high[context.locale]}
                </ToggleButton>
                <ToggleButton value="Mid" aria-label="Mid">
                  {context.translation.mid[context.locale]}
                </ToggleButton>
                <ToggleButton
                  value="Low"
                  aria-label="Low"
                  style={{
                    borderTopRightRadius: ".2rem",
                    borderBottomRightRadius: ".2rem",
                  }}
                >
                  {context.translation.low[context.locale]}
                </ToggleButton>
              </ToggleButtonGroup>
            </div>
          </Col>
          <Col>
            <span>{context.translation.active_points[context.locale]}</span>
            <br />
            <Switch
              defaultChecked
              checked={state.filter.active_points}
              onChange={handleActiveChange}
              checkedChildren={context.translation.active[context.locale]}
              unCheckedChildren={context.translation.inactive[context.locale]}
              size="small"
              style={{ marginTop: "10px" }}
            />
          </Col>
        </Row>
        <Row className="mt-2">
          <Col>
            <span>Status</span>
            <br />
            <Select
              isMulti
              placeholder={context.translation.search[context.locale]}
              value={state.filter.status}
              closeMenuOnSelect={false}
              defaultValue={optionStatus[0]}
              components={animatedComponents}
              options={optionStatus}
              styles={colourStyles}
              onChange={(value, { action, removedValue }) => {
                switch (action) {
                  case "remove-value":
                  case "pop-value":
                    if (removedValue.isFixed) {
                      return;
                    }
                    break;
                  case "clear":
                    value = [];
                    break;

                  default:
                    value = value;
                }

                dispatch({
                  type: "SET_STATE",
                  payload: { filter: { ...state.filter, status: value } },
                });
              }}
              getOptionLabel={(option) =>
                context.translation.status[option.value][context.locale]
              }
              className="basic-multi-select"
              classNamePrefix="select"
              id="timelog-filter"
            />
          </Col>
        </Row>
        <Row className="mt-2">
          <Col xl={6} lg={12} md={12} xs={12} sm={12}>
            <span>{context.translation.client[context.locale]}</span>
            <br />
            <Select
              id="client-filter-feedback"
              options={state.options.clients}
              styles={{ menu: (base) => ({ ...base, marginBottom: 76 }) }}
              value={state.filter.client}
              getOptionLabel={(option) =>
                option.value === "all"
                  ? context.translation.status[option.value][context.locale]
                  : option.label
              }
              onChange={handleFilterChange("Client")}
            />
          </Col>
          <Col xl={6} lg={12} md={12} xs={12} sm={12}>
            <span>{context.translation.nav.project[context.locale]}</span>
            <br />
            <Select
              id="project-filter-feedback"
              options={state.options.projects}
              styles={{ menu: (base) => ({ ...base, marginBottom: 76 }) }}
              value={state.filter.project}
              getOptionLabel={(option) =>
                option.value === "all"
                  ? context.translation.status[option.value][context.locale]
                  : option.label
              }
              onChange={handleFilterChange("Project")}
            />
          </Col>
          <Col>
            <DatePicker.RangePicker
              defaultValue={currentDateFilter}
              allowClear={false}
              className="w-100 mt-3"
              onChange={(date) => {
                let [start, end] = date;
                start = start.utc().startOf("day");
                end = end.utc().endOf("day");
                // moment.utc().subtract(1, "month").startOf("month"),
                // moment.utc().endOf("month"),
                // console.log(
                //   [start.toISOString(), end.toISOString()],
                //   "currentFilterArray"
                // );
                localStorage.setItem(
                  "currentDateFilter",
                  JSON.stringify([start, end])
                );

                loadPoints(
                  {
                    id: "all",
                    type: "all",
                    time_log: true,
                    priority: state.filter.priority.join("|"),
                    isActivePoint: state.filter.active_points,
                    dateFilter: date,
                  },
                  "Open|Question|For Testing|Draft|Postpone|Closed"
                );
              }}
            />
          </Col>
        </Row>
      </div>
      <span
        id="filter-link"
        onClick={() =>
          dispatch({
            type: "SET_STATE",
            payload: { expandFilter: !state.expandFilter },
          })
        }
      >
        <i
          className="fas fa-chevron-down"
          style={{
            transition: "150ms all",
            transform: state.expandFilter ? "rotate(180deg)" : "rotate(0)",
          }}
        ></i>{" "}
        {context.translation.filter[context.locale]}
      </span>
    </div>
  );
};
