import React, { Fragment, useReducer, useEffect, useContext } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import Table from './ProjectTable';
import Modal from './ProjectModal';
import { Card, Page } from '../../common';
import { Row, Col } from 'reactstrap';

import { ApolloContext } from 'react-apollo';
import { GlobalContext } from '../../../contexts/GlobalContext';
import { AuthContext } from '../../../contexts/AuthContext';
import { PROJECTS_QUERY, UPDATE_PROJECT, PROJECTS_TEST_QUERY, PROJECTS_OPEN_POINTS } from '../../../queries';
import ProjectReducer from './ProjectReducer';

const Actions = ({ e, handleEdit, handleToggleStatus, statusCount, editTitle, toggleTitle, hideActions }) => (
  <>
    { 
      [1,2].includes(hideActions) && (
        <Tooltip title={editTitle}>
          <button onClick={handleEdit} className="btn btn-sm btn-warning">
            <i className="fas fa-edit" style={{ color: '#fff'  }}></i>
          </button>
        </Tooltip>
      )
    }
    {
      hideActions === 2 && (
        <Tooltip title={toggleTitle}>
          <button onClick={handleToggleStatus} className={`btn btn-sm ${(e.status === 'Open')? 'btn-danger': 'btn-primary'}`} style={{ marginLeft: '5px' }}>
            <i className={`fas ${(e.status === 'Open')? 'fa-eye-slash': 'fa-eye'}`} style={{ color: '#fff' }}></i>
          </button>
        </Tooltip>
      )
    }
  </>
)

const initialErrorState = {
  project: {
    proj_id: {
      invalid: false,
      message: ''
    },
    proj_nr: {
      invalid: false,
      message: ''
    },
    proj_name: {
      invalid: false,
      message: ''
    },
    version: {
      invalid: false,
      message: ''
    },
    team: {
      invalid: false,
      message: ''
    }
  }
}

const initialState = {
  errors: { ...initialErrorState },
  modal: false,
  visible: true,
  role: { id: 3, name: 'Project Leader' },
  editVersion: { edit: false, id: 0, version_name: '' },
  selectedClient: '',
  team: [],
  user: {},
  users: [],
  projects: [],
  projectsTable: [],
  versions: [],
  version: '',
  clients: [],
  proj_id: '',
  proj_nr: '',
  proj_name: '',
  selected: [],
  modalType: '',
  tempUsers: [],
  tempVersions: [],
  statusCount: {},
  loading: true
}
let temp_projectsRaw = []
let temp_users = []
let tempUsers = []

export default () => {
  const { client } = useContext(ApolloContext);
  const context = useContext(GlobalContext);
  const { user } = useContext(AuthContext);
  const [state, dispatch] = useReducer(ProjectReducer, initialState)

  useEffect(() => {
    console.time("key")
    client.query({ query: PROJECTS_QUERY }).then(({ data }) => {
      tempUsers = data.users ? [...data.users]: []
      temp_projectsRaw = {...data}
      // const data_projects = createTable(data);
      temp_users = data.users
      console.timeEnd("key")
      dispatch({ type: 'INITIAL_STATE', 
        payload: { 
          user: { user_id: data.users ? data.users[0].id : 0, name: data.users ? data.users[0].name : '', user_type: data.users ? data.users[0].user_type_id : 0 },
          users: data.users ? [...data.users]: [],
          clients: data.clients ? [...data.clients] : [],
          tempUsers: data.users ? [...data.users]: [],
          statusCount: data.projectStatusCount ? {...data.projectStatusCount[0]}: {},
          // loading: false,
          isAssigned: true,
          hideClosedProjects: true
        } 
      })
    })
    return () => {
      context.dispatch({ type: ' SET_STATE', payload: { isInteraction: false, modal1: false } })
    }
  }, [])

  useEffect(() => {
    console.time("key2")
    client.query({ query: PROJECTS_TEST_QUERY }).then(({ data }) => {
      tempUsers = data.users ? [...data.users]: []
      temp_projectsRaw = {...data}
      const data_projects = createTable(data);
      temp_users = data.users
      console.timeEnd("key2")
      dispatch({ type: 'INITIAL_STATE', 
        payload: { 
          projects: [...data.projects],
          projectsTable: [...data_projects],
          loading: false,
          isAssigned: true,
          hideClosedProjects: true
        } 
      })
    })    
    
    return () => {
      context.dispatch({ type: ' SET_STATE', payload: { isInteraction: false, modal1: false } })
    }
  }, [])

  useEffect(() => {
    if (Object.entries(state.projects).length !== 0){
      const updated_action = createTable({ projects: state.projects })
      dispatch({ type: 'INITIAL_STATE', 
      payload: { 
        projectsTable: updated_action
      } 
    })
    // console.log(state.projects)
    // console.log(state.projectsTable)
    }
  }, [state.projects]);

  useEffect(() => {
    dispatch({ type: 'INITIAL_STATE', 
      payload: { 
        errors: { ...initialErrorState },
        editVersion: { edit: false, id: 0, version_name: '' },
        version: '',
        role: { id: 3, name: context.translation.project_leader[context.locale] }
      } 
    })
  }, [state.modal])

  const handleEdit = (e, temp_team, temp_version, data) => {
    const temp_temp = temp_version
    dispatch({ type: 'INITIAL_STATE', 
      payload: {
        modal: true,
        modalType: 'update',
        proj_id: e.id,
        proj_nr: e.proj_nr,
        proj_name: e.proj_name,
        temp_proj_nr: e.proj_nr,
        temp_proj_name: e.proj_name,
        selectedClient: e.client_id,
        versions: [ ...temp_temp ],
        team: temp_team.map(e => {
          return { ...e, user_id: e.id };
        })
      }
    })

    let selectedTeam = temp_team.map(e => e.id)
    
    dispatch({ type: 'setUsers', payload: temp_users.filter((x, i) => !selectedTeam.includes(x.id)) })
  }

  const handleToggleStatus = e => {
    console.log('points',e)
    client.query({ query: PROJECTS_OPEN_POINTS(e.id) }).then(({ data }) => {
    if (e.status === 'Open' && data.projectOpenForTesting.length > 0 && data.projectOpenForTesting[0].has_open_or_testing_points == 'true') { //added a == 'true' because issue of project not closing even without open points 
      console.log("enter here")
      dispatch({ 
        type: 'INITIAL_STATE', 
        payload: {
          modal: true,
          modalType: 'warning',
          confirmWarning: () => updateProject({ status: (e.status === 'Open')? 'Closed': 'Open' }, e.id)
        }
      })
    } else {
      updateProject({ status: (e.status === 'Open')? 'Closed': 'Open' }, e.id)
    }
    })
  } 

  const createTable = data => {
    const temp_data = {...data}
    if (temp_data) {
      return temp_data.projects.map(e => {        
        /* Create List of Team */
        let temp_user_type = '';
        let team = '', isFeedbackProvider = 2
        if (!!e.team && !!e.team.length) {
          team = (
            <dl>
              {e.team.map((x, i) => {
                const user_type = x.project_role
                let header;

                // Hide actions for feedback provider on project list, except for administrator accounts
                // Legend: 
                // 0 = user is feedback provider || hide all
                // 1 = user is a staff || hide toggle status action
                // 2 = user is an administrator || display all actions
                if (x.id === user.id) {
                  if (user.role !== 'Administrator') {
                    if (x.project_role === 'Feedback Provider') 
                      isFeedbackProvider = 0

                    if (x.project_role === 'Project Staff')
                      isFeedbackProvider = 1
                  } else {
                    isFeedbackProvider = 2
                  }
                }

                if (temp_user_type !== user_type){
                  header = (<dt>{context.translation[user_type.toLowerCase().replace(' ', '_')][context.locale]}</dt>)
                  temp_user_type = user_type
                }

                return (
                  <Fragment key={i}>
                    {header}
                    <dd>{x.name} {user.id === x.id && (<i className="fas fa-star" style={{ fontSize: '10px' }}></i>)}</dd>
                  </Fragment>
                )
              })}
            </dl>
          )
        }

        /* Create Action Buttons */
        const temp_team = e.team;
        const temp_version = e.project_versions
        const action = <Actions hideActions={2} editTitle={context.translation.edit_project[context.locale]} toggleTitle={context.translation.toggle_status[context.locale]} e={e} statusCount={state.statusCount} handleEdit={() => handleEdit(e, temp_team, temp_version, temp_data)} handleToggleStatus={() => handleToggleStatus(e)} /> 
        // hideActions={isFeedbackProvider}  --> replace on actions

        /* Create List of Versions */
        let project_versions = ''
        if (!!e.project_versions && !!e.project_versions.length) {
          project_versions = (
            <dl>
              {e.project_versions.map((x, i) => <dd key={i} className='dl-item'>{x.version_name}</dd> )}
            </dl>
          )
        }

        return {
          ...e,
          action,
          team,
          project_versions,
        }
      })
    }

    return data.projects
  }
console.log("Here", state)
  const updateProject = async (payload, id) => {
    
    try {
      const { data } = await client.mutate({ mutation: UPDATE_PROJECT, variables: { data: payload, id: parseInt(id)} });
      let temp_project = state.projects.map(e => {
        if (e.id === data.updateProject.id) 
          e = data.updateProject;
        return e 
      })
      console.log("Here", data)  
      const project = createTable({ projects: temp_project})
  
      if (!!payload.status) {
        console.log("Here", payload, state.statusCount)
        if (payload.status === 'Open') {
          state.statusCount.open_projects++
          state.statusCount.closed_projects--
        } else {
          state.statusCount.open_projects--
          state.statusCount.closed_projects++
        }
        
        dispatch({ type: 'setStatusCount', payload: { ...state.statusCount, open_projects: state.statusCount.open_projects, closed_projects: state.statusCount.closed_projects } })
      }
      
      dispatch({ type: 'INITIAL_STATE', 
        payload: {
          projects: [...temp_project],
          projectsTable: [...project]
        } 
      })
    } catch (error) {
      error.message = error.message.replace('GraphQL error: ', '')
      let error_msg = JSON.parse(error.message)
      ['proj_nr', 'proj_name'].forEach((e) => {
        if (error_msg[e])
          if (error_msg[e].invalid)
            error_msg[e].message = context.translation.errors.project[e][context.locale];
      });
      dispatch({ type: 'setErrors', payload: error_msg })
      return false
    }
  }

  const clearState = () => {
    dispatch({ type: 'INITIAL_STATE', 
      payload: {
        users: [...tempUsers],
        team: [],
        versions: [],
        proj_id: '',
        proj_nr: '',
        proj_name: ''
      }
    })
  }
  
  return (
    <Page title={context.translation.nav.project[context.locale]} components={
      <span style={{ position: 'absolute', right: '1.3em', display: user.role !== 'Client'? 'initial': 'none' }}>
          <span className="mr-2"><span style={{ fontSize: '.875rem', fontWeight: 'normal' }} className="badge badge-info">{context.translation.total_projects[context.locale]}: { state.statusCount.total_projects }</span></span>
          <span className="mr-2"><span style={{ fontSize: '.875rem', fontWeight: 'normal' }} className="badge badge-primary">{context.translation.open_projects[context.locale]}: { state.statusCount.open_projects }</span></span>
          <span className="mr-2"><span style={{ fontSize: '.875rem', fontWeight: 'normal' }} className="badge badge-secondary">{context.translation.closed_projects[context.locale]}: { state.statusCount.closed_projects }</span></span>
      </span>
    }>
      <Row className="d-flex align-items-center mb-3 mt-2">
        {/* <Col xs={12} className="d-flex align-items-center" style={{ position: 'relative' }}>
          <span className="mr-2"><span style={{ fontSize: '.875rem', fontWeight: 'normal' }} className="badge badge-info"> Total Projects: { state.statusCount.total_projects }</span></span>
          <span className="mr-2"><span style={{ fontSize: '.875rem', fontWeight: 'normal' }} className="badge badge-primary">Open Projects: { state.statusCount.open_projects }</span></span>
          <span className="mr-2"><span style={{ fontSize: '.875rem', fontWeight: 'normal' }} className="badge badge-secondary">Closed Projects: { state.statusCount.closed_projects }</span></span>

          <button onClick={() => { 
            dispatch({ type: 'INITIAL_STATE', 
              payload: {
                modal: !state.modal,
                modalType: 'create'
              }
            })
            clearState(); 
            }} className="btn btn-sm btn-outline-exact" style={{ position: 'absolute', right: '1.15em' }}>
            <i className="fas fa-folder-plus"></i> Add Project
          </button>          
        </Col> */}

        <Col xs="5" className="d-flex align-items-center">
          <Modal createTable={createTable} clearState={clearState} updateProject={updateProject} state={state} dispatch={dispatch} initialErrorState={initialErrorState}/>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <Card>
            {/* <h5>Project List</h5><br /> */}
            <Table state={state} dispatch={dispatch} clearState={clearState} context={context} />
          </Card>
        </Col>
      </Row>
    </Page>
  )
}