import React from 'react'

export default ({
  to: {
      opacity: 1, 
      marginLeft: 0
  }, 

  from: { 
      opacity: 0, 
      marginLeft: -500 
  }, 
  delay: 100,
})