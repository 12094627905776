import React, { useContext, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTachometerAlt,
  faComments,
  faCommentMedical,
  faUsers,
  faFolderOpen,
  faUserTie,
  faPlus,
  faUserClock,
} from '@fortawesome/free-solid-svg-icons';
import {
  NavItem,
  NavLink,
  Nav,
  Button,
  Tooltip,
  Row,
  Col,
  Label,
  FormGroup,
  Input,
} from 'reactstrap';
import classNames from 'classnames';
import { Modal, Toast } from './';
import { Layout, Menu, Breadcrumb, Icon } from 'antd';

import { ApolloContext } from 'react-apollo';
import { AuthContext } from '../../contexts/AuthContext';
import { GlobalContext } from '../../contexts/GlobalContext';
import { UPSERT_FEEDBACK } from '../../queries';

const { SubMenu } = Menu;
const { Sider } = Layout;

const uri =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3000'
    : 'http://exactplacev0.exactconstruct.synology.me/';

const Sidebar = withRouter((props) => {
  const context = useContext(GlobalContext);
  const { client } = useContext(ApolloContext);
  const { isOpen, history, location } = props;
  const { user } = useContext(AuthContext);
  const [tooltipOpen, toggle] = useState({
    dashboard: false,
    feedbacks: false,
    users: false,
    projects: false,
    clients: false,
    add_feedback: false,
    time_log: false,
  });
  const goTo = (to) => {
    history.push(to);
  };
  const [modal, setModal] = useState(false);
  const checkCurrentLocation = (path) =>
    path === location.pathname
      ? 'link-active'
      : (path.split('/').length === 2 || path.split('/').length === 3) &&
        path.split('/')[1] === location.pathname.split('/')[1]
      ? 'link-active'
      : '';
  const confirmCreate = async (e) => {
    e.preventDefault();
    let payload = {};
    try {
      const formData = new FormData(document.forms.namedItem('modal-form'));
      formData.forEach((value, key) => (payload[key] = value));
      const { data } = await client.mutate({
        mutation: UPSERT_FEEDBACK,
        variables: { data: payload },
      });
      setModal(!modal);
      history.push(`/feedback/view/${data.upsertFeedback.id}`);
      Toast.showToast('success', 'Feedback Successfully Added!');
    } catch (error) {
      console.log(error);
      Toast.showToast('error', error);
    }
  };

  return context.showSidebar ? (
    <div
      className={classNames('sidebar', 'fade-in-left', { 'is-open': isOpen })}
    >
      <Modal
        title={context.translation.add_feedback[context.locale]}
        modal={modal}
        setModal={(e) => setModal(!modal)}
        accessFunction={confirmCreate}
      >
        <Row>
          <Col>
            <FormGroup>
              <Label for="title" id="feedback-title">
                Feedback Title
              </Label>
              <br />
              <Input
                name="title"
                id="title"
                onKeyPress={(event) => {
                  event.key === 'Enter' && confirmCreate(event);
                }}
                placeholder="Feedback Title"
              />
            </FormGroup>

            <FormGroup>
              <Label for="project">Project</Label>
              <Input type="select" name="proj_id" id="project">
                {context.projects.map((e, i) => (
                  <option key={i} value={e.id}>
                    {e.proj_name}
                  </option>
                ))}
              </Input>
            </FormGroup>
          </Col>
        </Row>
      </Modal>

      <Nav
        vertical
        className={`list-unstyled pb-3 ${isOpen ? '' : 'asdo'}`}
        style={{
          position: 'absolute',
          right: '0',
          width: '100%',
          transition: 'all 200ms ease',
        }}
      >
        <a onClick={() => goTo('/')}>
          <div id="side-header">
            {/* <span className="logo-name">e</span> */}
            <h4 id="logo-namee" className={`ico ${isOpen ? '' : 'opac'}`}>
              xactplace
            </h4>
            <div id="logsgo"></div>
          </div>
        </a>
        <NavItem className={`${!isOpen ? 'nav-collapse' : ''}`}>
          <NavLink
            id="n-dashboard"
            onClick={() => goTo('/dashboard')}
            className={`mr-2 link-sidebar ${
              checkCurrentLocation('/dashboard') +
              ' ' +
              checkCurrentLocation('/')
            }`}
          >
            <FontAwesomeIcon
              className={`ico ${isOpen ? '' : 'ic'}`}
              icon={faTachometerAlt}
            />{' '}
            <span
              className={`${isOpen ? '' : 'opac'}`}
              style={{
                whiteSpace: 'nowrap',
                opacity: 1,
                transition: 'all 200ms ease-in',
              }}
            >
              Dashboard
            </span>
          </NavLink>
        </NavItem>
        <NavItem className={`${!isOpen ? 'nav-collapse' : ''}`}>
          <NavLink
            id="n-feedbacks"
            onClick={() => goTo('/feedbacks/Open')}
            className={`mr-2 link-sidebar ${
              checkCurrentLocation('/feedbacks') +
              ' ' +
              checkCurrentLocation('/feedback/view')
            }`}
          >
            <FontAwesomeIcon
              className={`ico ${isOpen ? '' : 'ic'}`}
              icon={faComments}
            />{' '}
            <span
              className={`${isOpen ? '' : 'opac'}`}
              style={{
                whiteSpace: 'nowrap',
                opacity: 1,
                transition: 'all 200ms ease-in',
              }}
            >
              {context.translation.feedback_list[context.locale]}
            </span>
          </NavLink>
        </NavItem>
        {/* <NavItem>
                    <NavLink onClick={ () => goTo('/feedbacks') } className={`mr-2 link-sidebar ${checkCurrentLocation('/feedbacks')}`}><FontAwesomeIcon className={`ico ${isOpen? '': 'ic'}`} icon={faComments}/> <span className={`${isOpen? '' : 'opac'}`} style={{ whiteSpace: 'nowrap', opacity: 1, transition: 'all 200ms ease-in' }}>Feedback List v2</span></NavLink>
                </NavItem> */}

        {user.role === 'Administrator' && (
          <>
            <NavItem className={`${!isOpen ? 'nav-collapse' : ''}`}>
              <NavLink
                id="n-users"
                onClick={() => goTo('/users')}
                className={`mr-2 link-sidebar ${checkCurrentLocation(
                  '/users'
                )}`}
              >
                <FontAwesomeIcon
                  className={`ico ${isOpen ? '' : 'ic'}`}
                  icon={faUsers}
                />{' '}
                <span
                  className={`${isOpen ? '' : 'opac'}`}
                  style={{
                    whiteSpace: 'nowrap',
                    opacity: 1,
                    transition: 'all 200ms ease-in',
                  }}
                >
                  {context.translation.users[context.locale]}
                </span>
              </NavLink>
              <Tooltip
                placement="right"
                isOpen={tooltipOpen.users}
                target="n-users"
                toggle={() =>
                  !isOpen &&
                  toggle({ ...tooltipOpen, users: !tooltipOpen.users })
                }
              >
                {context.translation.users[context.locale]}
              </Tooltip>
            </NavItem>
          </>
        )}

        {user.role !== 'Client' ? (
          <>
            <NavItem className={`${!isOpen ? 'nav-collapse' : ''}`}>
              <NavLink
                id="n-clients"
                onClick={() =>
                  // context.isTourOpen
                  //   ? context.dispatch({
                  //       type: 'SET_STATE',
                  //       payload: { goToStep: 4 },
                  //     })
                  //   : 
                    goTo('/clients')
                }
                className={`mr-2 link-sidebar ${checkCurrentLocation(
                  '/clients'
                )}`}
              >
                <FontAwesomeIcon
                  className={`ico ${isOpen ? '' : 'ic'}`}
                  icon={faUserTie}
                />{' '}
                <span
                  className={`ico ${isOpen ? '' : 'opac'}`}
                  style={{
                    whiteSpace: 'nowrap',
                    opacity: 1,
                    transition: 'all 200ms ease-in',
                  }}
                >
                  {context.translation.client[context.locale]}
                </span>
              </NavLink>
              <Tooltip
                placement="right"
                isOpen={tooltipOpen.clients}
                target="n-clients"
                toggle={() =>
                  !isOpen &&
                  toggle({ ...tooltipOpen, clients: !tooltipOpen.clients })
                }
              >
                {context.translation.client[context.locale]}
              </Tooltip>
            </NavItem>

            <NavItem className={`${!isOpen ? 'nav-collapse' : ''}`}>
              <NavLink
                id="n-projects"
                onClick={() => goTo('/projects')}
                className={`mr-2 link-sidebar ${checkCurrentLocation(
                  '/projects'
                )}`}
              >
                <FontAwesomeIcon
                  className={`ico ${isOpen ? '' : 'ic'}`}
                  icon={faFolderOpen}
                />{' '}
                <span
                  className={`${isOpen ? '' : 'opac'}`}
                  style={{
                    whiteSpace: 'nowrap',
                    opacity: 1,
                    transition: 'all 200ms ease-in',
                  }}
                >
                  {context.translation.nav.project[context.locale]}
                </span>
              </NavLink>
              <Tooltip
                placement="right"
                isOpen={tooltipOpen.projects}
                target="n-projects"
                toggle={() =>
                  !isOpen &&
                  toggle({ ...tooltipOpen, projects: !tooltipOpen.projects })
                }
              >
                {context.translation.nav.project[context.locale]}
              </Tooltip>
            </NavItem>

            <hr style={{ margin: 0 }} />

            <NavItem className={`${!isOpen ? 'nav-collapse' : ''}`}>
              <NavLink
                id="n-time_log"
                onClick={() => goTo('/time_log')}
                className={`mr-2 link-sidebar ${checkCurrentLocation(
                  '/time_log'
                )}`}
              >
                <FontAwesomeIcon
                  className={`ico ${isOpen ? '' : 'ic'}`}
                  icon={faUserClock}
                />{' '}
                <span
                  className={`${isOpen ? '' : 'opac'}`}
                  style={{
                    whiteSpace: 'nowrap',
                    opacity: 1,
                    transition: 'all 200ms ease-in',
                  }}
                >
                  {context.translation.time_log[context.locale]}
                </span>
              </NavLink>
              <Tooltip
                placement="right"
                isOpen={tooltipOpen.time_log}
                target="n-time_log"
                toggle={() =>
                  !isOpen &&
                  toggle({ ...tooltipOpen, time_log: !tooltipOpen.time_log })
                }
              >
                {context.translation.time_log[context.locale]}
              </Tooltip>
            </NavItem>
          </>
        ) : (
          <>
            <NavItem className={`${!isOpen ? 'nav-collapse' : ''}`}>
              <NavLink
                id="n-projects"
                onClick={() => goTo('/projects')}
                className={`mr-2 link-sidebar ${checkCurrentLocation(
                  '/projects'
                )}`}
              >
                <FontAwesomeIcon
                  className={`ico ${isOpen ? '' : 'ic'}`}
                  icon={faFolderOpen}
                />{' '}
                <span
                  className={`${isOpen ? '' : 'opac'}`}
                  style={{
                    whiteSpace: 'nowrap',
                    opacity: 1,
                    transition: 'all 200ms ease-in',
                  }}
                >
                  {context.translation.nav.project[context.locale]}
                </span>
              </NavLink>
              <Tooltip
                placement="right"
                isOpen={tooltipOpen.projects}
                target="n-projects"
                toggle={() =>
                  !isOpen &&
                  toggle({ ...tooltipOpen, projects: !tooltipOpen.projects })
                }
              >
                {context.translation.nav.project[context.locale]}
              </Tooltip>
            </NavItem>
          </>
        )}

        <hr style={{ marginTop: 0 }} />
        <NavItem
          className={`${!isOpen ? 'nav-collapse' : ''}`}
          style={{ marginLeft: '12.35px' }}
        >
          <Button
            id="n-add-feedback"
            className={`butt ${!isOpen ? 'but' : ''}`}
            color="feedback"
            size="sm"
            onClick={() => goTo('/feedback/create')}
            style={{ fontSize: context.locale === 'de' ? '13px' : '14px' }}
          >
            <FontAwesomeIcon
              className={`ico ${isOpen ? '' : 'ic'}`}
              icon={faCommentMedical}
            />{' '}
            <span className={`ico ${isOpen ? '' : 'opac'}`}>
              {context.translation.add_feedback[context.locale]}
            </span>
          </Button>
        </NavItem>

        {/* Tooltips */}
        <Tooltip
          placement="right"
          isOpen={tooltipOpen.dashboard}
          target="n-dashboard"
          toggle={() =>
            !isOpen &&
            toggle({ ...tooltipOpen, dashboard: !tooltipOpen.dashboard })
          }
        >
          Dashboard
        </Tooltip>
        <Tooltip
          placement="right"
          isOpen={tooltipOpen.feedbacks}
          target="n-feedbacks"
          toggle={() =>
            !isOpen &&
            toggle({ ...tooltipOpen, feedbacks: !tooltipOpen.feedbacks })
          }
        >
          Feedbacks
        </Tooltip>
        <Tooltip
          placement="right"
          isOpen={tooltipOpen.add_feedback}
          target="n-add-feedback"
          toggle={() =>
            !isOpen &&
            toggle({ ...tooltipOpen, add_feedback: !tooltipOpen.add_feedback })
          }
        >
          {context.translation.add_feedback[context.locale]}
        </Tooltip>
      </Nav>
    </div>
  ) : location.pathname.split('/')[1] === 'how_to_use' ? (
    <Sider
      style={{
        background: '#fff',
        marginTop: '80px',
        borderRight: '1px solid #e8e8e8',
      }}
    >
      <Menu
        mode="inline"
        defaultSelectedKeys={['1']}
        defaultOpenKeys={['sub1']}
        style={{ height: '100%', borderRight: 0 }}
      >
        <Menu.Item key="1" onClick={() => goTo('/how_to_use/introduction')}>
          Introduction
        </Menu.Item>
        <Menu.ItemGroup key="g1" title="Prerequisites">
          <Menu.Item key="2">Client</Menu.Item>
          <Menu.Item key="3" onClick={() => goTo('/how_to_use/project')}>
            Project
          </Menu.Item>
          <Menu.Item key="4">User</Menu.Item>
        </Menu.ItemGroup>

        <Menu.ItemGroup key="g2" title="Data Entry">
          <Menu.Item key="5">Feedback</Menu.Item>
          <Menu.Item key="6">Points</Menu.Item>
        </Menu.ItemGroup>
      </Menu>
    </Sider>
  ) : (
    ''
  );
});

export default Sidebar;
