export const actionType = {
  SET_LOCALE: 'SET_LOCALE',
  SET_PROJECTS: 'SET_PROJECTS',
  SET_STATUS_DATA: 'SET_STATUS_DATA',
  SET_IS_OPEN: 'SET_IS_OPEN',
  SET_PROJECT_CLIENT_DATA: 'SET_PROJECT_CLIENT_DATA',
  SET_SELECTED_FILTER: 'SET_SELECTED_FILTER',
  SET_TABLE_LOADING: 'SET_TABLE_LOADING',   
  SET_SNACKBAR: 'SET_SNACKBAR',
  SET_STATUS_COUNT: 'SET_STATUS_COUNT',
  SET_STATE: 'SET_STATE'
}

const GlobalReducer = (state, action) => {

    switch (action.type) {
        case 'IS_TOUR_OPEN': 
          return {
            ...state,
            isTourOpen: action.payload
          }

        case actionType.SET_STATE:
          return {
            ...state,
            ...action.payload
          }

        case actionType.SET_LOCALE:
          localStorage.setItem('locale', action.locale)

          return {
            ...state,
            locale: action.locale
          }

        case actionType.SET_STATUS_COUNT:
          return {
            ...state,
            statusCount: { ...action.statusCount }
          }

        case actionType.SET_IS_OPEN:
          localStorage.setItem('expand-sidebar', action.isOpen)
          return {
            ...state,
            isOpen: action.isOpen
          }

        case actionType.SET_STATUS_DATA:
          let stat = {
            Draft: 0,
            Postpone: 0,
            Open: 0,
            Question: 0,
            For_Testing: 0,
            Closed: 0,
            Archive: 0
          };

          if (!action.isEmptyProject) {
            action.status.forEach(e => {
              if (e.status == 'For Testing') {
                e.status = e.status.replace(' ','_');
              } 
  
              stat[e.status] = e.no_status;
            });
          }
          

          return {
            ...state,
            status: stat,
          }
        
        case actionType.SET_SNACKBAR: 
          return {
            ...state,
            open: action.open,
            message: action.message
          }

        case actionType.SET_SELECTED_FILTER:
          console.log(action.selectedFilter)
          localStorage.setItem('selectedFilter', JSON.stringify(action.selectedFilter))

          return{
            ...state,
            selectedFilter: action.selectedFilter,
            tableLoading: !action.isLoading
          }

        case actionType.SET_TABLE_LOADING:
          return{
            ...state,
            tableLoading: action.isLoading
          }

        case actionType.SET_PROJECT_CLIENT_DATA: 
          let selectedFilter = [];
          
          /* Store Selected Project */
          if (localStorage.getItem('selectedFilter') === null || localStorage.getItem('selectedFilter') === undefined || Object.entries(JSON.parse(localStorage.getItem('selectedFilter'))).length === 0) {
            // if (action.projects.length !== 0 && action.clients !== 0) {
            //   selectedFilter = {
            //     id: action.clients[0].id,
            //     name: 'All Projects',
            //     type: 'Client',
            //     clientname: action.clients[0].clientname
            //   };
            // } else {
            //   selectedFilter = {
            //     id: 0,
            //     name: 'No Projects',
            //     type: 'All'
            //   };
            // }
            selectedFilter = {
              id: 'all',
              name: 'All Projects',
              type: 'All',
              clientname: 'All Clients'
            };
              localStorage.setItem('selectedFilter', JSON.stringify({...selectedFilter}));
          } else {
            if (JSON.parse(localStorage.getItem('selectedFilter')).type === 'Project') { 
              if (action.projects.find(e => e.id == JSON.parse(localStorage.getItem('selectedFilter')).id)) { // If project is assigned from cached selected filter
                selectedFilter = JSON.parse(localStorage.getItem('selectedFilter'));
              } else {
                selectedFilter = {
                  id: 'all',
                  name: 'All Projects',
                  type: 'All',
                  clientname: 'All Clients'
                };
                localStorage.setItem('selectedFilter', JSON.stringify({...selectedFilter}))
              }
            } else {
              selectedFilter = JSON.parse(localStorage.getItem('selectedFilter'));
            }
          }

          return {
            ...state,
            projects: action.projects,
            clients: action.clients,
            selectedFilter
          }

        default:
          return state;
    }
}

export default GlobalReducer;