import React, { useContext, useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Layout, Menu, Breadcrumb, Icon } from 'antd';

import Introduction from './ContentIntroduction';
import Project from './ContentProjects';

/* Import Context Provider */
import { GlobalContext } from '../../../contexts/GlobalContext';

const { SubMenu } = Menu;
const { Content, Sider } = Layout;

export default ((props) => {
  const context = useContext(GlobalContext);
  
  useEffect(() => {
    context.dispatch({ type: 'SET_STATE', payload: { showSidebar: false, newSidebarOpen: true } })

    return () => { // Trigger if component will unmount
      context.dispatch({ type: 'SET_STATE', payload: { showSidebar: true, newSidebarOpen: false } }) // Hide Sidebar      
    }
  }, [])

  return (
    <Layout style={{ padding: '40px 24px 24px',  marginTop: '-30px', background: '#fff' }}>
      <Content
        style={{
          background: '#fff',
          padding: 24,
          margin: 0,
          minHeight: 280,
        }}
      >
        <Switch>
          <Route path="/how_to_use/introduction" render={ props => <Introduction />} />
          <Route path="/how_to_use/project" render={ props => <Project context={context} />} />
        </Switch>
      </Content>
    </Layout>
  )
});