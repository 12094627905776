import React, { useEffect, useContext, useReducer } from 'react';
import Avatar from '../../../common/Avatar'
import Modal from './FeedbackFormModal'
import { Button, message } from 'antd';

import FeedbackFormReducer from './FeedbackFormReducer'
import { ApolloContext } from 'react-apollo';
import { AuthContext } from "../../../../contexts/AuthContext";
import { GlobalContext } from "../../../../contexts/GlobalContext";
import { SUBMIT_FEEDBACK } from '../../../../queries';


const initialEditedItem = {
  user: { id: 0, name: '' },
  instructions: '',
  status: 'Open',
  deadline: '',
  time_estimate: '',
  hours_worked: '',
  priority: 'Low',
  comments: [],
  users: [],
  comment: '',
}

const initialState = {  
  modal: false,
  files: [],
  removefiles: [],
  points: [],
  gallery: [],
  rowData: {files: [], editedItem: {}, dupFiles: []},
  modalType: '',
  isSubmitFeedbackForm: true,
  project: {
    id: -1,
    name: ''
  },
  loading: false,
  editedItem: {...initialEditedItem},
}

export default () => {
  const { client } = useContext(ApolloContext);
  const Auth = useContext(AuthContext);
  const context = useContext(GlobalContext);
  const [state, dispatch] = useReducer(FeedbackFormReducer, initialState)

  useEffect(() => {
    Auth.dispatch({ type: "SET_STATE", payload: { isSubmitForm: true } });

    return () => {
      Auth.dispatch({ type: "SET_STATE", payload: { isSubmitForm: false } });
    };
  }, []);

  useEffect(() => {
    if (!state.modal) 
      dispatch({ type: 'SET_STATE', payload: { editedItem: initialEditedItem, rowData: initialState.rowData, gallery: [], files: [] } })
  }, [state.modal])

  useEffect(() => {
    if (context.uploadFiles && context.uploadDone) {
      clearForm()
    }
    
  }, [context.uploadDone])

  function clearForm() {
    dispatch({ type: 'SET_STATE', payload: initialState })
    document.forms.namedItem('feedback-form').reset()
    message.success(context.translation.msg_feedback_submitted[context.locale]);
  }

  function openModal() {
    dispatch({ type: 'SET_STATE', payload: { modalType: 'create', modal: !state.modal, editedItem: initialEditedItem } })
  }

  // Points function
  function deletePoint(id) {
    dispatch({ type: 'SET_STATE', payload: { points: state.points.filter((e, i) => i != id) } })
  }

  function editPoint(id) {
    const selected = state.points[id]
    dispatch({ type: 'SET_STATE', payload: { modalType: 'update', modal: true, editedItem: { ...state.editedItem, ...selected, s_id: id }, files: selected.files } })
  }

  async function onSubmit(e) {
    e.preventDefault();
    if (state.points.length !== 0) {
      const formData = new FormData(document.forms.namedItem('feedback-form'))
      let payload = { points: [...state.points] };
      formData.forEach((value,key) => payload[key] = value)
  
      dispatch({ type: 'SET_STATE', payload: { loading: true }})
  
      client.mutate({ mutation: SUBMIT_FEEDBACK, variables: { data: payload } }).then(({ data }) => {
        console.log(state.points)
        let files = state.points.reduce((a, b, index) => {
          b.dupFiles = b.dupFiles || []
          b.dupFiles = (b.dupFiles.length == b.files.length) ? b.dupFiles.map(fileItem => {
            fileItem.setMetadata('points', `point_${data.submitFeedback[index].point_nr}_${data.submitFeedback[index].id}`)
            fileItem.setMetadata('feedback', `feedback_${data.submitFeedback[index].feedback_id}`)
            return fileItem
          }) : []

          return a.concat(b.dupFiles)
        }, [])        

        if (files.length !== 0) {
          context.dispatch({ type: 'SET_STATE', payload: { uploadDone: false, uploadFiles: true, files, fileName: `/project_${data.submitFeedback[0].proj_id}/feedback_${data.submitFeedback[0].feedback_id}` } })
        } else {
          clearForm()
        } 
      }).catch(err => {
        console.log(err)
        message.error(context.translation.error_occurred[context.locale]);
      }).finally(() => {
        dispatch({ type: 'SET_STATE', payload: { loading: false } })
      })
  
    } else {
      message.error(context.translation.msg_add_one_point[context.locale]);
    }
  }

  return (
    <div id="e-feedback-form">
      <Modal state={state} dispatch={dispatch} />

      <div className="wrapperr">
        <div className="card center">
          <form onSubmit={onSubmit} name="feedback-form">
            <div className="logo">
              <Avatar />
              <h1 style={{ zIndex: 9 }}>xactplace</h1>
            </div>

            <div className="input-form">
              <h5>{ context.translation.username[context.locale]}</h5>
              <input className="email" name="email" type="email" required />
            </div>
                  
            <div className="input-form">
              <h5>{ context.translation.client_name[context.locale]}</h5>
              <input className="clientname" name="clientname" type="text" required />
            </div>
                  
            <div className="input-form">
              <h5>{ context.translation.project_name[context.locale] }</h5>
              <input className="proj_name" name="proj_name" type="text" required />
            </div>

            <div className="input-form">
              <h5>{ context.translation.feedback_title[context.locale] }</h5>
              <input className="feedback_title" name="feedback_title" type="text" required />
            </div>


            <div className="inline">
              <h5>{ context.translation.feedback_points[context.locale] }</h5> 
              <button onClick={openModal} type="button"> + { context.translation.add_point[context.locale] }</button>
            </div>

            <hr />

            <div className="table">
              {
                state.points.length !== 0 &&
                  state.points.map((e, i) => (
                    <div className="list-card" key={i}>
                      <div className="instructions">{ e.instructions }</div>
                      <div className="footer">
                        <div className="id">#{ i+1 }</div>
                        <div className="action">
                          <a className="e-edit-modal" onClick={() => editPoint(i)}>{ context.translation.edit[context.locale] }</a> 
                          <a className="e-delete-point" onClick={() => deletePoint(i)}>{ context.translation.delete[context.locale] }</a> 
                        </div>
                      </div>
                    </div>
                  ))
              }
              <Button className="submit" loading={state.loading} htmlType="submit">{ context.translation.submit[context.locale] }</Button>
            </div>

          </form>
        </div>
      </div>
    </div>
  )
}